import { SOCIAL_TYPE_META_MASK } from "../actionTypes";

export default {
    age_verify: null,
    photos: {
        private: [],
        public: [],
    },
    social_media: [],
    provider: SOCIAL_TYPE_META_MASK,
    chat_online: false
}