import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";

export default ({ name, img_url, _id }) => {
  return <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3 px-0">
    <div className="box-sizem">
      <Link className="btnn-img" to={`/collection/${_id}`}>
        <div className="live-box">
          <div className="live-img position-relative">
            {img_url.includes("https") ? <img className="roundeded height-incress"
              src={img_url}
            /> : <img className="roundeded height-incress"
              src={`${henceforthApi.API_FILE_ROOT_MEDIUM}${img_url}`}
            />}
          </div>
          <div className="hot-collection live-text">
            <div className="text-center justify-content-center align-items-center im-whiteing">
              {img_url.includes("https") ? <img
                src={img_url}
              /> : <img
                src={`${henceforthApi.API_FILE_ROOT_SMALL}${img_url}`}
              />}
              <h2 className="minum-heit  mt-3 fs-5 pb-2 px-4  text-truncate">{name}</h2>
            </div>
          </div>
        </div>
      </Link>
    </div>
  </div>
};
