import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context/Provider"
import henceforthApi from "../utils/henceforthApi"
import moment from "moment";

export default () => {
    const { scrollToTop, setLoading } = useContext(GlobalContext)
    const [state, setState] = useState({
        data: [],
        total_count: 0,
    });
    const initialiseData = async () => {
        try {
            setLoading(true)
            let apiRes = await henceforthApi.Common.aboutUs();
            setState({
                ...state,
                ...apiRes,
            });
            setLoading(false)
        } catch (error) {
        }
    };
    useEffect(() => {
        scrollToTop();
        initialiseData();
    }, []);
    return <>
        {state.data.map((res) => {
            return <section key={res._id} className='termconditons mt-5 pt-5 pb-5 mb-3 text-dark'>
                <div className="container">
                    <h2 className='term-heading text-center mt-lg-5'>{res.title}</h2>
                    <div className="inner-content">
                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: res.description ? `${res.description.slice(3, -4)}` : "description not addeds" }}>
                        </div>
                        <h5>Updated at:   {moment(Number(res.updated_at)).format("MMM DD, YYYY")}</h5>
                    </div>
                </div>
            </section>
        })}
    </>
}