import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import exploreAction from "../context/actions/auth/exploreAction";
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "./../assets/images/view_profile.svg";
import authAction from "../context/actions/auth/authAction";
import { FIXED_PRICE_AUCTION_ID, WALLET_ADDRESS_SLICE_FROM } from "../context/actionTypes";

export default ({
  auction_type,
  _id,
  currentUser,
  created_by,
  current_owner,
  file_ext,
  file_url,
  type,
  name,
  price,
  signature,
  is_favourite,
  index,
  creator_address,
  exploreDispatch,
  authDispatch,
  col,
}) => {
  const [is_favourite_state, setis_favourite] = useState(
    is_favourite ? is_favourite : false
  );
  const location = useLocation();

  const addToFev = async (id) => {
    try {
      let items = {
        nft_id: id,
        language: "ENGLISH",
      };
      exploreAction.exploreFav({ index, value: true })(exploreDispatch);
      setis_favourite(true);
      let apiRes = await henceforthApi.NFT.addToFev(items);
      // toast.success("Added to fevrote")
    } catch (error) {
      if (error.response && error.response.body.error == "UNAUTHORIZED") {
        toast.warn("You must login first.");
      } else if (
        error.response &&
        error.response.body.error == "ALREADY_MARKED_AS_FAVOURITE"
      ) {
        removeToFev(id);
      } else {
        exploreAction.exploreFav({ index, value: false })(exploreDispatch);
        setis_favourite(false);
      }
    }
  };
  const removeToFev = async (id) => {
    try {
      let items = {
        nft_id: id,
        language: "ENGLISH",
      };
      exploreAction.exploreFav({ index, value: false })(exploreDispatch);
      setis_favourite(false);
      if (location.pathname.includes("profile/favorite")) {
        authAction.authFav({ index })(authDispatch);
      }
      let apiRes = await henceforthApi.NFT.removeFromFev(items);
      // toast.success("Removed from fevrote")
    } catch (error) {
      if (error.response && error.response.body.error == "UNAUTHORIZED") {
        toast.warn("You must login first.");
      } else {
        setis_favourite(true);
        exploreAction.exploreFav({ index, value: true })(exploreDispatch);
      }
    }
  };

  return (

    <div className={col}>
      <div className="live-box p-4">
        <Link to={`/content/${_id}/${auction_type == 1 ? "trading" : "bids" }/last_30_days`}>
          <div className="live-img position-relative">
            {String(file_ext).includes("video") ? (
              <video autoPlay muted loop className="w-100 ">
                <source
                  src={henceforthApi.FILES.video(file_url)}
                ></source>
              </video>
            ) : (

              <img src={henceforthApi.FILES.imageMedium(file_url)} className="w-100" />
            )}
          </div>
        </Link>
        <div className="live-text pt-3">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="d-inline-block text-truncate" style={{ maxWidth: '150px' }} title={name}>
              {name}
            </h2>
            {is_favourite_state ? (
              <i
                className="fa fa-heart text-danger"
                onClick={() => removeToFev(_id)}
              ></i>
            ) : (
              <i className="fa fa-heart" onClick={() => addToFev(_id)}></i>
            )}
          </div>
          <div className="owner-name d-flex pt-2">
            <div className="name-img">
              {current_owner ? (
                <img
                  src={
                    current_owner.profile_pic
                      ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${current_owner.profile_pic}`
                      : profile_placeholder
                  }
                />
              ) : (
                <img
                  src={
                    created_by && created_by.profile_pic
                      ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${created_by.profile_pic}`
                      : profile_placeholder
                  }
                />
              )}
            </div>
            <div className="name-text ms-2">
              <h6
                className="mb-0 fs-6 fw-bolderr"
                title={
                  current_owner && current_owner.display_name
                    ? current_owner.display_name
                    : ""
                }
              >
                <Link
                  to={`/profile/${current_owner?._id ? current_owner?._id : created_by?._id
                    }/on_sale`}
                >
                  @
                  {current_owner && current_owner.display_name
                    ? created_by.display_name
                    : `${creator_address?.slice(
                      0,
                      WALLET_ADDRESS_SLICE_FROM
                    )}....${creator_address?.slice(
                      creator_address?.length -
                      WALLET_ADDRESS_SLICE_FROM,
                      creator_address?.length
                    )}`}
                </Link>
              </h6>
              <p
                className="fs-6 text-black-50 mb-0"
                title={
                  created_by && created_by.user_name ? created_by.user_name : ""
                }
              >
                Art by
                <Link className="text-black ms-1" to={`/profile/${created_by._id}/on_sale`}>
                  {created_by && created_by.user_name
                    ? created_by.user_name
                    : `${creator_address?.slice(
                      0,
                      WALLET_ADDRESS_SLICE_FROM
                    )}....${creator_address?.slice(
                      creator_address?.length -
                      WALLET_ADDRESS_SLICE_FROM,
                      creator_address?.length
                    )}`}
                </Link>
              </p>
            </div>
          </div>
          <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
            <div className="bid-left">
              <p className=" text-black-50 mb-0 text-start">Price</p>
              <h6 className="mb-0 fw-bolderr">{price} wETH</h6>
            </div>
            <div className="bid-right">
              {currentUser !== current_owner?._id && signature && (
                <h5 className="fs-6 fw-bolderr mb-0">
                  <Link to={`/content/${_id}/${type === FIXED_PRICE_AUCTION_ID ? "trading" : "bids"}/last_30_days`}>{type === FIXED_PRICE_AUCTION_ID ? 'Buy Now' : 'Make Offer'}</Link>
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
