import { Link, } from "react-router-dom";
import { Fragment } from "react";
import profile_placeholder from "./../assets/images/view_profile.svg";

export default ({
  image_url,
  index,
  title,
  contract_address,
  token_id,
  token_type,
  col,
  callback,
  type
}) => {
  const initialiseType = (index, value) => {
    if (!type) {
      callback({ index, value })
    }
  };

  return <div className={col}>
    <div className="live-box p-4">
      <Link to={`/collection/offer/${contract_address}/${token_id}/${token_type}`}>
        <div className="live-img position-relative">
          {type ? type == "image" ?
            <img src={image_url ? image_url : profile_placeholder} className="w-100" /> :
            <video id="video" src={image_url} autoPlay muted playsInline loop ></video>
            : <Fragment>
              <img src={image_url ? image_url : profile_placeholder} className="w-100" onLoad={(e) => initialiseType(index, 'image')} style={{ display: 'none' }} />
              <video id="video" className="w-100" onLoadedMetadata={() => initialiseType(index, 'video')} onLoadedData={(e) => console.log('onLoadedData', e.target)} src={image_url} autoPlay playsInline style={{ visibility: 'hidden' }}  ></video></Fragment>}
        </div>
      </Link>
      <div className="live-text pt-3">
        <div className="d-flex justify-content-between align-items-center">
        </div>
        <div className="owner-name d-flex pt-2">
          <div className="name-img">
            {type ? type == "image" ?
              <img src={image_url ? image_url : profile_placeholder} className="w-100" /> :
              <video id="video" src={image_url} autoPlay muted playsInline loop ></video>
              : <Fragment>
                <img src={image_url ? image_url : profile_placeholder} className="w-100" onLoad={(e) => initialiseType(index, 'image')} style={{ display: 'none' }} />
                <video id="video" className="w-100" onLoadedMetadata={() => initialiseType(index, 'video')} onLoadedData={(e) => console.log('onLoadedData', e.target)} src={image_url} style={{ visibility: 'hidden' }}  ></video></Fragment>}
          </div>
          <div className="name-text ms-2">
            <h6>{title ? title : parseInt(token_id, 16)}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
};
