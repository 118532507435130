import { EXPLORE_ADD, EXPLORE_FEV, EXPLORE_HOT_COLLECTIONS, EXPLORE_LIVE_AUCTION, EXPLORE_TOP_SELLERS, EXPLORE_TRENDING, EXPLORE_UPDATE, } from "../actionTypes";

const auth = (state, { type, payload }) => {
    switch (type) {
        case EXPLORE_ADD:
            return {
                ...state,
                nfts: {
                    ...payload,
                },
                last_date: new Date()
            }
        case EXPLORE_LIVE_AUCTION:
            return {
                ...state,
                live_auction: {
                    ...payload,
                },
                last_date: new Date()
            }
        case EXPLORE_TOP_SELLERS:
            return {
                ...state,
                top_sellers: {
                    ...payload,
                },
                last_date: new Date()
            }
        case EXPLORE_TRENDING:
            return {
                ...state,
                trending: {
                    ...payload,
                },
                last_date: new Date()
            }
        case EXPLORE_HOT_COLLECTIONS:
            return {
                ...state,
                hot_collections: {
                    ...payload,
                },
                last_date: new Date()
            }
        case EXPLORE_FEV: {
            let oldNfts = state.nfts.data
            let total_count = state.nfts.total_count
            let index = payload.index
            let value = payload.value
            if (total_count > index) {
                oldNfts[index]["is_favourite"] = value
                let allNft = [...oldNfts]
                return {
                    ...state,
                    nfts: {
                        data: allNft,
                        total_count
                    }
                }
            } else {
                return {
                    ...state,
                }
            }

        }
        case EXPLORE_UPDATE: {
            let oldNfts = state.nfts.data
            if (payload.nfts) {
                let newNft = payload.nfts.data
                let newCountData = payload.nfts.total_count
                let allNft = [...oldNfts, ...newNft]
                return {
                    ...state,
                    nfts: {
                        data: allNft,
                        total_count: newCountData
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default auth;