import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "./../assets/images/view_profile.svg";
import { Spinner } from "./BootstrapCompo";

export default ({ display_name, user_name, bio, total_followers, profile_pic, _id, following, followFun, loading, index }) => {
    return <div className="me-0 mb-md-4 mb-sm-4 mb-3">
        <div className="live-box" style={{ width: 270 }}>
            <Link to={`/profile/${_id}/on_sale`}>
                <div className="live-img position-relative">
                    <img src={profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${profile_pic}` : profile_placeholder} className="w-100 roundeded heightt-short" />
                    <div className="name-img olaceholderss new-small-img">
                        <img src={profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${profile_pic}` : profile_placeholder} />
                    </div>
                </div>
            </Link>
            <div className="live-text pt-3 p-3">
                <div className="owner-name pt-2 text-truncate">
                    <div className="name-text ms-2 text-center">
                        <h6 className="mb-0 fw-bolderr">@{display_name ? display_name : 'Not available'}</h6>
                        <p className="fs-6 text-black-50 mb-0">Art by {user_name ? user_name : 'Not available'}</p>
                    </div>
                </div>
                <p className="mb-1 text-truncate">{bio?.length > 20 ? bio?.slice(0, 30) : bio}</p>
                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                    <div className="bid-left">
                        <h6 className="mb-0 fw-bolderr text-start">{total_followers ? total_followers : 0}</h6>
                        <p className="text-black-50">Followers</p>
                    </div>
                    <div className="bid-right">
                        <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" onClick={() => followFun(_id, following, index)}>{loading ? <Spinner /> : following ? 'UNFOLLOW' : 'FOLLOW'}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}