
import profile_placeholder from "../assets/images/profile_pic_profile_page.svg";
import { useMatch } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import { WALLET_ADDRESS_SLICE_FROM } from "./../context/actionTypes";
import TrendingCollNfts from "../components/TrendingCollNfts";

export default () => {
  const {
    authState,
    setLoading,
    loading,
    usdPrice,
    scrollToTop,
    exploreDispatch,
  } = useContext(GlobalContext);
  const [loadMore, setLoadMore] = useState(null)
  const [showMore, setShowMore] = useState(false);
  const [state, setState] = useState({
    data: [],
    total_count: 0,
    page: 0
  });
  const [collectionNfts, setCollectionNfts] = useState({
    nfts: [],
  });
  henceforthApi.setToken(authState.access_token);
  const match = useMatch("/trending-collection/:id");
  const initialiseCollNfts = async (nextToken) => {
    try {
      setLoading(true)
      let apiRes = await henceforthApi.NFT.trendingCollNfts(match.params.id, nextToken, 12)
      let data = apiRes.data
      let nfts = data.nfts
      debugger
      if (nextToken) {
        let oldNfts = collectionNfts.nfts
        let newNfts = [...oldNfts, ...nfts]
        setCollectionNfts({
          ...collectionNfts,
          nfts: newNfts,
          nextToken: data.nextToken
        })
      } else {
        if (nextToken === 0) {
          setCollectionNfts({
            ...collectionNfts,
            ...data,
            nextToken: data.nextToken
          })
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initialiseCollDetails = async () => {
    try {
      let apiRes = await henceforthApi.Collection.getTrendingCollDetails(match.params.id);
      let data = apiRes.data;
      setState(apiRes.data);
    } catch (error) { }
  };

  useEffect(() => {
    if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
      if (collectionNfts?.nfts?.length) {
        if (!loading) {
          if (collectionNfts?.nfts?.length) {
            initialiseCollNfts(collectionNfts.nextToken)
          } else {
          }
        }
      }
    }
  }, [loadMore])

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore)
    return () =>
      window.removeEventListener("scroll", setLoadMore)
  }, [])

  useEffect(() => {
    scrollToTop()
    initialiseCollNfts(0);
    initialiseCollDetails();
  }, [match.params.id]);

  return <section className="activity-wrapper mt-lg-5 mt-4">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src={state.cover_img ? state.cover_img : profile_placeholder}
              className="cover-img cover-hot-featured"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-4 position-relative mb-3">
          <div className="collection-profile p-4">
            <div className="profile-image position-relative m-auto">
              <img
                src={
                  state.image_url ? state.image_url : profile_placeholder
                }
              />
              <div className="position-absolute bottom-0 end-0">
                <label htmlFor="fil">
                  <span className="btn pe-0">
                  </span>
                  <input className="d-none" type="file" id="fil" />
                </label>
              </div>
            </div>
            <div className="text-center">
              <h4 className="mt-4 mb-1 profile-name text-truncate">
                {state.name ? state.name : "Not available"}
              </h4>
              <h6 className="text-gray profile-id mb-0" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(state?.address); toast.success("Copied") }}>{state.address?.length ? `${state?.address.slice(0, WALLET_ADDRESS_SLICE_FROM)}....${state.address?.slice(state?.address?.length - WALLET_ADDRESS_SLICE_FROM, state?.address?.length)}` : ''}</h6>
            </div>
            <hr />
            <div className="">
              <div className="d-flex justify-content-center">
                <div className="border item-box p-3 mb-2">
                  <p className="mb-0 text-gray fs-12 ">Items</p>
                  <p className="mb-0 text-dark fw-bold fs-18 px-4">
                    {state.total_supply ? state.total_supply : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-break detail-collectionn">
              {showMore ? `${state.description}` : `${state.description?.substring(0, 100)}...`}
              {state.description?.length > 100 ?
                <button className="btn-readmore" onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Read less" : "Read more"}
                </button>
                : ""}
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 nav-tabss">
          <div className="on-salle pb-5 mb-lg-5 mt-4 pt-lg-2">
            <ul
              className="nav nav-pills mb-3 border-bottom"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item me-4" role="presentation">
                <button
                  className="nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0 active"
                  id="pills-item-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-item"
                  type="button"
                  role="tab"
                  aria-controls="pills-item"
                  aria-selected="true"
                >
                  Item
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade  show active `}
                id="pills-item"
                role="tabpanel"
                aria-labelledby="pills-item-tab"
              >
                <div className="container p-0">
                  <div className="row">
                    {collectionNfts?.nfts?.map((res, index) => {
                      return <TrendingCollNfts
                        key={res._id}
                        {...res}
                        usdPrice={usdPrice}
                        currentUser={authState._id}
                        index={index}
                        exploreDispatch={exploreDispatch}
                        col="col-lg-4 col-md-6 mb-md-4 mb-sm-3 mb-4"
                        callback={(e) => {
                          let data = collectionNfts.nfts
                          data[e.index]['type'] = e.value
                          setCollectionNfts({
                            ...collectionNfts,
                            nfts: data
                          })
                        }}
                      />
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
