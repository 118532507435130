import { Link } from "react-router-dom";
import profile_placeholder from "./../assets/images/view_profile.svg";

export default ({ name, image_url, _id }) => {
  return <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3 px-0">
    <div className="box-sizem">
      <Link className="btnn-img " to={`/hot-collection/${_id}`}>
        <div className="live-box ">
          <div className="live-img position-relative">
            <img className="roundeded height-incress"
              src={image_url ? image_url : profile_placeholder}
            />
          </div>
          <div className="hot-collection live-text">
            <div className="text-center justify-content-center align-items-center im-whiteing">
              <img
                src={image_url ? image_url : profile_placeholder}
              />
              <h2 className="minum-heit  mt-3 fs-5 px-4 text-truncate">{name}</h2>
            </div>
          </div>
        </div>
      </Link>
    </div>
  </div>
};
