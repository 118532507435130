import { useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { WALLET_ADDRESS_SLICE_FROM } from "../context/actionTypes"
import { GlobalContext } from "../context/Provider"

export default () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { authState, accounts, balanceInEth, usdPrice, scrollToTop } = useContext(GlobalContext)

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/?redirect=${location.pathname}`)
        }
    }, [accounts, authState.access_token])

    return <section className="edit-profile  bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-7 m-auto">
                    <div className="create-content p-4 bg-white m-auto text-center vh-100 h-100">
                        <div className="profile">
                            <h4 className="text-start fs-4 mb-3">Wallet</h4>
                            <div className="etherum-wallet pt-3 pb-3">
                                <p className="mb-2 mt-2"><small>Ethereum Wallet</small></p>
                                <h4 className="mb-2 color-red">${Number(balanceInEth * usdPrice).toFixed(3)}</h4>
                                <p className="bg-walet mb-4 p-0  m-auto mb-2 text-muted"><small>{accounts.length ? `${accounts[0].slice(0, WALLET_ADDRESS_SLICE_FROM)}....${accounts[0].slice(accounts[0].length - WALLET_ADDRESS_SLICE_FROM, accounts[0].length)}` : ''}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}


