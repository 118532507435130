export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const INITIALISE_PROFILE = "INITIALISE_PROFILE";

export const STATIC_DATA_SUCCESS = "STATIC_DATA_SUCCESS";
export const PAGE_LIMIT_SUCCESS = "PAGE_LIMIT_SUCCESS";
export const RIGHT_AFTER_LISTING = "RIGHT_AFTER_LISTING";
export const PICK_SPECIFIC_DATE = "PICK_SPECIFIC_DATE";
export const EXPIRE_1_DAY = "EXPIRE_1_DAY";
export const EXPIRE_3_DAY = "EXPIRE_3_DAY";
export const EXPIRE_5_DAY = "EXPIRE_5_DAY";
export const EXPLORE_ADD = "EXPLORE_ADD";
export const EXPLORE_UPDATE = "EXPLORE_UPDATE";
export const EXPLORE_FEV = "EXPLORE_FEV";
export const EXPLORE_LIVE_AUCTION = "LIVE_AUCTIONS";
export const EXPLORE_ENDED_AUCTIONS = "ENDED_AUCTIONS";
export const EXPLORE_ENDED_BUY_IT_NOW = "ENDED_BUY_IT_NOW";
export const EXPLORE_TOP_SELLERS = "EXPLORE_TOP_SELLERS";
export const EXPLORE_TRENDING = "EXPLORE_TRENDING";
export const EXPLORE_HOT_COLLECTIONS = "EXPLORE_HOT_COLLECTIONS";

export const AUTH_FEV = "AUTH_FEV";

export const FIXED_PRICE_AUCTION_ID = "FIXED_PRICE";
export const UNLIMITED_AUCTION_AUCTION_ID = "UNLIMITED_AUCTION";
export const TIMED_AUCTION_AUCTION_ID = "TIMED_AUCTION";

export const NFT_STEP_0 = 0;
export const NFT_STEP_1 = 1;
export const NFT_STEP_2 = 2;
export const NFT_STEP_3 = 3;


export const WALLET_ADDRESS_SLICE_FROM = 5;

export const REDIRECT_PROFILE = "profile/on_sale";
export const REDIRECT_CONTENT_CREATE = "profile/sale";
export const DEFAULT_COLLECTION_ID = "62b584b4e5ee513c791640f8";

export const CHAT_STATUS = "CHAT_STATUS";
export const SOCIAL_TYPE_PROVIDER = "PROVIDER";
export const SOCIAL_TYPE_META_MASK = "META_MASK";
export const SOCIAL_TYPE_WALLET_CONNECT = "WALLET_CONNECT";

// NFT TYPES
export const ERROR_UNAUTHORIZED = "UNAUTHORIZED";
// NFT TYPES
export const NFT_OWN = "own";
export const NFT_SOLD = "sold";
export const NFT_RE_SALE = "resale";
// social network
export const NFT_INSTAGRAM = "https://www.instagram.com/yourlink";
export const NFT_TWITTER = "https://www.twitter.com/yourlink";
export const NFT_MEDIUM = "https://medium.com/yourlink";
export const NFT_DISCORD = "https://discord.com/channels/@yourlink";
export const NFT_TIK_TOK = "https://www.tiktok.com/@yourlink";
export const NFT_REDDIT = "https://www.reddit.com/user/yourlink/";
