import React, { Component, useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

export default (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const filters = [
        { name: "Recently Added", value: "RECENTLY_ADDED" },
        { name: "Price -- High to Low", value: "PRICE_HIGH_TO_LOW" },
        { name: "Price -- Low to High", value: "PRICE_LOW_TO_HIGH" },
        { name: "Auction Ending Soon", value: "AUCTION_ENDING_SOON" },
    ]
    const onFilterApply = (name, value) => {
        if (query.has(name)) {
            let queryValue = query.get(name)
            // if (queryValue == value) {
            if (value == "") {
                query.delete(name)
            } else {
                query.set(name, value)
            }
        } else {
            query.set(name, value)
        }
        navigate({ search: query.toString() })
    }
    const {name} = props
    return <div className="d-lg-flex justify-content-between pb-lg-2">
        <div className="text-sm-center text-center mb-sm-2 mb-2">
            <div className="dropdown">
                <button className="trant-btn btn btn-light Create-btn pe-4 ps-4 dropdown-toggle" id="sortDropdown" data-bs-toggle="dropdown"
                    aria-expanded="false">{(filters.filter(res => res.value == query.get("sort_by"))).length ? (filters.filter(res => res.value == query.get("sort_by")))[0].name
                        : 'All'}</button>
                <ul className="dropdown-menu" aria-labelledby="sortDropdown">
                    {filters.map(res => {
                        return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => onFilterApply("sort_by", res.value)}>{res.name}</a> </li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}