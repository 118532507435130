import notification from "./../../assets/images/notification_header_orange.svg";
import logo from "./../../assets/images/agria_logo_header.svg";
import wallet from "./../../assets/images/wallet_connected.svg";
import editpro from "./../../assets/images/edit_profile.svg";
import signout from "./../../assets/images/sign_out.svg";
import placeholder_image from "../../assets/images/no-data-found.svg";
import profile_placeholder from "./../../assets/images/profile_placeholder.png";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import {
  WALLET_ADDRESS_SLICE_FROM,
} from "../../context/actionTypes";
import henceforthApi from "../../utils/henceforthApi";
import { Spinner } from "../BootstrapCompo";
import Modals from "../Modals";
export default () => {
  // const match = useMatch()
  let navigate = useNavigate();
  let location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const {
    authState,
    accounts,
    balanceInEth,
    logOutNow,
  } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const notificationDrop = useRef(null);
  const [loading, setLoading] = useState(false);
  const searchDrop = useRef(null);
  const [searchResult, setSearchResult] = useState({
    users: {
      data: [],
      total_count: 0,
    },
    nfts: {
      data: [],
      total_count: 0,
    },
    collections: {
      data: [],
      total_count: 0
    },
  });
  const [notificationData, setNotificationData] = useState({
    data: [],
    page: 0,
    total_count: null,
    show: false
  })


  const initialiseNotification = async (page, b) => {
    setNotificationData({
      ...notificationData,
      show: b
    })
    setLoading(true)
    try {
      let apiRes = await henceforthApi.Notification.get(page)
      let apiData = apiRes.data
      let newData = [...notificationData.data, ...apiData.data]
      const uniqueData = Array.from(new Set(
        newData.map(a => a._id))).map(id => {
          return newData.find(a => a._id === id)
        })
      setNotificationData({
        ...notificationData,
        data: uniqueData,
        page: notificationData.page + 1,
        total_count: apiData.total_count,
        show: b
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const markRead = async (_id, index) => {
    let apiRes1 = await henceforthApi.Notification.markRead(_id)
    initialiseNotification(0, true)
  }
  const markAllRead = async () => {
    let apiRes1 = await henceforthApi.Notification.markAllRead()
    initialiseNotification(0, true)
  }
  useEffect(() => {
    if (authState.access_token) {
      initialiseNotification(0, false)
    }
  }, [authState.access_token])

  const initialiseSearch = async (search) => {
    let searchText = String(search);
    setSearchText(searchText);
    if (searchText) {
      try {
        let apiRes = await henceforthApi.Search.pagination(searchText, 10, 0);
        let data = apiRes.data;
        setSearchResult({
          ...searchResult,
          ...data,
        });
      } catch (error) {
        setSearchResult({
          users: {
            data: [],
            total_count: 0,
          },
          nfts: {
            data: [],
            total_count: 0,
          },
          collections: {
            data: [],
            total_count: 0
          },
        });
      }
    } else {
      setSearchResult({
        users: {
          data: [],
          total_count: 0,
        },
        nfts: {
          data: [],
          total_count: 0,
        },
        collections: {
          data: [],
          total_count: 0
        },
      });
    }
  };

  useEffect(() => {
    let x = searchQuery.has("signin");
    if (searchQuery.has("signin")) {
      let LoginWithSocial = document.getElementById("LoginWithSocial");
      if (LoginWithSocial) {
        LoginWithSocial.click();
      }
    }
  }, [searchQuery.get("signin")]);

  const handleClickOutside = e => {
    if (searchDrop.current && !searchDrop.current.contains(e.target)) {
      setSearchText("")
      setSearchResult({
        users: {
          data: [],
          total_count: 0
        },
        nfts: {
          data: [],
          total_count: 0
        },
        collections: {
          data: [],
          total_count: 0
        },
      })
    } if (notificationDrop.current && !notificationDrop.current.contains(e.target)) {
      setNotificationData({
        ...notificationData,
        show: false
      })
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.has("action")) {
      let LoginWithSocial = document.getElementById("LoginWithSocial");
      if (LoginWithSocial) {
        LoginWithSocial.click();
      }
    }
  }, [searchQuery.get("action")]);

  const hideHeaderDropdown = () => {
    let headerDropdown = document.getElementById("headerDropdown");
    if (headerDropdown) {
      headerDropdown.classList.remove("show");
    }
    setSearchText("");

    setSearchResult({
      users: {
        data: [],
        total_count: 0,
      },
      nfts: {
        data: [],
        total_count: 0,
      },
      collections: {
        data: [],
        total_count: 0
      },
    });
  };

  return (

    <header className="header pt-3 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light pt-0 pb-0" role="navigation">
              <div className="container-fluid p-0">
                <Link
                  className="navbar-brand text-white fw-bold fs-2 me-2"
                  to="/"
                >
                  <img src={logo} className="w-75" />
                </Link>
                <button
                  className="navbar-toggler mdb-select shadow-none"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" ref={searchDrop} id="navbarNav">
                  <div className="input-group rounded justify-content-lg-start mt-3 mt-lg-0" ref={searchDrop}>
                    <div className="dropdown position-relative search-wrapper">
                      <div className="position-relative search-inp">
                        <i className="fas fa-search position-absolute top-50 translate-middle-y start-0 ms-3"></i>

                        <input
                          type="text"
                          id="dropdownMenuClickableInside"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="form-control rounded"
                          placeholder="Search by Users or NFTs or Collections"
                          data-bs-auto-close="outside"
                          value={searchText}
                          onChange={(e) => initialiseSearch(e.target.value)}
                          autoComplete="off"
                        />
                      </div>

                      {searchText && (
                        <ul
                          className="dropdown-menu mt-2 show full-width shadow"
                          id="headerDropdown"
                          aria-labelledby="dropdownMenuClickableInside"
                          role="tablist"
                        >
                          <div className="drop-head d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="contact"
                                  aria-selected="true"
                                >
                                  NFT ({searchResult.nfts.total_count})
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link "
                                  id="home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home"
                                  type="button"
                                  role="tab"
                                  aria-controls="home"
                                  aria-selected="false"
                                >
                                  User ({searchResult.users.total_count})
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link" id="collection-tab" data-bs-toggle="tab" data-bs-target="#collection" type="button" role="tab" aria-controls="collection" aria-selected="false">Collections ({searchResult.collections?.total_count})</button>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade  "
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              {searchResult.users.data.map((res) => {
                                return (
                                  <li key={res._id}>
                                    <Link
                                      className="dropdown-item"
                                      to={`/profile/${res._id}/on_sale`}
                                      onClick={hideHeaderDropdown}
                                    >
                                      <div className="name-img">
                                        <img src={res.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.profile_pic}` : profile_placeholder}></img>
                                        <span className="mx-2"> {res.user_name ? res.user_name : res.display_name}</span>

                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="contact"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              {searchResult.nfts.data.map((res) => {
                                return (
                                  <li key={res._id}>
                                    <Link
                                      className="dropdown-item"
                                      to={`/content/${res._id}/bids/last_30_days`}
                                      onClick={hideHeaderDropdown}
                                    >
                                      <div className="name-img d-flex align-items-center">
                                        {res?.file_ext !== "video/mp4" ?
                                          <img src={res?.file_url ? henceforthApi.FILES.imageSmall(res?.file_url) : profile_placeholder} /> :
                                          <video src={henceforthApi.FILES.video(res?.file_url)}></video>}
                                        <span className="mx-2 text-truncate search-word"> {res.name}</span>

                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="collection"
                              role="tabpanel"
                              aria-labelledby="collection-tab"
                            >
                              {searchResult.collections?.collections?.map((res) => {
                                return (
                                  <li key={res._id}>
                                    <Link
                                      className="dropdown-item"
                                      to={`/${res.type == "TopCollections" ? "hot-collection" : res.type == "TrendingCollections" ? "trending-collection" : "collection"}/${res._id}`}
                                      onClick={hideHeaderDropdown}
                                    >
                                      <div className="name-img text-truncate search-word">
                                        {res.img_url?.includes("https://ipfs") ?
                                          <img src={res?.img_url ? res?.img_url : profile_placeholder} /> :
                                          <img src={res?.img_url ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.img_url}`
                                            : res.image_url} />}
                                        <span className="mx-2 text-truncate"> {res.name ? res.name : `${res.address?.slice(0, 4)}...${res.address?.slice(-4)}`}</span>
                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </div>
                          </div>
                        </ul>
                      )}
                    </div>
                  </div>
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-start align-items-lg-center mt-lg-0 mt-4">
                    {authState.access_token &&
                      <li className="nav-item">
                        <div className="dropdown" ref={notificationDrop}>
                          <div className="w-100 ms-0 notificatons-drop">
                            <img
                              src={notification}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => initialiseNotification(0, true)}
                            />
                            <ul
                              className="dropdown-menu top-100 start-100 translate-middle-x mt-2 pb-3 pt-3 notify"
                              aria-labelledby="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <li>
                                <a className="notifi-hove" href="#">
                                  <div className="d-flex justify-content-between align-items-center mb-2 px-3">
                                    <h5 className="mb-0  mb-0 color-red">
                                      Notifications
                                    </h5>
                                    <p className="mb-0 text-muted ms-2">
                                      <small onClick={() => navigate(`/profile/activity`)}>See all</small>
                                    </p>
                                  </div>
                                </a>
                              </li>



                              {notificationData.total_count !== null ? (
                                notificationData.total_count == 0 ? (
                                  <li>
                                    <div className="imge-placeholder text-center">
                                      <img className="w-100 no-data-found" src={placeholder_image} />
                                      <p className="mt-2 ms-lg-5"> Data not found</p>
                                    </div>
                                  </li>
                                ) : (
                                  notificationData.data.map((res, index) => (
                                    <li key={res._id}>
                                      <Link
                                        className="notification-dropdwn d-flex justify-content-between align-items-center px-3"
                                        to={`/profile/activity#${res._id}`}
                                      >
                                        <p className="mb-0 text-break text-truncate">{res.message}</p>
                                        <i
                                          className="fa fa-times ms-2"
                                          onClick={() => markRead(res._id, index)}
                                        ></i>
                                      </Link>
                                    </li>
                                  ))
                                )
                              ) : (
                                <li>
                                  {" "}
                                  <Spinner />
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </li>}
                    <li className="nav-item pe-lg-2 ps-lg-2">
                      {authState.access_token ? (
                        <Link to={`/content/create`}>
                          <button
                            type="button"
                            className="orange-btn btn btn-light me-lg-3 ms-lg-3 Create-btn shadow-none my-lg-0 my-2"
                          >
                            Create NFT
                          </button>
                        </Link>
                      ) : (
                        <button
                          type="button"
                          id="openLoginModal"
                          className="orange-btn btn btn-light me-lg-3 ms-lg-3 Create-btn shadow-none my-lg-0 my-2 text-white"
                          data-bs-toggle="modal"
                          data-bs-target="#LoginWithSocial"
                        >
                          Create NFT
                        </button>

                      )}
                    </li>

                    {authState.access_token ? (
                      <li className="nav-item view-profile">
                        <div className="dropdown mt-lg-0 mt-2 ms-lg-0">
                          <div className="w-100 ">
                            <button
                              className="trant-btn btn btn-light Create-btn view-img"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  authState.profile_pic
                                    ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}`
                                    : profile_placeholder
                                }
                              />
                              {authState.user_name
                                ? authState.user_name
                                : "..." + authState.social_token.slice(-5)}
                            </button>
                            <ul
                              className="dropdown-menu top-100  translate-middle-x mt-2 pb-0 pt-0 p-4 ps-1 pe-1 profile-logout"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <div className="dropdown-item pt-3">
                                  <div>
                                    <Link
                                      to="/profile/on_sale"
                                      className="text-dark text-decoration-none"
                                    >
                                      <p className="fs-6">
                                        <small>
                                          <img
                                            src={
                                              authState.profile_pic
                                                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}`
                                                : profile_placeholder
                                            }
                                            className="me-2 profile-div-main"
                                          />
                                        </small>
                                        <small>View Profile</small>
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </li>
                              <li className="dropdown-divider my-0"></li>
                              <li>
                                <span className="dropdown-item">
                                  <div className="py-2">
                                    <Link
                                      to="/wallet"
                                      className="text-dark text-decoration-none"
                                    >
                                      <h6 className="mb-0 fs-6 mb-1">
                                        <small>Connected</small>
                                      </h6>
                                      <div className="d-flex justify-content-start align-items-center ">
                                        <img
                                          src={wallet}
                                          className="me-2 profile-div-sin"
                                        />
                                        <div>
                                          <p className="mb-0 fs-6">
                                            <small>
                                              {accounts.length
                                                ? `${accounts[0].slice(
                                                  0,
                                                  WALLET_ADDRESS_SLICE_FROM
                                                )}....${accounts[0].slice(
                                                  accounts[0].length -
                                                  WALLET_ADDRESS_SLICE_FROM,
                                                  accounts[0].length
                                                )}`
                                                : ""}
                                            </small>
                                          </p>
                                          <p className="text-muted mb-2">
                                            <small>
                                              Ethereum • $
                                              {Number(balanceInEth).toFixed(3)}

                                            </small>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </span>
                              </li>
                              <li className="dropdown-divider my-0"></li>
                              <li>
                                <span className="dropdown-item">
                                  <div className="py-2">
                                    <Link
                                      to="/profile/edit"
                                      className="text-dark text-decoration-none"
                                    >
                                      <p className="mb-2  fs-6">
                                        {" "}
                                        <img
                                          src={editpro}
                                          className="me-2 profile-div-sin"
                                        />{" "}
                                        <small>Edit Profile</small>
                                      </p>
                                    </Link>
                                  </div>
                                </span>
                              </li>
                              <li className="dropdown-divider my-0"></li>
                              <li>
                                <span className="dropdown-item pb-3">
                                  <div className="">
                                    <Link
                                      to="/"
                                      onClick={() => logOutNow()}
                                      className="text-dark text-decoration-none"
                                    >
                                      <p className="my-2 fs-6">
                                        {" "}
                                        <img
                                          src={signout}
                                          className="me-2 profile-div-sin"
                                        />{" "}
                                        <small>Sign Out</small>
                                      </p>
                                    </Link>
                                  </div>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item pe-lg-2 ps-lg-2">
                        <button
                          className="trant-btn btn btn-light Create-btn"
                          id="openLoginModal"
                          data-bs-toggle="modal"
                          data-bs-target="#LoginWithSocial"
                        >
                          <span>Sign In</span>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <Modals.LoginWith />
    </header>
  );
};
