import React, { createContext, useEffect, useReducer, useState } from 'react'
import authInitialState from './initialStates/authInitialState';
import exploreInitialState from './initialStates/exploreInitialState';
import auth from './reducers/auth';
import explore from './reducers/explore';
import { BigNumber, ethers } from 'ethers';
import henceforthApi from '../utils/henceforthApi';
import { EXPLORE_LIVE_AUCTION, NFT_STEP_1, NFT_STEP_2, NFT_STEP_3, ERROR_UNAUTHORIZED, FIXED_PRICE_AUCTION_ID, UNLIMITED_AUCTION_AUCTION_ID, NFT_STEP_0, SOCIAL_TYPE_META_MASK } from './actionTypes';

import { toast } from 'react-toastify';
import logoutSuccess from './actions/auth/logoutSuccess';
import axios from 'axios';
import exploreAction from './actions/auth/exploreAction';
import henceforthIPFS from '../utils/henceforthIPFS';
import abis from '../utils/abis';
import GENERIC_ERC721_CONTRACT_ARTIFACTS from '../artifacts/contracts/ERC721/ERC721.sol/GenericERC721.json'
import CREATE_NFT_GENERIC_ERC721_CONTRACT_ARTIFACTS from '../artifacts/contracts/Collections/ERC721/ERC721.sol/GenericERC721.json'
import CREATE_COLLECTIONS_FACTORY721_CONTRACT_ARTIFACTS from '../artifacts/contracts/Collections/CollectionFactory721.sol/CollectionsFactory721.json'

import MARKETPLACE_CONTRACT_ARTIFACTS from '../artifacts/contracts/Marketplace.sol/MarketPlace.json'
import henceforthMarketplaceVoucher from '../utils/henceforthMarketplaceVoucher';
import WalletConnectProvider from '@walletconnect/web3-provider';

const IS_STAGING = (window.origin.includes('staging') || window.origin.includes('localhost'));


export const LIVE_ERC721_CONTRACT_ADDRESS_KEY = '0x3e68e10b19F7ba9652b2bcb1939404a867F177A8'//live
export const STAGING_ERC721_CONTRACT_ADDRESS_KEY = '0xD698750211A7CE987E7f1964a5EAE82F3C5c49dF'//staging
export const ERC721_CONTRACT_ADDRESS_KEY = IS_STAGING ? STAGING_ERC721_CONTRACT_ADDRESS_KEY : LIVE_ERC721_CONTRACT_ADDRESS_KEY

const LIVE_COLLECTION721_CONTRACT_ADDRESS_KEY = '0x963bf9e0bb062f1aA5134a4943b3971cb9976A1a'//live
const STAGING_COLLECTION721_CONTRACT_ADDRESS_KEY = '0x7608c71Ce64F7baa7d552eb86d743b4278da30d6'//staging
export const COLLECTION721_CONTRACT_ADDRESS_KEY = IS_STAGING ? STAGING_COLLECTION721_CONTRACT_ADDRESS_KEY : LIVE_COLLECTION721_CONTRACT_ADDRESS_KEY

const LIVE_WETH_CONTRACT_ADDRESS_KEY = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'//live
const STAGING_WETH_CONTRACT_ADDRESS_KEY = '0xc778417E063141139Fce010982780140Aa0cD5Ab'//staging
const WETH_CONTRACT_ADDRESS_KEY = IS_STAGING ? STAGING_WETH_CONTRACT_ADDRESS_KEY : LIVE_WETH_CONTRACT_ADDRESS_KEY

const LIVE_MARKET_PLACE_CONTRACT_ADDRESS_KEY = '0x49B778B13C926475455A2D3918427571DD63f939'//live
const STAGING_MARKET_PLACE_CONTRACT_ADDRESS_KEY = '0x7d2022B2A05575EF11Db46F1D50a9Cca493c6e4e'//staging
const MARKET_PLACE_CONTRACT_ADDRESS_KEY = IS_STAGING ? STAGING_MARKET_PLACE_CONTRACT_ADDRESS_KEY : LIVE_MARKET_PLACE_CONTRACT_ADDRESS_KEY

export const WALLET_CONNECT_INFURA_ID = 'ef243a0c38ed4616aa3fe5e525db87bf'

export const GlobalContext = createContext({});
export const paybleAmount = (actualPrice, feePercentGloble) => {
    const amountWithQuantity = Number(actualPrice ? actualPrice : 0)
    const currFees = feePercentGloble ? (amountWithQuantity * Number(feePercentGloble)) / 100 : 0
    const bidAmount = currFees + Number(amountWithQuantity)
    const commission = bidAmount - Number(amountWithQuantity)
    return { bidAmount, commission }
}
function GlobalProvider({ children }) {
    const [loading, setLoading] = useState(false)
    const [feePercentGloble, setFeePercentGloble] = useState("")
    const [fileUploadLoading, setFileUploadLoading] = useState(false)
    const [categories, setCategories] = useState([])

    const [accounts, setAccounts] = useState([])
    const [ethChainId, setEthChainId] = useState(null)
    const [balanceInEth, setBalanceInEth] = useState(0)
    const [gasPriceInEth, setGasPriceInEth] = useState(0)

    const [createNftStep, setCreateNftStep] = useState(NFT_STEP_1)
    const [wethStep, setWethStep] = useState(NFT_STEP_0)

    const [wethPrice, setWethPrice] = useState(0)
    const [usdPrice, setUsdPrice] = useState(0)
    const [creaters, setCreaters] = useState({
        data: [],
        page: 0
    })
    const [exploreFilter, setExploreFilter] = useState({
        filters: [],
        page: 0
    })
    const [collectionData, setCollectionData] = useState({
        data: []
    })
    const [trendingCollection, setTrendingCollection] = useState({
        data: []
    })
    const [hotCollection, setHotCollection] = useState({
        data: []
    })
    const [authState, authDispatch] = useReducer(auth, authInitialState, () => {
        const localAuthState = localStorage.getItem("authState");
        return localAuthState ? JSON.parse(localAuthState) : authInitialState
    })
    const [exploreState, exploreDispatch] = useReducer(explore, exploreInitialState, () => {
        const localExploreState = localStorage.getItem("exploreState");
        return localExploreState ? JSON.parse(localExploreState) : exploreInitialState
    })

    const scrollToTop = () => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }


    const fileUpload = async (file) => {
        debugger
        setFileUploadLoading(true)
        const url = `${henceforthApi.API_ROOT}Upload`;
        debugger
        const formDataFile = new FormData();
        formDataFile.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'token': authState.access_token
            }
        }
        try {
            const apiRes = await (await axios.post(url, formDataFile, config)).data
            debugger
            setFileUploadLoading(false)
            debugger
            return apiRes.data.file_name
        } catch (error) {
            setFileUploadLoading(false)
            return null
        }
    }
    const uploadFileIpfs = async (file) => {
        setFileUploadLoading(true)
        const url = `${henceforthApi.API_ROOT}upload/ipfs/file`;
        const formDataFile = new FormData();
        formDataFile.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'token': authState.access_token
            }
        }
        try {
            const apiRes = await (await axios.post(url, formDataFile, config)).data
            setFileUploadLoading(false)
            return apiRes.data
        } catch (error) {
            setFileUploadLoading(false)
            return null
        }
    }
    const ethereumInstalled = () => {
        return window.ethereum
    }

    const walletConnectProvider = new WalletConnectProvider({
        infuraId: WALLET_CONNECT_INFURA_ID,
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        },
        qrcodeModalOptions: {
            mobileLinks: [
                "rainbow",
                "metamask",
                "argent",
                "trust",
                "imtoken",
                "pillar",
            ],
        },
    })

    const loginWithWalletConnect = async () => {
        debugger
        try {
            const connected = walletConnectProvider.connected
            const isConnecting = walletConnectProvider.isConnecting
            if (connected === false) {
                if (isConnecting) {
                    window.location.reload()
                }
                await walletConnectProvider.enable()
            }
            const provider = new ethers.providers.Web3Provider(walletConnectProvider)
            let listAccounts = await provider.listAccounts()
            let network = await provider.getNetwork()
            setAccounts(listAccounts)
            setEthChainId(network)
            return { accounts: listAccounts, eth_chainId: network.chainId, provider }

        } catch (error) {
            debugger
            return null
        }
    }
    const ethereumNetworkRpc = (id) => (
        {
            rpc: {
                chainId: `0x${id}`,
                rpcUrl: `https://${id === 1 ? 'mainnet' : 'rinkeby'}.infura.io/v3/${WALLET_CONNECT_INFURA_ID}`,
                chainName: `Ethereum - ${id === 1 ? 'mainnet' : 'rinkeby'}`
            }
        }
    )

    const RequestMetamaskAddChain = async (rpc) => {
        debugger
        let ethereum = ethereumInstalled()
        if (ethereum) {
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [rpc],
                });
            } catch (addError) {
                console.error(addError);
            }
        }
    }
    const RequestMetamaskChangeChain = async (id) => {
        debugger
        let ethereum = ethereumInstalled()
        if (ethereum) {
            try {
                const { rpc } = ethereumNetworkRpc(id)
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: rpc.chainId }],
                });
                return { rpc }
            } catch (error) {
                console.error('RequestMetamaskChangeChain', error);
                if (error.code === 4902) {
                    // await RequestMetamaskAddChain(rpc)
                    return true
                }
            }
        } else {
            return toast.warn('Please connect mainnet')
        }
    }

    const loginWithMetamaskConnect = async () => {
        const ethereum = ethereumInstalled()
        if (ethereum) {
            const networkVersion = ethereum.networkVersion
            try {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const eth_chainId = parseInt(await ethereum.request({ method: 'eth_chainId' }), 16)
                const provider = new ethers.providers.Web3Provider(ethereum)

                setAccounts(accounts)
                setEthChainId(eth_chainId)
                return { accounts, eth_chainId, provider }

            } catch (error) {
                return null
            }
        }
        return null
    }
    const initialiseMarketPlaceFee = async () => {
        try {
            let apiRes = await henceforthApi.MarketPlace.getfee(
            );
            setFeePercentGloble(Number(apiRes.data.fee_percent))
        } catch (error) {
            console.log(error)
        }

    };

    const initialiseFeePercent = async () => {
        try {

            let { contract, accounts, provider, signer } = await getMarketPlaceContract()
            let feePercentInBigNumber = await contract.functions.feePercent()
            if (Array.isArray(feePercentInBigNumber)) {
                let feePercent = feePercentInBigNumber[0]?.toNumber()
                if (typeof feePercent == 'number') {
                    setFeePercentGloble(feePercent)
                }
                console.log('feePercent', feePercent);
            }
        } catch (error) {
            console.log('error', error);
            debugger
        }
    }

    const getMyProvider = async () => {
        try {
            const { accounts, eth_chainId, provider } = (authState.provider === SOCIAL_TYPE_META_MASK) ? await loginWithMetamaskConnect() : await loginWithWalletConnect()
            let balanceInWei = await provider.getBalance(accounts[0])
            let gasPriceInWei = await provider.getGasPrice()

            let balanceInEth = ethers.utils.formatEther(balanceInWei)
            let gasPriceInEth = ethers.utils.formatEther(gasPriceInWei)
            setBalanceInEth(balanceInEth)
            setGasPriceInEth(gasPriceInEth)
            setAccounts(accounts)

            return { provider, accounts, eth_chainId, balanceInEth, gasPriceInEth, error: null }
        } catch (error) {
            console.log('error', error);
            debugger
            return { error }
        }
    }
    const logOutNow = async () => {
        try {
            setAccounts([])
            logoutSuccess()(authDispatch);
            return true
        } catch (error) {
            return false
        }
    }
    const initialiseCategories = async () => {
        try {
            let apiRes = await henceforthApi.NFT.categories()
            let data = apiRes.data
            setCategories(data.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        initialiseCategories()
        initialiseMarketPlaceFee()
    }, [])

    useEffect(() => {
        localStorage.setItem("authState", JSON.stringify(authState))
    }, [authState]);
    useEffect(() => {
        localStorage.setItem("exploreState", JSON.stringify(exploreState))
    }, [exploreState]);

    const initialiseHomeData = async () => {

        try {
            const apiRes = await henceforthApi.NFT.exploreHome("", "", 0)
            const live_auction = await henceforthApi.NFT.exploreHome(EXPLORE_LIVE_AUCTION, "", 0)
            const collectionApiRes = await henceforthApi.Collection.getHome()
            setCollectionData(collectionApiRes.data)
            const collectionTrendingApiRes = await henceforthApi.Collection.getTrendingCollection()
            setTrendingCollection(collectionTrendingApiRes.data)
            const collectionHotApiRes = await henceforthApi.Collection.getHotCollection()
            setHotCollection(collectionHotApiRes.data)
            exploreAction.addAll(apiRes.data)(exploreDispatch)
            exploreAction.liveAuction(live_auction.data)(exploreDispatch)
        } catch (error) {
            if (error.response && error.response.body) {
                if (error.response.body.error == ERROR_UNAUTHORIZED) {
                    logOutNow()
                }
            }
        }
        // const apiRes = await henceforthApi.NFT.exploreHome("", "", 0)
        // const live_auction = await henceforthApi.NFT.exploreHome(EXPLORE_LIVE_AUCTION, "", 0)
        // const collectionApiRes = await henceforthApi.Collection.getHome()
        // setCollectionData(collectionApiRes.data)
        // exploreAction.addAll(apiRes.data)(exploreDispatch)
        // exploreAction.liveAuction(live_auction.data)(exploreDispatch)

    }
    useEffect(() => {
        initialiseHomeData()
    }, [])

    useEffect(() => {
        const ethereum = ethereumInstalled()
        if (ethereum) {
            ethereum.on('accountsChanged', (e) => { console.log("on accountsChanged", e); logOutNow() })
            ethereum.on('chainChanged', (e) => getMyProvider(authState.provider))
            ethereum.on('close', (e) => console.log("on close", e))
            ethereum.on('networkChanged', (e) => { console.log("on networkChanged", e); getMyProvider(authState.provider) })
            ethereum.on('notification', (e) => console.log("on notification", e))
        }
        if (authState.access_token) {
            getMyProvider(authState.provider)
            initialiseFeePercent()
        }
    }, [authState.access_token])

    const initialiseUsdPrice = async () => {
        let ethusd = await henceforthApi.Common.ethusd()
        setUsdPrice(ethusd.body.USD)
    }
    const initialiseWethPrice = async () => {
        let ethtoweth = await henceforthApi.Common.ethtoweth()
        setWethPrice(ethtoweth.body.WETH)
    }

    useEffect(() => {
        initialiseWethPrice()
        initialiseUsdPrice()
    }, [])

    const requestChainChange = async (eth_chainId) => {
        debugger
        console.log('henceforthApi.IS_STAGING', henceforthApi.IS_STAGING);
        if (authState.provider === SOCIAL_TYPE_META_MASK) {
            if (henceforthApi.IS_STAGING) {
                if (eth_chainId !== 4) {
                    await RequestMetamaskChangeChain(4)
                }
                return true
            } else {
                if (eth_chainId !== 1) {
                    await RequestMetamaskChangeChain(1)
                }
            }
        }
        return true
    }
    const getWethContract = async (actualPrice) => {
        debugger
        const etherPrice = ethers.utils.parseEther(Number(actualPrice).toFixed(18));
        const options = { value: etherPrice }
        const abi = abis.IERC20();
        const { provider, accounts, eth_chainId } = await getMyProvider()
        const signer = provider.getSigner()
        const contract = new ethers.Contract(WETH_CONTRACT_ADDRESS_KEY, abi, signer);
        const allowanceERC20Tx = await contract.allowance(accounts[0], MARKET_PLACE_CONTRACT_ADDRESS_KEY)
        const balanceOfERC20Tx = await contract.balanceOf(accounts[0])
        debugger
        const buyPrice = ethers.utils.formatEther(etherPrice)
        const allowancePrice = ethers.utils.formatEther(allowanceERC20Tx)

        if (Number(buyPrice) > Number(allowancePrice)) {
            setWethStep(NFT_STEP_1)
            const depositERC20Tx = await contract.deposit(options)
            let depositERC20TxRes = await depositERC20Tx.wait();
            setWethStep(NFT_STEP_2)
            const approvalERC20Tx = await contract.approve(MARKET_PLACE_CONTRACT_ADDRESS_KEY, etherPrice)
            let approvalERC20TxRes = await approvalERC20Tx.wait();
            setWethStep(NFT_STEP_3)
            return { contract, accounts, provider, signer, depositERC20TxRes, approvalERC20TxRes }
        }
        return { contract, accounts, provider, signer }
    }

    const getMarketPlaceContract = async () => {
        const abi = MARKETPLACE_CONTRACT_ARTIFACTS.abi;
        const { provider, accounts } = await getMyProvider()
        const signer = provider.getSigner()
        const contract = new ethers.Contract(MARKET_PLACE_CONTRACT_ADDRESS_KEY, abi, signer);
        return { contract, accounts, provider, signer }
    }
    const getERC721Contract = async (tokenContract) => {
        const abi = GENERIC_ERC721_CONTRACT_ARTIFACTS.abi;
        const { provider, eth_chainId } = await getMyProvider()
        const signer = provider.getSigner()
        const contract = new ethers.Contract(tokenContract, abi, signer);
        return contract
    }
    // const getERC1155Contract = async () => {
    //     const abi = GENERIC_ERC1155_CONTRACT_ARTIFACTS.abi;
    //     const { provider } = await getMyProvider()
    //     const signer = provider.getSigner()
    //     const contract = new ethers.Contract(ERC1155_CONTRACT_ADDRESS_KEY, abi, signer);
    //     return contract
    // }
    const createCollection721Contract = async () => {
        setLoading(true)
        const abi = CREATE_COLLECTIONS_FACTORY721_CONTRACT_ARTIFACTS.abi;
        const { provider, accounts } = await getMyProvider()
        const signer = provider.getSigner()
        const contract = new ethers.Contract(COLLECTION721_CONTRACT_ADDRESS_KEY, abi, signer);
        return { contract, accounts }
    }
    // const createCollection1155Contract = async () => {
    //     setLoading(true)
    //     const abi = CREATE_COLLECTIONS_FACTORY1155_CONTRACT_ARTIFACTS.abi;
    //     const { provider, accounts } = await getMyProvider()
    //     const signer = provider.getSigner()
    //     const contract = new ethers.Contract(COLLECTION1155_CONTRACT_ADDRESS_KEY, abi, signer);
    //     return { contract, accounts }
    // }
    const getNftERC721Contract = async (location) => {
        var query = new URLSearchParams(location.search);
        const collectionAddress = query.get("collection")
        setLoading(true)
        const abi = CREATE_NFT_GENERIC_ERC721_CONTRACT_ARTIFACTS.abi;
        const { provider, accounts } = await getMyProvider()
        const signer = provider.getSigner()
        const contract = new ethers.Contract(collectionAddress ? collectionAddress : ERC721_CONTRACT_ADDRESS_KEY, abi, signer);
        return { contract, accounts }
    }
    // const getNftERC1155Contract = async (location) => {
    //     var query = new URLSearchParams(location.search);
    //     const collectionAddress = query.get("collection")
    //     setLoading(true)
    //     const abi = CREATE_NFT_GENERIC_ERC1155_CONTRACT_ARTIFACTS.abi;
    //     const { provider, accounts } = await getMyProvider()
    //     const signer = provider.getSigner()
    //     const contract = new ethers.Contract(collectionAddress ? collectionAddress : COLLECTION1155_CONTRACT_ADDRESS_KEY, abi, signer);
    //     return { contract, accounts }
    // }
    const createCollection721 = async (_name, _symbol, _icon, description) => {
        try {
            let image = await uploadFileIpfs(_icon)
            let metadata = (await henceforthApi.Ipfs.metadata(JSON.stringify({
                name: _name,
                symbol: _symbol,
                image,
                description
            }))).data
            let metadataFullUri = henceforthIPFS.ipfsGetFileUrl(metadata)
            let { contract, accounts } = await createCollection721Contract()
            const contractResFun = await contract.functions.createToken(_name, _symbol, henceforthIPFS.baseUrl, metadataFullUri, accounts);
            const contractRes = await contractResFun.wait();
            setLoading(false)
            return contractRes
        } catch (error) {
            setLoading(false)
            return null
        }
    }
    // const createCollection1155 = async (_name, _symbol, _icon, description) => {
    //     try {
    //         let image = (await henceforthIPFS.ipfsClient.add(_icon)).path
    //         let metadata = (await henceforthIPFS.ipfsClient.add(JSON.stringify({
    //             name: _name,
    //             symbol: _symbol,
    //             image,
    //             description
    //         }))).path
    //         let metadataFullUri = henceforthIPFS.ipfsGetFileUrl(metadata)
    //         let { contract, accounts } = await createCollection1155Contract()
    //         const contractResFun = await contract.functions.createToken(_name, _symbol, henceforthIPFS.baseUrl, metadataFullUri, accounts);
    //         const contractRes = await contractResFun.wait();
    //         setLoading(false)
    //         return contractRes
    //     } catch (error) {
    //         console.log("Error", error);
    //         setLoading(false)
    //         return null
    //     }
    // }

    const createNFT721 = async (metadata, royalties, location,) => {
        debugger
        setLoading(true)
        setCreateNftStep(NFT_STEP_1)
        let { contract, accounts } = await getNftERC721Contract(location)
        if (contract) {
            try {
                const newNft = await contract.functions.createNFT(metadata, Number(royalties));
                const nftResponse = await newNft.wait();
                debugger
                return { ...nftResponse, tokenContract: contract.address, accounts }
            } catch (error) {
                console.log('createNFT721 error', error);
                setLoading(false)
                return null
            }
        }
    }

    // const createNFT1155 = async (metadata, royalties, location, supply) => {
    //     setLoading(true)
    //     setCreateNftStep(NFT_STEP_1)
    //     let { contract } = await getNftERC1155Contract(location)eEEF
    //     if (contract) {
    //         try {
    //             const newNft = await contract.functions.createToken(metadata, supply, Number(royalties));
    //             const nftResponse = await newNft.wait();
    //             console.log("createNFT res", nftResponse);
    //             debugger
    //             return nftResponse
    //         } catch (error) {
    //             console.log("Error", error);
    //             setLoading(false)
    //             return null
    //         }
    //     }
    // }
    const createVoucher = async (price, auctionType, tokenContract, endTime) => {
        let { contract, accounts, signer } = await getMarketPlaceContract()
        if (contract) {
            const ether = ethers.utils.parseEther(Number(price).toFixed(18));
            await henceforthMarketplaceVoucher.setToken(contract, signer);
            let salt = Math.round(new Date().getTime())
            const { signature } = await henceforthMarketplaceVoucher.CreateVoucher(String(accounts[0]).toLocaleLowerCase(), auctionType, 1, endTime, ether, String(tokenContract).toLocaleLowerCase(), salt)
            let itemsVoucher = {
                owner_address: accounts[0],
                price: Number(price),
                auction_type: `${auctionType}`,
                signature,
                end_date: endTime,
                salt,
            }
            return itemsVoucher
        }
    }
    const createAuctionNow = async (metadata, price, royalties, location, auctionType, end_date) => {
        let auctionValue = auctionType == FIXED_PRICE_AUCTION_ID ? 1 : auctionType == UNLIMITED_AUCTION_AUCTION_ID ? 2 : 3
        setLoading(true)
        let endTime;
        if (end_date) {
            endTime = Math.round(new Date(Number(end_date)).getTime() / 1000)
        }
        try {
            let createNftRes = await createNFT721(metadata, royalties, location)
            if (createNftRes == null) {
                setLoading(false)
                return null
            }
            const approveRes = await setApproveFun(createNftRes.tokenContract)
            debugger
            if (approveRes) {
                setCreateNftStep(NFT_STEP_3)
                const events = createNftRes.events;
                let _tokenId = ""
                events.forEach(element => {
                    if (element.args) {
                        let args = element.args
                        if (args.tokenId) {
                            _tokenId = args.tokenId
                        }
                        if (args._tokenId) {
                            _tokenId = args._tokenId
                        }
                    }
                })
                const itemsVoucher = await createVoucher(price, auctionValue, createNftRes.tokenContract, endTime ? endTime : 0)

                setLoading(false)
                return { itemsVoucher, token_id: _tokenId._hex }
            }

        } catch (error) {
            setLoading(false)
            return null
        }
    }

    const finaliseFixedPriceAuction = async (owner_address, voucher, signature, _tokenContract, actualPrice) => {
        if (!actualPrice) {
            return null
        }
        try {
            let { contract, accounts, provider, signer } = await getMarketPlaceContract()
            let feePercentInBigNumber = await contract.functions.feePercent()
            let taxPercent = feePercentInBigNumber[0].toNumber()
            debugger
            const amountWithQuantity = Number(actualPrice)
            const currFees = (amountWithQuantity * taxPercent) / 100
            const bidAmount = currFees + Number(amountWithQuantity)
            const commission = bidAmount - Number(amountWithQuantity)
            if (contract) {
                await getWethContract(bidAmount)
                let taskRes = await contract.functions.finaliseFixedPriceAuction721(owner_address, voucher, signature, _tokenContract)
                const nftResponse = await taskRes.wait();
                setWethStep(NFT_STEP_0)
                setLoading(false)
                return { ...nftResponse, commission }
            }
        } catch (error) {
            setLoading(false)
            setWethStep(NFT_STEP_0)
            if (error.data && error.data.message) {
                toast.error(error.data.message)
            }
            toast.error(error?.error?.message)
            return null
        }
    }
    const finaliseERC721Auction = async (buyer_address, voucher, signature, _tokenContract, actualPrice) => {
        if (!actualPrice) {
            return null
        }
        const etherPrice = ethers.utils.parseEther(Number(actualPrice).toFixed(18));
        setLoading(true)
        try {
            let { contract, accounts, provider, signer } = await getMarketPlaceContract()
            if (contract) {
                debugger

                let taskRes = await contract.functions.finaliseERC721Auction(buyer_address, accounts[0], voucher, signature, etherPrice, _tokenContract)
                const nftResponse = await taskRes.wait();
                setLoading(false)
                debugger
                return { ...nftResponse }
            }
        } catch (error) {
            setLoading(false)
            if (error?.data && error?.data?.message) {
                toast.error(error.data.message)
            }
            if (error?.error) {
                debugger
                toast.error(error?.error?.message)
            }
            return null
        }
    }

    const bidOnTimedAuction = async (actualPrice, feePercentGloble) => {
        if (!actualPrice) {
            return null
        }
        // setLoading(true)
        try {
            const amountWithQuantity = Number(actualPrice)
            const currFees = (amountWithQuantity * Number(feePercentGloble)) / 100
            const bidAmount = currFees + Number(amountWithQuantity)
            const commission = bidAmount - Number(amountWithQuantity)
            let contractRes = await getWethContract(bidAmount)
            setWethStep(NFT_STEP_0)
            setLoading(false)
            return { ...contractRes, commission }
        } catch (error) {
            setWethStep(NFT_STEP_0)
            setLoading(false)
            if (error.reason) {
                toast.error(error.reason)
            }
            if (error.data && error.data.message) {
                toast.error(error.data.message)
            }
            return null
        }
    }

    const requestApprove = async (nftItContracts, tokenContract) => {
        try {
            const taskRes = await nftItContracts.functions.setApprovalForAll(MARKET_PLACE_CONTRACT_ADDRESS_KEY, true);
            const approveRes = await taskRes.wait();
            return approveRes
        } catch (error) {
            if (error.message) {
                toast.error(error.message)
            }
            return null
        }
    }
    const setApproveFun = async (tokenContract) => {
        setCreateNftStep(NFT_STEP_2)
        const { accounts, eth_chainId } = await getMyProvider()
        // await requestChainChange(eth_chainId)
        if (accounts.length) {
            try {
                let nftItContracts = await getERC721Contract(tokenContract)
                debugger
                const isApproveForAllRes = await nftItContracts.functions.isApprovedForAll(accounts[0], MARKET_PLACE_CONTRACT_ADDRESS_KEY);
                if (Array.isArray(isApproveForAllRes) && isApproveForAllRes.length) {
                    let isApproved = isApproveForAllRes[0]
                    if (isApproved) {
                        return isApproved
                    } else {
                        return await requestApprove(nftItContracts, tokenContract)
                    }
                } else {
                    return await requestApprove(nftItContracts)
                }
            } catch (error) {
                console.log('error', error);
                return null
            }
        } else {
            alert("Account not found")
            return false
        }
    }

    const cancelAuction = async (order_id) => {
        const hexToBigNumber = BigNumber.from(order_id)
        try {
            let contracts = await getMarketPlaceContract()
            if (contracts) {
                const newNft = await contracts.functions.cancelAuction(hexToBigNumber);
                return newNft
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message)
            return null
        }
    }

    return (
        <GlobalContext.Provider
            value={{
                loading,
                fileUploadLoading,
                wethStep,
                categories,
                setLoading,
                fileUpload,
                createNftStep,
                scrollToTop,
                getMyProvider,
                createCollection721,
                authState,
                authDispatch,
                logOutNow,
                exploreState,
                exploreDispatch,
                accounts,
                gasPriceInEth,
                balanceInEth,
                wethPrice,
                usdPrice,
                eth_chain_id: ethChainId,
                collectionData,
                trendingCollection,
                hotCollection,
                exploreFilter,
                setExploreFilter,
                cancelAuction,
                creaters,
                uploadFileIpfs,
                setCreaters,
                initialiseHomeData,
                createAuctionNow,
                createVoucher,
                finaliseFixedPriceAuction,
                bidOnTimedAuction,
                finaliseERC721Auction,
                setApproveFun,
                // collectionList, 
                // setCollectionList,
                loginWithWalletConnect,
                loginWithMetamaskConnect,
                feePercentGloble
            }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
