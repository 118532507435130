import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { toast } from "react-toastify";
import loginSuccess from "../context/actions/auth/loginSuccess";
import { Spinner } from "../components/BootstrapCompo";
import profile_placeholder from "./../assets/images/view_profile.svg";
import Modals from "../pages/Modals";
import { nameValidation } from "../utils/validations";
var countdownTimer = null;

export default () => {
  let location = useLocation();
  let navigate = useNavigate();
  const searchParam = new URLSearchParams(location.search);
  const [timerCount, setTimerCount] = useState(30);
  const {
    authState,
    accounts,
    authDispatch,
    scrollToTop,
    fileUpload,
    loading,
    setLoading,
  } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [state, setState] = useState({
    ...authState,
    loading: false,
  });

  const handleInput = ({ name, value }) => {
    setState({
      ...state,
      [name]: value,
      loading: false,
    });
  };

  const updateProfile = async (items, b) => {
    setLoading(true);
    try {
      let apiRes = await henceforthApi.Profile.edit(items);
      let data = apiRes.data;
      loginSuccess(data)(authDispatch);
      setState({
        ...state,
        loading: false,
        loading_profile_icon: false,
      });
      if (b) {
        if (searchParam.has("redirect")) {
          navigate(`/${searchParam.get("redirect")}`);
        } else {
          navigate(`/profile/on_sale`);
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.body.error_description);
      setLoading(false);
      setState({
        ...state,
        loading: false,
        loading_profile_icon: false,
      });
    }
  };

  const onUpdateInputField = async () => {
    const {
      bio,
      user_name,
      email,
      display_name,
    } = state;
    let items = {
      user_name: user_name,
      display_name: display_name,
      language: "ENGLISH",
    };
    if (email) {
      items["email"] = email;
    }
    if (bio) {
      items["bio"] = bio;
    }
    setState({
      ...state,
      loading: true,
    });
    await updateProfile(items, true);
  };
  const onUpdateProfilePicture = async (file) => {
    setState({
      ...state,
      loading_profile_icon: true,
    });
    let fileName = await fileUpload(file);
    let items = {
      profile_pic: fileName,
      language: "ENGLISH",
    };
    await updateProfile(items, false);
  };
  const initialiseEmailVerify = (data) => {
    loginSuccess(data)(authDispatch);
  };
  const resendOtp = async () => {
    try {
      let items = {
        email: authState.email,
        language: "ENGLISH",
      };
      let apiRes = await henceforthApi.Auth.resendOtp(items);
      toast.success(apiRes.message);
      initialiseInterval();
      return apiRes.data;
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        toast.error(error.response.body.error_description);
      }
    }
  };

  const changeEmail = async () => {
    if (!loading) {
      const { email } = state;
      let items = {
        email: email,
        language: "ENGLISH",
      };
      await updateProfile(items, false);
    }
  };

  const initialiseInterval = () => {
    var _second = 30;
    function timer() {
      if (_second < 1) {
        clearInterval(countdownTimer);
        return;
      } else {
        _second -= 1;
      }
      setTimerCount(_second);
    }
    countdownTimer = setInterval(() => timer(), 1000);
  };
  useEffect(() => {
    if (authState.access_token) {
      scrollToTop();
    } else {
      navigate(`/?action=login&redirect=${location.pathname}`, {
        replace: true,
      });
    }
  }, [accounts, authState.access_token]);
  const openVerifyEmailLink = async (email) => {
    if (!authState.email_verified) {
      let apiRes = await henceforthApi.Auth.resendOtp({
        email: email,
        language: "ENGLISH",
      });
    }
    if (countdownTimer == null) {
      initialiseInterval();
    }
  };

  return <section className="edit-profile bg-light">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-7 m-auto">
          <div className="create-content p-4 bg-white m-auto">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onUpdateInputField();
              }}
            >
              <div className="profile">
                <h4 className="">Edit Profile</h4>
                <p className="mb-0 p-color">
                  You can manage your display name or other personal settings.
                </p>
                <div className="profile-image  text-center d-flex justify-content-center align-items-center mt-4 mb-3">
                  <div className="imgesss  text-center position-relative">
                    <div className="icon-img  ">
                      <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}` : profile_placeholder}/>
                    </div>
                    <div className="camera-img position-absolute bottom-0 end-0 ">
                      <label htmlFor="filProfile">
                        <a className="btn pe-0">
                          <i
                            className={`fas ${state.loading_profile_icon
                              ? "fa-spinner"
                              : "fa-camera"
                              } rounded-circle `}
                          ></i>
                        </a>
                      </label>
                      <input
                        className="d-none"
                        type="file"
                        accept="image/*"
                        id="filProfile"
                        onChange={(e) =>
                          onUpdateProfilePicture(e.target.files[0])
                        }
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-wrapper ">
                  <label className="mb-1" htmlFor="">
                    Display Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control shadow-none "
                      placeholder="Enter your display name"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                      title="Please enter a valid name"
                      name="display_name"
                      maxLength={20}
                      value={state.display_name}
                      onChange={(e) => nameValidation(e.target.value) ? handleInput(e.target) : console.log(e.target)}
                      disabled={loading}
                      required
                    />
                  </div>
                </div>
                <div className="input-wrapper ">
                  <label className="mb-1" htmlFor="">
                    Username
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control shadow-none "
                      placeholder="Enter your display name"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                      title="Please enter a valid username"
                      name="user_name"
                      maxLength={24}
                      value={state.user_name}
                      onChange={(e) => handleInput(e.target)}
                      disabled={loading}
                      required
                    />
                  </div>
                </div>
                <div className="input-wrapper ">
                  <label className="mb-1" htmlFor="">
                    Bio
                  </label>
                  <div className="input-group mb-3">
                    <div className="form-floating w-100">
                      <textarea
                        className="form-control resize-none shadow-none h-100"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        rows="4"
                        cols="50"
                        name="bio"
                        value={state.bio}
                        onChange={(e) => handleInput(e.target)}
                        disabled={loading}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group email-input position-relative">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={state.email}
                      onChange={(e) => handleInput(e.target)}
                      required
                    />
                    {state.email !== authState.email ? (
                      <button
                        type="button"
                        className="eth-btnconfirm"
                        onClick={() => changeEmail()}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="eth-btnconfirm"
                        data-bs-toggle="modal"
                        data-bs-target={
                          authState.email_verified ? "" : "#VerifyEmailModal"
                        }
                        onClick={() => openVerifyEmailLink(authState.email)}
                      >
                        {authState.email_verified ? "Verified" : "Confirm"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="footer-buttoon d-flex justify-content-between align-items-center mt-2 pb-lg-3">
                  <button
                    type="submit"
                    className="orange-btn btn shadow-none rounded-pill w-100 me-2"
                    disabled={loading}
                  >
                    {loading ? <Spinner /> : "Update Profile"}
                  </button>
                  <button
                    type="button"
                    className="trant-btn btn   shadow-none rounded-pill w-100"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Modals.VerifyEmail
      initialiseProfile={initialiseEmailVerify}
      resendOtp={resendOtp}
      timerCount={timerCount}
    />
  </section>
};
