import bgimage from "./../assets/images/bg-image.png";
import create from "./../assets/images/create_and_sell_nft.svg";
import name from "./../assets/images/view_profile.svg";
import otheruser from "./../assets/images/other_user_cover_photo.png";
import otheruserprofile from "./../assets/images/my_profile_pic.png";
import imgone from "./../assets/images/img_one.png";
import imgtwo from "./../assets/images/img_two.png";
import imgthree from "./../assets/images/img_three.png";
import imgfour from "./../assets/images/img_four.png";

export default () => {
    return (<>
        <section className="profile-activity position-relative">
            <div className="container-fluid p-0">
                <div className="bg-imgae">
                    <div className="bg-grey w-100  bg-light d-inline-block">
                        <img className="w-100" src={otheruser} />
                    </div>
                </div>
            </div>
        </section>
        <section className="activity-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 position-relative">
                        <div
                            className="profile-image top-part text-center d-flex justify-content-center align-items-center ">
                            <div className="imgesss  bg-light text-center">
                                <div className="icon-img position-relative">
                                    <img className="border border-4 border-white" src={otheruserprofile} />

                                    <div className="camera-img position-absolute">
                                        <label htmlFor="fil">
                                            <a className="btn pe-0">
                                                <i className="fas fa-camera bg-light border border-2  rounded-circle p-1"></i>
                                            </a>
                                        </label>
                                        <input className="d-none" type="file" id="fil" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-inline pt-5 text-center">
                            <h4 className=" mt-3  mb-0">User Name</h4>
                            <p className="p-color">@user_name</p>
                            <div className="left-icon bg-white d-flex justify-content-center vw-50 mt-lg-3 mb-lg-3 m-auto mb-3">
                                <i
                                    className="fas fa-pencil p-2 bg-white text-muted m-0 border-start text-muted fs-6 border-end-0 border fs-6 like-icon"></i>
                                <i
                                    className="fas fa-share-alt p-2 bg-white border border-top border-bottom text-muted m-0 fs-6 share-icon"></i>

                                <i
                                    className="far fa-ellipsis-h p-2 bg-white text-muted m-0 border-end border-top border-bottom text-muted fs-6 report-icon"></i>
                            </div>
                            <div className="border border-end-0 border-start-0">
                                <div
                                    className="folloers d-flex justify-content-center align-items-center w-75 m-auto">
                                    <div className="five-nine me-4 lh-sm">
                                        <p className="text-start mb-0  pt-3">5</p>
                                        <p className="fs-6 text-muted"><small>Following</small></p>
                                    </div>
                                    <div className="five-nine me-2 lh-sm me-2">
                                        <p className="text-start mb-0 fw-bold pt-3 fs-6">9</p>
                                        <p className="text-muted fs-6">Following</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 text-center">
                                <button className="trant-btn w-75 btn btn-light Create-btn mb-4" data-bs-toggle="modal" data-bs-target="#exampleModal"><span>Follow</span></button>
                            </div>
                            <div className="bio">
                                <p className="text-muted mb-0 mt-lg-3">
                                    Bio
                                </p>
                                <p className="fs-6 p-3 pt-0">
                                    <small>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacinia quis metus et
                                        congue. Proin dignissim sem erat, at pretium sapien consequat ut. Nulla augue magna,
                                        egestas quis faucibus bibendum, volutpat vel sem.
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="on-salle pb-5 mb-lg-5">
                            <ul className="nav nav-pills mb-4 border-bottom mt-lg-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active text-secondary  bg-transparent  ps-0"
                                        id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button"
                                        role="tab" aria-controls="pills-home" aria-selected="true">On Sale
                                        <small className="light-color px-1 p-color ms-1">3</small></button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-secondary  bg-transparent"
                                        id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Sold
                                        <small className="light-color px-1 p-color ms-1">10</small></button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-secondary  bg-transparent"
                                        id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                                        type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Owned
                                        <small className="light-color px-1 p-color ms-1">2</small></button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-secondary  bg-transparent" id="messages-tab"
                                        data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab"
                                        aria-controls="messages" aria-selected="false">Created <small
                                            className="light-color p-color px-1">2</small></button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-secondary  bg-transparent" id="settings-tab"
                                        data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab"
                                        aria-controls="settings" aria-selected="false">Favorited</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-secondary  bg-transparent" id="profile-tab"
                                        data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab"
                                        aria-controls="profile" aria-selected="false">Activity</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className="d-flex justify-content-between align-items-center pb-2">
                                                    <div className="explore-btns">
                                                        <div className="btnss-cat d-flex">
                                                            <button className="trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4   pe-lg-2 px-sm-3 py-sm-2 me-sm-2 mb-sm-0" href="#">Category</button>
                                                            <button className="trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4   pe-lg-2 px-sm-3 py-sm-2 me-sm-2 mb-sm-0" href="#">Collection</button>
                                                            <button className="trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4   pe-lg-2 px-sm-3 py-sm-2 me-sm-2 mb-sm-0" href="#">Sale Type</button>
                                                            <button className="trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4   pe-lg-2 px-sm-3 py-sm-2 me-sm-2  mt-md-0 mt-2" href="#">Price Range</button>
                                                        </div>
                                                    </div>
                                                    <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" href="#">Filter & Sort</button>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgone} className="w-100" />
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Remove</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgtwo} className="w-100" />
                                                        <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                            <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                        </div>
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <p className="text-black-50 mb-0"><small>Highest bid</small></p>
                                                                <h6 className="mb-0 fw-bolderr"><small>0.01 wETH</small></h6>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Remove</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgthree} className="w-100" />
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Remove</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">

                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgtwo} className="w-100" />
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">

                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sold</h5>
                                                            </div>

                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgone} className="w-100" />
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">

                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sold</h5>
                                                            </div>

                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgfour} className="w-100" />
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">

                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sold</h5>
                                                            </div>

                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgfour} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Resale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgthree} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Resale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgone} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Resale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgone} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            <i className="fa fa-heart"></i>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Buy Now</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgfour} className="w-100" />
                                                        <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                            <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                        </div>
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            <i className="fa fa-heart"></i>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <p className="text-black-50 mb-0"><small>Highest bid</small></p>
                                                                <h6 className="mb-0 fw-bolderr"><small>0.01 wETH</small></h6>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Place a Bid</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgtwo} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            <i className="fa fa-heart"></i>
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Buy Now</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">

                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgthree} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>0.01 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgtwo} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ps-0">
                                                <div className="live-box p-4">
                                                    <div className="live-img position-relative">
                                                        <img src={imgone} className="w-100" />
                                                        {/* <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                                                                <h6 className="mb-0 me-2 fw-bolderr">05:22:14:59</h6><span className="text-black-50">left</span>
                                                            </div> */}
                                                    </div>
                                                    <div className="live-text pt-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="fw-bolderr">NFT Heading</h2>
                                                            {/* <i className="fa fa-heart"></i> */}
                                                        </div>
                                                        <div className="owner-name d-flex justify-content-start align-items-center pt-2 mb-3">
                                                            <div className="name-img">
                                                                <img src={name} />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <h6 className="mb-0 fw-bolderr"><small>@owner_name</small></h6>
                                                                <p className="text-black-50 mb-0"><small>Art by creator_name</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                                            <div className="bid-left">
                                                                <h6 className="mb-0 fw-bolderr"><small>3,333 wETH</small></h6>
                                                                <p className="text-black-50 mb-0 text-start"><small>$1651501.5 USD</small></p>
                                                            </div>
                                                            <div className="bid-right">
                                                                <h5 className="fw-bolderr mb-0">Sale</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="nft-activity mt-lg-4">
                                        <div className="profile-name bg-light py-2 rounded-3 mb-2 mt-4">
                                            <div className=" ms-3 d-flex justify-content-start align-items-center">
                                                <div className="profile-image nft-nameimg bg-secondary me-3 rounded-3">
                                                    <img className="w-100" src={imgone} />
                                                    {/* <i className="fas fa-image p-4"></i> */}
                                                </div>
                                                <div>
                                                    <p className="mb-0 color-red"><strong>NFT Name</strong></p>
                                                    <p className="mb-0 text-muted">1 edition transferred
                                                        from <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong> to
                                                        <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong>
                                                    </p>
                                                    <p className="text-muted mb-0"><small>18 hours ago</small></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-name bg-light py-2 rounded-3 mb-2">
                                            <div className=" ms-3 d-flex justify-content-start align-items-center">
                                                <div className="profile-image nft-nameimg bg-secondary me-3 rounded-3">
                                                    <img className="w-100" src={imgone} />
                                                    {/* <i className="fas fa-image p-4"></i> */}
                                                </div>
                                                <div>
                                                    <p className="mb-0 color-red"><strong>NFT Name</strong></p>
                                                    <p className="mb-0 text-muted">1 edition transferred
                                                        from <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong> to
                                                        <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong>
                                                    </p>
                                                    <p className="text-muted mb-0"><small>18 hours ago</small></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-name bg-light py-2 rounded-3 mb-2">
                                            <div className=" ms-3 d-flex justify-content-start align-items-center">
                                                <div className="profile-image nft-nameimg bg-secondary me-3 rounded-3">
                                                    <img className="w-100" src={imgone} />
                                                    {/* <i className="fas fa-image p-4"></i> */}
                                                </div>
                                                <div>
                                                    <p className="mb-0 color-red"><strong>NFT Name</strong></p>
                                                    <p className="mb-0 text-muted">1 edition transferred
                                                        from <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong> to
                                                        <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong>
                                                    </p>
                                                    <p className="text-muted mb-0"><small>18 hours ago</small></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-name bg-light py-2 rounded-3 mb-2">
                                            <div className=" ms-3 d-flex justify-content-start align-items-center">
                                                <div className="profile-image nft-nameimg bg-secondary me-3 rounded-3">
                                                    <img className="w-100" src={imgone} />
                                                    {/* <i className="fas fa-image p-4"></i> */}
                                                </div>
                                                <div>
                                                    <p className="mb-0 color-red"><strong>NFT Name</strong></p>
                                                    <p className="mb-0 text-muted">1 edition transferred
                                                        from <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong> to
                                                        <strong className="text-dark"><span
                                                            className=" bg-dark rounded-circle px-2 mx-1">.</span>
                                                            0x7dfbee423...254f</strong>
                                                    </p>
                                                    <p className="text-muted mb-0"><small>18 hours ago</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>    </>
    )
}