
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDFp257cL543-TI-A9Qw1Arcvgbv-QPQ7s",
    authDomain: "acria-market-6ab41.firebaseapp.com",
    projectId: "acria-market-6ab41",
    storageBucket: "acria-market-6ab41.appspot.com",
    messagingSenderId: "274340810181",
    appId: "1:274340810181:web:32dd401ad9a1ad9d0dcfac",
    measurementId: "G-V3XKNKFBP0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
// if (!firebase.getApps.length) {
//     firebase.initializeApp(firebaseConfig)
// }

export const getFirebaseMessageToken = async () => {
    const messaging = getMessaging(app);
    if (isSupported()) {
        try {
            let tokenId = await getToken(messaging, { vapidKey: "BA1Wx1s5YEv6q7F8WyUp1_B0f3BUm_RZExSFn0ejs6Tad5k3MtDvHWfrLRYlB7l2jkzry-kf457tw18sg0d1xE0", })
            return { tokenId }
        } catch (error) {
            return { error }
        }
    } else {
        return { error: " Notification Not Supported" }
    }
}

export const onMessageListener = () => {
    const messaging = getMessaging(app);
    return new Promise((resolve, reject) => {
        if (messaging) {
            messaging.onMessage((payload) => {
                resolve(payload)
            })
        } else {
            reject(null)
        }
    })
}
