import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import authAction from "../context/actions/auth/authAction";
import { WALLET_ADDRESS_SLICE_FROM } from "../context/actionTypes";
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "./../assets/images/view_profile.svg";

export default ({ creator_address, currentUser, _id,  highest_bid_amount, created_by, current_owner, end_date, file_ext, file_url, signature, name, start_date, min_bid, is_favourite, index, exploreDispatch, authDispatch, col, price }) => {
    const location = useLocation()
    const [is_favourite_state, setis_favourite] = useState(is_favourite ? is_favourite : false)
    const [isExpire, setIsExpire] = useState(false);
    const addToFev = async (id) => {
        try {
            let items = {
                "nft_id": id,
                "language": "ENGLISH"
            }
            setis_favourite(true)
            let apiRes = await henceforthApi.NFT.addToFev(items)
        } catch (error) {
            if (error.response && error.response.body.error == "UNAUTHORIZED") {
                toast.warn("You must login first.")
            } else if (error.response && error.response.body.error == "ALREADY_MARKED_AS_FAVOURITE") {
                removeToFev(id)
            } else {
                setis_favourite(false)
            }
        }
    }
    const removeToFev = async (id) => {
        try {
            let items = {
                "nft_id": id,
                "language": "ENGLISH"
            }
            setis_favourite(false)
            if (location.pathname.includes("profile/favorite")) {
                authAction.authFav({ index })
            }
            let apiRes = await henceforthApi.NFT.removeFromFev(items)
        } catch (error) {
            if (error.response && error.response.body.error == "UNAUTHORIZED") {
                toast.warn("You must login first.")
            } else {
                setis_favourite(true)
            }
        }
    }

    var countdownTimer;
    const [timerCount, setTimerCount] = useState({
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
        title: ""
    })

    const initialiseInterval = (end_date, title) => {
        if (end_date) {
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var end = new Date(Number(end_date))
            function timer() {
                var now = new Date();
                var distance = end - now;
                if (distance < 0) {
                    clearInterval(countdownTimer);
                    setIsExpire(true);
                    return;
                }
                var days = Math.floor(distance / _day);
                var hours = Math.floor((distance % _day) / _hour);
                var minutes = Math.floor((distance % _hour) / _minute);
                var seconds = Math.floor((distance % _minute) / _second);
                setTimerCount({
                    ...timerCount,
                    days,
                    hours,
                    minutes,
                    seconds,
                    title
                })
            }
            countdownTimer = setInterval(() => timer(), 1000);
        }
    }

    useEffect(() => {
        if (moment(Number(start_date)).utc().valueOf() > moment().utc().valueOf()) {
            initialiseInterval(start_date, "Auction starts in")
        } else {
            initialiseInterval(end_date, "Auction ends in")
        }
    }, [])

    return <div className={col}>
        <div className="live-box p-4">
            <Link to={`/content/${_id}/bids/last_30_days`}>
                <div className="live-img position-relative">
                    {(String(file_ext).includes("video")) ? <video className="w-100" autoPlay muted loop>
                        <source src={henceforthApi.FILES.video(file_url)}></source>
                    </video> :
                        <img src={henceforthApi.FILES.imageMedium(file_url)} className="w-100 " />
                    }
                    {!(timerCount.days == "0" && timerCount.hours == "0" && timerCount.minutes == "0" && timerCount.seconds == "0") &&
                        <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark rounded-3">
                            <h6 className="mb-0 me-2 fw-bolderr">{String(timerCount.days).length == 1 ? `0${timerCount.days}` : timerCount.days}:{String(timerCount.hours).length == 1 ? `0${timerCount.hours}` : timerCount.hours}:{String(timerCount.minutes).length == 1 ? `0${timerCount.minutes}` : timerCount.minutes}:{String(timerCount.seconds).length == 1 ? `0${timerCount.seconds}` : timerCount.seconds}</h6><span className="text-black-50">left</span>
                        </div>}
                </div>
            </Link>
            <div className="live-text pt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="d-inline-block text-truncate" style={{ maxWidth: '150px' }} title={name}>{name}</h2>
                    {is_favourite_state ?
                        <i className="fa fa-heart text-danger" onClick={() => removeToFev(_id)}></i> :
                        <i className="fa fa-heart" onClick={() => addToFev(_id)}></i>}
                </div>
                <div className="owner-name d-flex pt-2">
                    <div className="name-img">
                        {current_owner ?
                            <img src={current_owner?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${current_owner?.profile_pic}` : profile_placeholder} /> :
                            <img src={created_by && created_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${created_by?.profile_pic}` : profile_placeholder} />}
                    </div>
                    <div className="name-text ms-2">
                        <h6 className="mb-0 fs-6 fw-bolderr" title={current_owner?.user_name ? current_owner?.user_name : ''}><Link to={`/profile/${current_owner?._id ? current_owner?._id : created_by?._id}/on_sale`}>@{current_owner?.display_name ? current_owner?.display_name : `${creator_address?.slice(
                            0,
                            WALLET_ADDRESS_SLICE_FROM
                        )}....${current_owner?.address?.slice(
                            current_owner?.address?.length -
                            WALLET_ADDRESS_SLICE_FROM,
                            current_owner?.address?.length
                        )}`} </Link></h6>
                        <p className="fs-6 text-black-50  mb-0" title={created_by?.user_name ? created_by?.user_name : `${creator_address?.slice(
                            0,
                            WALLET_ADDRESS_SLICE_FROM
                        )}....${creator_address?.slice(
                            creator_address?.length -
                            WALLET_ADDRESS_SLICE_FROM,
                            creator_address?.length
                        )}`}>Art by<Link className="text-black ms-1" to={`/profile/${created_by?._id}/on_sale`}> {created_by?.user_name ? created_by?.user_name : `${creator_address?.slice(
                            0,
                            WALLET_ADDRESS_SLICE_FROM
                        )}....${creator_address?.slice(
                            creator_address?.length -
                            WALLET_ADDRESS_SLICE_FROM,
                            creator_address?.length
                        )}`}</Link></p>
                    </div>
                </div>
                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                    <div className="bid-left">
                        <p className="text-black-50 mb-0 text-start">Highest bid</p>
                        <h6 className="mb-0 fw-bolderr">{highest_bid_amount ? highest_bid_amount : price} wETH</h6>
                    </div>
                    <div className="bid-right">
                        {(currentUser !== current_owner?._id) && signature &&
                            <h5 className="fs-6 fw-bolderr mb-0">
                                <Link to={`/content/${_id}/bids/last_30_days`}>Place a Bid</Link>
                            </h5>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}