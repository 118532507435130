import profile_placeholder from "./../assets/images/view_profile.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify"
import henceforthIPFS from "../utils/henceforthIPFS";
import { Spinner } from "../components/BootstrapCompo";
import { paybleAmount } from "../context/Provider";
import {
  WALLET_ADDRESS_SLICE_FROM
} from "../context/actionTypes";
export default () => {

  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/hot-collection/:tab/:contract_address/:token_id/:token_type");
  const {
    authState,
    bidOnTimedAuction,
    setLoading,
    usdPrice,
    feePercentGloble,
    loading,
  } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: 0,
    page: 0
  });
  henceforthApi.setToken(authState.access_token)
  const [bidsData, setBidsData] = useState({
    bids: [],
    total_count: 0,
    page: 0
  })
  const [showMore, setShowMore] = useState(false);
  const [bidAmount, setBidAmount] = useState("");
  const [bidOfferLoading, setBidOfferLoading] = useState(false)
  const initialiseCollDetails = async () => {
    try {
      setLoading(true)
      let apiRes = await henceforthApi.Collection.getOpenseaNftDetails(match.params.contract_address, match.params.token_id, match.params.token_type);
      let data = apiRes.data;
      setState(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };
  const onPlaceABid = async (bid_amount, title, description) => {
    if (authState.access_token) {
      if (state.current_owner && state.current_owner._id == authState._id) {
        return toast.warn("You can't bid on your own NFT.");
      }
      try {
        setBidOfferLoading(true)
        let contractRes = await bidOnTimedAuction(bid_amount, feePercentGloble)
        if (contractRes.commission) {
          let items = {
            token_id: match.params.token_id,
            contract_address: match.params.contract_address,
            bid_amount: Number(bid_amount),
            commission: Number(contractRes.commission),
            language: "ENGLISH"
          }
          await henceforthApi.NFT2.openSeabid(items)
          setBidOfferLoading(false)
        }
        setBidAmount("")
        setBidOfferLoading(false)
        closeModals()
        inialiseBids(0)
      } catch (error) {
        setBidOfferLoading(false)
        toast.error(error.response.body.error_description)
      }
    } else {
      navigate(`/?signin=true&redirect=${location.pathname}`);
    }
  };

  const closeModals = () => {
    let makeAnOfferModalHotCloseModal = document.getElementById("makeAnOfferModalHotCloseModal")
    if (makeAnOfferModalHotCloseModal) {
      makeAnOfferModalHotCloseModal.click()
    }
  }

  const inialiseBids = async (page) => {
    try {
      let apiRes = await henceforthApi.NFT2.getBidsOpensea(match.params.token_id, match.params.contract_address, page)
      let data = apiRes.data
      setBidsData({
        ...bidsData,
        ...data,
        page: page + 1
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    if (match.params.tab == "offer") {
      inialiseBids(0)
    }
    // else if (match.params.tab == "trading") {
    //   initialiseTradingHistory(0)
    //   }
  }, [match.params.tab])

  useEffect(() => {
    initialiseCollDetails();

  }, []);
  return <section className="bid-page mt-5 pb-0 mb-5 justify-content-center">
    <div className="container">
      {!loading ?
        <div className="row">
          <div className="col-lg-5 ">
            {state.metadata?.image?.includes(".mp4") ? <video className="w-100" controls autoPlay loop src={state.metadata?.image}></video> : <img className="w-100" src={state.metadata?.image?.startsWith("data:") ? state?.metadata?.image : state.metadata?.image ? henceforthIPFS.getWithIPFS(state.metadata?.image) : state.metadata?.image_url ? henceforthIPFS.getWithIPFS(state.metadata?.image_url) : profile_placeholder} />}
            <button disabled
              className="orange-btn btn w-100 mt-3 Create-btn pe-4 ps-4"
            >
              Not For Sale
            </button>
            <button
              className="orange-btn btn w-100 mt-2 Create-btn pe-4 ps-4"
              data-bs-toggle="modal"
              data-bs-target="#makeAnOfferModalHot">
              Make offer
            </button>
          </div>
          <div className="col-lg-7">
            <div className="mx-3 d-flex justify-content-between">
              <div>
                <h2 className="text-start my-3 mb-2 fs-3 ">{state.title ? state.title : parseInt(state?.id?.tokenId, 16)}</h2>
              </div>
            </div>
            <p className="fs-6 p-color mx-3">{showMore ? `${state.description}` : `${state.description?.substring(0, 250)}`}
              {state.description?.length > 250 ?
                <button className="btn-readmore" onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Read less" : "Read more"}
                </button>
                : ""}</p>
            <div className="three-img d-flex">
              {match.params.token_type == "ERC721" ? <div className="owner-name me-4 ms-4 d-flex align-items-center pt-2">
                <div className="name-text ms-2">
                  <h5 className="text-black-50 mb-2">owner</h5>
                  <h6 className="mb-0 ">
                    <div className="scrolling">
                      {Array.isArray(state.owners) &&
                        state.owners.map((res) => {
                          return <div key={res._id} className="d-flex"><p className="mx-1" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(res); toast.success("Copied") }}>{res?.length ? `${res?.slice(0, WALLET_ADDRESS_SLICE_FROM)}.....${res?.slice(-WALLET_ADDRESS_SLICE_FROM)}` : "No Owner found"}</p></div>
                        })}
                    </div>
                  </h6>
                </div>
              </div> : ""}
            </div>
            {/* table-start */}
            <div className="nav-tabss mt-4">
              <div className="on-salle pb-4 mb-lg-5 dsffffffffffffffffffff">
                <ul
                  className="nav nav-pills mb-4 border-bottom mt-lg-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "offer" ? "active" : ""} text-secondary  bg-transparent  ps-0`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => navigate(`/hot-collection/offer/${match.params.contract_address}/${match.params.token_id}/${match.params.token_type}`, { replace: true })}
                    >
                      Offer
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link text-secondary ${match.params.tab == "trading" ? "active" : ""}   bg-transparent nav-link text-secondary    bg-transparent`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="true"
                      onClick={() => navigate(`/hot-collection/trading/${match.params.contract_address}/${match.params.token_id}/${match.params.token_type}`, { replace: true })}
                    >
                      Trading History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "price" ? "active" : ""} text-secondary  bg-transparent`}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                      onClick={() => navigate(`/hot-collection/price/${match.params.contract_address}/${match.params.token_id}/${match.params.token_type}`, { replace: true })}
                    >
                      Price History
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade  ${match.params.tab == "offer" ? "show active" : ""}`}
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {bidsData.bids.length ? Array.isArray(bidsData.bids) &&
                      bidsData.bids.map((res) => {
                        return <div key={res._id} className="bid-owner w-lg-75 p-3 d-lg-flex justify-content-between mb-3 ">
                          <div className="owner-name d-flex align-items-center ">
                            <div className="name-img">
                            </div>
                            <div className="name-text ms-2">
                              <p className=" mb-0">
                                Bid place by
                                <Link
                                  to={`/profile/${res.bid_placed_by}/on_sale`}
                                >
                                  <strong className="color-red mx-2">
                                    {res.bid_placed_by}
                                  </strong>
                                </Link>
                              </p>
                              <p className=" mb-0">
                                {moment(Number(res.created_at)).format(
                                  "MMM DD, YYYY"
                                )}{" "}
                                at{" "}
                                {moment(Number(res.created_at)).format(
                                  "hh:mm:a"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="name-text ms-2">
                            <h6 className="fs-5 fw-bolderr text-black mb-0 text-end mt-2 mt-lg-0">
                              {res.bid_amount} wETH
                            </h6>
                            <p className="text-black-50 mb-lg-0 text-end mb-2">${Number(Number(res.bid_amount) * usdPrice).toFixed(4)}</p>
                          </div>
                        </div>
                      }) : <div className="bid-owner w-lg-75 p-3 d-flex justify-content-between mb-3">
                      <div className="owner-name d-flex align-items-center justify-content-center w-100">
                        <div className="name-text ms-2">
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5">No Bids found</p>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div
                    className={`tab-pane fade tab-pane fade ${match.params.tab == "trading" ? "show active" : ""}`}
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="bid-owner w-lg-75 p-3 d-flex justify-content-between mb-3">
                      <div className="owner-name d-flex align-items-center justify-content-center w-100">
                        <div className="name-text ms-2">
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5">No Trading history found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${match.params.tab == "price" ? "show active" : ""}`}
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="name-text ms-2">
                      <div className="imge-placeholder text-center">
                        <img className="w-100 no-data-found" src={placeholder_image} />
                        <p className="mt-2 ms-lg-5"> No Price history found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}
    </div>
    <div
      className="modal fade "
      id="makeAnOfferModalHot"
      data-bs-backdrop="static"
      tabIndex="-1"
      aria-labelledby="makeAnOfferModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <div className="modal-content">
                <div className="modal-header border-0 p-0 ">
                  <h5
                    className="modal-title w-100 text-center color-red fs-4 pb-2  pt-4 d-flex justify-content-center "
                    id="makeAnOfferModalLabel">
                    Make an offer
                  </h5>
                  <button
                    id="makeAnOfferModalHotCloseModal"
                    type="button"
                    className="btn shadow-none fs-6 position-absolute top-0 end-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => { setBidAmount(""); closeModals(); setBidOfferLoading(false) }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>
                <div className="modal-body pt-1 pb-0 p-4">
                  <p className="mb-2 text-center ">
                    You are about to bid for <strong>{state.metadata?.name}</strong>
                    from <strong>{state.owners
                      ? `${state.owners[0]?.slice(0, WALLET_ADDRESS_SLICE_FROM)}...${state.owners[0]?.slice(-WALLET_ADDRESS_SLICE_FROM)}`
                      : ""}</strong>
                  </p>
                  <div className="input-group mb-3 border-bid mt-3">
                    <input
                      type="text"
                      className="form-control shadow-none border-0 text-truncate "
                      placeholder="Make an offer"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setBidAmount(e.target.value)}
                      value={bidAmount}
                      disabled={bidOfferLoading}
                    />
                    <span
                      className="input-group-text border-0 bg-transparent"
                      id="basic-addon2">
                      <small className="text-muted">
                        wETH<i className="fal fa-angle-down"></i>
                      </small>
                    </span>
                  </div>
                  <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                    <div className="bid-left">
                      <p className=" mb-0">
                        <small>Your offer balance</small>
                      </p>
                      <p className=" mb-0">
                        <small>Service fee</small>
                      </p>
                    </div>
                    <div className="bid-right">
                      <p className=" mb-0 text-end text-truncate make-offer">
                        <small className="text-truncate">{bidAmount} wETH</small>
                      </p>
                      <p className=" mb-0">
                        <small className="text-break">{feePercentGloble}%</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                  <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                    <p className=" mb-0">
                      <small>Your balance</small>
                    </p>
                    <h6 className="mb-0 fw-bolderr text-truncate make-offer">
                      <small className="text-truncate">{paybleAmount(bidAmount, feePercentGloble).bidAmount} wETH</small>
                    </h6>
                  </div>
                  <button
                    type="button"
                    className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                    onClick={() => onPlaceABid(bidAmount, "Make Offer", "Offer in progress...")}
                    disabled={Number(bidAmount ? bidAmount : 0) <= 0 || bidOfferLoading}>
                    <small className="">
                      {bidOfferLoading ? <Spinner /> : "Make offer"}
                    </small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section >
};