import { EXPLORE_ADD, EXPLORE_FEV, EXPLORE_LIVE_AUCTION, EXPLORE_TOP_SELLERS } from "../../actionTypes"

const addAll = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_ADD,
        payload: payload
    })
}
const liveAuction = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_LIVE_AUCTION,
        payload: payload
    })
}

const topSellers = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_TOP_SELLERS,
        payload: payload
    })
}
const exploreAdd = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_ADD,
        payload: payload
    })
}
const exploreFav = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_FEV,
        payload: payload
    })
}

export default {
    addAll,
    liveAuction,
    topSellers,
    exploreAdd,
    exploreFav,
};
