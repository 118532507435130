import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import TopSellersCompo from "../components/TopSellersCompo";

export default () => {
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, loading, setLoading, scrollToTop } = useContext(GlobalContext)
    const [loadMore, setLoadMore] = useState(null)
    const [state, setState] = useState({
        data: [],
        total_count: 0,
        page: 0
    })
    henceforthApi.setToken(authState.access_token)
    const followFun = async (id, b, index) => {
        const data = state.data
        data[index]["loading"] = true
        setState({
            ...state,
            data
        })
        const items = {
            user_id: id,
            language: "ENGLISH"
        }
        try {
            if (b) {
                await henceforthApi.Follower.unfollow(items)
                data[index]["following"] = !b
                data[index]["followers"] = data[index]["followers"] - 1

            } else {
                await henceforthApi.Follower.follow(items)
                data[index]["following"] = !b
                data[index]["followers"] = data[index]["followers"] + 1
            }
            data[index]["loading"] = false
        } catch (error) {
            data[index]["loading"] = false
        }
        setState({
            ...state,
            data
        })
        initialiseAllData()
    }

    const initialiseAllData = async (page) => {
        setLoading(true)
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let q = query.toString()
            let apiRes = await henceforthApi.NFT.exploreTopSellers(q, page, 8, timezone)
            let data = apiRes.data
            if (page) {
                let oldData = state.data
                let newData = [...oldData, ...data.data]
                setState({
                    ...state,
                    data: newData,
                    page
                })
            } else {
                setState({
                    ...state,
                    ...data,
                    page
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        initialiseAllData(0)
    }, [])
    useEffect(() => {
        let page = state.page
        const pathname = location.pathname
        if (pathname !== "/") {
            if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
                if (state.data.length) {

                    if (!loading) {
                        if (state.total_count != state.data.length) {
                            initialiseAllData(Number(page) + 1)
                        } else {
                        }
                    }
                }
            }
        }
    }, [loadMore])

    useEffect(() => {
        window.addEventListener("scroll", setLoadMore)
        return () =>
            window.removeEventListener("scroll", setLoadMore)
    }, [])
    useEffect(() => {
        scrollToTop()
    }, [])

    return <section className="sliderss live-auctions position-relative pb-lg-0 pt-5 mt-5 mb-0">
        <div className="container">
            <div className="row">
                {Array.isArray(state.data) && state.data.map((res, index) => {
                    return <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3">
                        <TopSellersCompo key={res._id} index={index} {...res} followFun={followFun} />
                    </div>
                })}
            </div>
        </div>
    </section>
}