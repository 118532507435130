import profile_placeholder from "../assets/images/profile_pic_profile_page.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import { useLocation, useMatch } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useEffect, useState } from "react";
import LiveAuctionsCompo from "../components/LiveAuctionsCompo";
import LiveFixedCompo from "../components/LiveFixedCompo";
import Activity from "../pages/activity"
import { GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import Modals from "./Modals";
import CollectionFilters from "../components/CollectionFilters";
import { FIXED_PRICE_AUCTION_ID, UNLIMITED_AUCTION_AUCTION_ID, WALLET_ADDRESS_SLICE_FROM } from "./../context/actionTypes";

export default () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const {
    authState,
    setLoading,
    loading,
    usdPrice,
    scrollToTop,
    exploreDispatch,
  } = useContext(GlobalContext);
  const [loadMore, setLoadMore] = useState(null)
  const [state, setState] = useState({
    data: [],
    items_count: 0,
    page: 0
  });
  const [collectionNfts, setCollectionNfts] = useState({
    data: [],
  });
  const [collectionActivities, setCollectionActivities] = useState({
    data: [],
  });
  const [reportCollText, setCollReportText] = useState("");
  henceforthApi.setToken(authState.access_token);
  const match = useMatch("/collection/:id");
  const initialiseCollNfts = async (page) => {
    setLoading(true)
    try {
      let q = query.toString()
      let apiRes = await henceforthApi.NFT.exploreCollectionNfts(match.params.id, page, 9, q)
      let data = apiRes.data
      if (page) {
        let oldData = collectionNfts.data
        let newData = [...oldData, ...data.data]
        setCollectionNfts({
          ...collectionNfts,
          data: newData,
          page
        })
      } else {
        if(page == 0) {
          setCollectionNfts({
            ...collectionNfts,
            ...data,
            page
          })
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initialiseCollDetails = async () => {
    try {
      let apiRes = await henceforthApi.Collection.get2(match.params.id);
      let data = apiRes.data;
      setState(apiRes.data);
    } catch (error) { }
  };

  const initialiseActivitiesDetails = async () => {
    try {
      let apiRes = await henceforthApi.Collection.getCollectionActivity(match.params.id);
      let data = apiRes.data;
      setCollectionActivities(apiRes.data);
    } catch (error) {
    }
  };

  const submitCollectionReport = async (e) => {
    e.preventDefault()
    if (reportCollText) {
      let items = {
        report_reason: reportCollText,
        _id: match.params.id,
        language: "ENGLISH"
      }
      try {
        let apiRes = await henceforthApi.Collection.report(items)
        let closeCollectionModal = document.getElementById("closeCollectionModal")
        if (closeCollectionModal) {
          closeCollectionModal.click()
        }
        toast.success(apiRes?.message)
        setCollReportText("")
      } catch (error) {
        if (error.response && error.response && error.response.body && error.response.body.error_description) {
          toast.error(error.response.body.error_description)
        } else {
        }
      }
    }
  }

  useEffect(() => {
    let page = collectionNfts.page
    if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
      if (collectionNfts.data.length) {
        if (!loading) {
          if (collectionNfts.items_count != collectionNfts.data.length) {
            initialiseCollNfts(Number(page) + 1)
          } else {
          }
        }
      }
    }
  }, [loadMore])

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore)
    return () =>
      window.removeEventListener("scroll", setLoadMore)
  }, [])

  useEffect(() => {
    scrollToTop()
    initialiseActivitiesDetails();
    initialiseCollDetails();
  }, [match.params.id]);

  useEffect(() => {
    initialiseCollNfts(0);
  }, [query.get("sort_by")]);

  return <section className="activity-wrapper mt-lg-5 mt-4">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src={
                state.img_url
                  ? String(state.img_url).includes("https://") ? state.img_url : `${henceforthApi.API_FILE_ROOT_ORIGINAL}${state.img_url}`
                  : profile_placeholder
              }
              className="cover-img"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-4 position-relative mb-3">
          <div className="collection-profile p-4">
            <div className="profile-image position-relative m-auto">
              <img
                src={
                  state.img_url
                    ? String(state.img_url).includes("https://") ? state.img_url : `${henceforthApi.API_FILE_ROOT_SMALL}${state.img_url}`
                    : profile_placeholder
                }
              />
              <div className="position-absolute bottom-0 end-0">
                <label htmlFor="fil">
                  <span className="btn pe-0">
                  </span>
                  <input className="d-none" type="file" id="fil" />
                </label>
              </div>
            </div>
            <div className="text-center">
              <h4 className=" mt-4 mb-1 profile-name text-truncate">
                {state.name ? state.name : "Not available"}
              </h4>
              <h6 className="text-gray profile-id mb-0" onClick={() => { navigator.clipboard.writeText(state.address); toast.success("Copied") }}>{state.address?.length ? `${state.address.slice(0, WALLET_ADDRESS_SLICE_FROM)}....${state.address.slice(state.address?.length - WALLET_ADDRESS_SLICE_FROM, state.address?.length)}` : ''}</h6>
              <div className="left-icon profile-share-icon  bg-white d-flex justify-content-center  my-3">
                <button
                  className="btn border border-top border-bottom share-icon px-3 shadow-none br-rounded me-2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h text-muted fs-6"></i>
                </button>
                <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                  <li>
                    <button
                      className="dropdown-item fw-semi-bold text-center fs-14"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#ReportCollectionmodal"
                    >
                      Report
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 pe-lg-1">
                <div className="border item-box">
                  <p className="mb-0 text-gray fs-12">Items</p>
                  <p className="mb-0 text-dark fw-bold fs-18">
                    {state.items_count ? state.items_count : "0"}
                  </p>
                </div>
              </div>
              <div className="col-md-6 ps-lg-1">
                <div className="border item-box mt-3 mt-lg-0">
                  <p className="mb-0 text-gray fs-12">Total Volume</p>
                  <p className="mb-0 text-dark text-dark fw-bold fs-18">
                    {state.total_volume ? parseFloat(Number(state.total_volume).toFixed(6)) : "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 nav-tabss">
          <div className="on-salle pb-5 mb-lg-5 mt-4 pt-lg-2">
            <ul
              className="nav nav-pills mb-3 border-bottom"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item me-4" role="presentation">
                <button
                  className="nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0 active"
                  id="pills-item-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-item"
                  type="button"
                  role="tab"
                  aria-controls="pills-item"
                  aria-selected="true"
                >
                  Item
                </button>
              </li>
              <li className="nav-item me-4" role="presentation">
                <button
                  className="nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0"
                  id="pills-activity-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-activity"
                  type="button"
                  role="tab"
                  aria-controls="pills-activity"
                  aria-selected="false"
                >
                  Activity
                </button>
              </li>
              {/* <li className="nav-item me-4" role="presentation">
              <button
                className="nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0"
                id="pills-stats-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-stats"
                type="button"
                role="tab"
                aria-controls="pills-stats"
                aria-selected="false"
              >
                Stats
              </button>
            </li> */}
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade  show active `}
                id="pills-item"
                role="tabpanel"
                aria-labelledby="pills-item-tab"
              >
                <div className="container p-0">
                  <div className="d-flex justify-content-between mb-4 ">
                    <CollectionFilters />
                  </div>
                  <div className="row">
                    {collectionNfts.data.map((res, index) => {
                      return res.type == FIXED_PRICE_AUCTION_ID || res.type == UNLIMITED_AUCTION_AUCTION_ID ? (
                        <LiveFixedCompo
                          key={res._id}
                          {...res}
                          usdPrice={usdPrice}
                          currentUser={authState._id}
                          index={index}
                          exploreDispatch={exploreDispatch}
                          col="col-lg-4 col-md-6 mb-md-6 mb-sm-3 mb-4"
                        />
                      ) : (
                        <LiveAuctionsCompo
                          key={res._id}
                          {...res}
                          usdPrice={usdPrice}
                          currentUser={authState._id}
                          index={index}
                          exploreDispatch={exploreDispatch}
                          col="col-lg-4 col-md-6 mb-md-4 mb-sm-3 mb-4"
                        />
                      );
                    })}

                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade  show table-scroll-activity-collection activitys`}
                id="pills-activity"
                role="tabpanel"
                aria-labelledby="pills-activity-tab"
              >
                {collectionActivities?.data?.length ? collectionActivities.data.map((res) => {
                  return <Activity key={res._id}    {...res} />
                }) :
                  <div className="imge-placeholder text-center">
                    <img className="w-100 no-data-found" src={placeholder_image} />
                    <p className="mt-2 ms-lg-5">No Data Found</p>
                  </div>
                }
              </div>
              <div
                className={`tab-pane fade  show `}
                id="pills-stats"
                role="tabpanel"
                aria-labelledby="pills-stats-tab"
              >
                <div className="chartjs scrolling">

                </div>
                {/* <div className="container p-0">
                <div className="d-flex flex-wrap">
                  <div className="dropdown my-1">
                    <button
                      className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 select`}
                      type="button"
                      id="dropdownMenuCategory"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      7 Days
                    </button>
                  </div>
                  <div className="dropdown my-1">
                    <button
                      className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      30 Days
                    </button>
                  </div>
                  <div className="dropdown my-1">
                    <button
                      className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2"
                      type="button"
                      id="dropdownMenuType"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      90 Days
                    </button>
                  </div>
                  <div className="dropdown my-1">
                    <button
                      className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      1 Year
                    </button>
                  </div>
                  <div className="dropdown my-1">
                    <button
                      className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      All Time
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="stats-content mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <h4>Sales History</h4>
                          <div className="ms-3">
                            <button className="btn btn-primary px-3 text-capitalize">
                              Average
                            </button>
                            <button className="btn btn-outline-primary ms-2 px-3 text-capitalize">
                              Median
                            </button>
                          </div>
                        </div>
                        <button className="btn btn-primary px-4">
                          BUY NOW
                        </button>
                      </div>
                      <p className="fs-12">
                        <span className="text-gray me-1">
                          Current avg. price:
                        </span>
                        <span className="text-dark fw-semi-bold">
                          $757
                        </span>{" "}
                      </p>
                      <div className=""></div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modals.ReportCollection reportCollText={reportCollText} setCollReportText={setCollReportText} submitCollectionReport={submitCollectionReport} />
  </section>
};
