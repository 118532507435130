import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "./../assets/images/view_profile.svg";
import { Spinner } from "../components/BootstrapCompo";
import LiveFixedCompo from "../components/LiveFixedCompo";
import LiveAuctionsCompo from "../components/LiveAuctionsCompo";
import ExploreFilter from "../components/ExploreFilter";
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import Activity from "../pages/activity";
import { FIXED_PRICE_AUCTION_ID } from "../context/actionTypes";
import Modals from "./Modals";
import FollowUserListCompo from "../components/FollowUserListCompo";
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/profile/:id/:tab");
  var query = new URLSearchParams(location.search);
  const { authState, scrollToTop, logOutNow, ERROR_UNAUTHORIZED, usdPrice, loading, setLoading } =
    useContext(GlobalContext);
  const [loadMore, setLoadMore] = useState(null);
  henceforthApi.setToken(authState.access_token);
  const [reportUserText, setUserReportText] = useState("");
  const [followLoading, setFollowLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [state, setState] = useState({
    account_status: "",
    admin_verified: false,
    connection_id: null,
    created_at: "",
    device_type: null,
    email: "",
    email_verified: false,
    fcm_token: null,
    is_following: false,
    following: false,
    is_blocked: false,
    is_deleted: false,
    last_seen: null,
    name: "",
    profile_pic: null,
    followers: 0,
    followings: 0,
    unique_code: null,
    updated_at: "",
    user_name: "",
    user_status: 0,
    you_are_blocked: false,
    you_block_to: false,
  });

  const [followers, setFollowers] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [followings, setFollowings] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [onSaleItems, setOnSaleItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [soldItems, setSoldItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [ownItems, setOwnItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [createdItems, setCreatedItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [collectionItems, setCollectionItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [favoritedItems, setFavouriteItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [bidsItems, setBidsItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [activityItems, setActivityItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const initialiseFollow = async () => {
    let items = {
      user_id: match.params.id,
      language: "ENGLISH",
    };
    try {
      setFollowLoading(true);
      let apiRes;
      if (state.following) {
        apiRes = await henceforthApi.Follower.unfollow(items);
      } else {
        apiRes = await henceforthApi.Follower.follow(items);
      }
      setState({
        ...state,
        ...apiRes.data,
      });
      setFollowLoading(false);
    } catch (error) {
      setFollowLoading(false);
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        toast.error(error.response.body.error_description);
      }
    }
  };

  const initialiseProfile = async () => {
    try {
      let apiRes = await henceforthApi.Profile.getById(match.params.id);
      let data = apiRes.data;
      setState({ ...data });
      await inialiseTabData("on_sale", 0);
      await inialiseTabData("sold", 0);
      await inialiseTabData("owned", 0);
      await inialiseTabData("created", 0);
      await inialiseTabData("activity", 0);
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        if (error.response.body.error == ERROR_UNAUTHORIZED) {
          logOutNow();
          navigate(`/?action=login&redirect=${location.pathname}`, {
            replace: true,
          });
        } else {
          toast.error(error.response.body.error_description);
        }
      }
    }
  };
  const submitUserReport = async (e) => {
    e.preventDefault()
    if (reportUserText) {
      let items = {
        report_reason: reportUserText,
        _id: match.params.id,
        language: "ENGLISH"
      }

      try {
        let apiRes = await henceforthApi.Profile.report(items)
        let ReportUserModal = document.getElementById("closeReportUserModal")
        if (ReportUserModal) {
          ReportUserModal.click()
        }
        toast.success("Reported user successfully")
        setUserReportText("")
      } catch (error) {
        if (error.response && error.response && error.response.body && error.response.body.error_description) {
          toast.error(error.response.body.error_description)
        } else {
        }
      }
    }
  }
  useEffect(() => {
    if (authState._id == match.params.id) {
      navigate(`/profile/on_sale`, { replace: true });
    } else {
      scrollToTop();
      initialiseProfile();
    }
  }, [match.params.id]);

  const inialiseProfileTab = async (tab, page) => {
    let q = query.toString();
    try {
      let apiRes = await henceforthApi.Profile.getDetailsById(
        match.params.id,
        tab,
        9,
        q,
        page
      );
      return apiRes.data;
    } catch (error) {
      setLoading(false)
    }
  };
  const inialiseTabData = async (tab, page) => {
    setLoading(true);
    let { data, total_count } = await inialiseProfileTab(
      String(tab).toUpperCase(),
      page
    );
    let arrData = Array.isArray(data) ? data : [];
    switch (tab) {
      case "on_sale": {
        let oldData = page ? onSaleItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setOnSaleItems({
          ...onSaleItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "sold": {
        let oldData = page ? soldItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setSoldItems({
          ...soldItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "owned": {
        let oldData = page ? ownItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setOwnItems({
          ...ownItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "created": {
        let oldData = page ? createdItems.data : [];
        let newData = [...oldData, ...arrData]
        const uniqueData = Array.from(new Set(
          newData.map(a => a._id))).map(id => {
            return newData.find(a => a._id === id)
          })
        setCreatedItems({
          ...createdItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "collection": {
        let oldData = page ? collectionItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setCollectionItems({
          ...collectionItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "favourite": {
        let oldData = page ? favoritedItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setFavouriteItems({
          ...favoritedItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "bids": {
        let oldData = page ? bidsItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setBidsItems({
          ...bidsItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "activity": {
        let oldData = page ? activityItems.data : [];
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setActivityItems({
          ...activityItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      default:
        break;
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (match.params.tab) {
      inialiseTabData(match.params.tab, 0);
    }
  }, [
    match.params.tab,
    query.get("collection_id"),
    query.get("sale_type"),
    query.get("filter_by"),
    query.get("min_price"),
    query.get("max_price"),
  ]);

  const followUnFollow = async (index, user_id, type, is_following) => {
    const items = {
      user_id: user_id,
      language: "ENGLISH",
    };
    try {
      if (is_following) {
        await henceforthApi.Follower.follow(items);
      } else {
        await henceforthApi.Follower.unfollow(items);
      }

      if (type == "followings") {
        let temp = followings.data;
        temp[index]["is_following"] = is_following;
        setFollowings({
          ...followings,
          data: temp,
        });
      } else {
        let temp = followers.data;
        temp[index]["is_following"] = is_following;
        setFollowers({
          ...followers,
          data: temp,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        toast.error(error.response.body.error_description);
      } else {
        toast.error(JSON.stringify(error));
      }
    }
  };
  const initialiseFollowers = async () => {
    try {
      let apiRes = await henceforthApi.Follower.getFollowers(match.params.id);
      setFollowers(apiRes.data);
    } catch (error) { }
  };

  const initialiseFollowings = async () => {
    try {
      let apiRes = await henceforthApi.Follower.getFollowings(match.params.id);
      setFollowings(apiRes.data);
    } catch (error) { }
  };
  useEffect(() => {
    initialiseFollowings()
    initialiseFollowers()
  }, []);

  useEffect(() => {
    let total_count = 0;
    let data_length = 0;
    let page = 0;
    switch (match.params.tab) {
      case "onsale": {
        total_count = onSaleItems.total_count;
        data_length = onSaleItems.data.length;
        page = onSaleItems.page;
        break;
      }
      case "sold": {
        total_count = soldItems.total_count;
        data_length = soldItems.data.length;
        page = soldItems.page;
        break;
      }
      case "owned": {
        total_count = ownItems.total_count;
        data_length = ownItems.data.length;
        page = ownItems.page;
        break;
      }
      case "created": {
        total_count = createdItems.total_count;
        data_length = createdItems.data.length;
        page = createdItems.page;
        break;
      }

      case "activity": {
        total_count = activityItems.total_count;
        data_length = activityItems.data.length;
        page = activityItems.page;
        break;
      }
    }
    if (
      window.innerHeight + document.documentElement.scrollTop + 400 >=
      document.scrollingElement.scrollHeight
    ) {
      if (data_length) {
        if (!loading) {
          if (total_count != data_length) {
            inialiseTabData(match.params.tab, page);
          } else {
          }
        }
      }
    }
  }, [loadMore]);

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore);
    return () => window.removeEventListener("scroll", setLoadMore);
  }, []);


  return (
    <>
      <section className="profile-activity position-relative">
        <div className="container-fluid p-0">
          <div className="bg-imgae">
            <div className="bg-grey w-100  bg-light d-inline-block">
              <img
                className="w-100"
                src={
                  state.cover_img
                    ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.cover_img}`
                    : profile_placeholder
                }
              />
            </div>
          </div>
        </div>
      </section>
      <section className="activity-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 position-relative">
              <div className="profile-image top-part text-center d-flex justify-content-center align-items-center ">
                <div className="imgesss  bg-light text-center">
                  <div className="icon-img position-relative">
                    <img
                      className="border border-4 border-white"
                      src={
                        state.profile_pic
                          ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.profile_pic}`
                          : profile_placeholder
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-inline pt-5 text-center">
                <h4 className=" mt-3 mb-0">
                  {state.display_name}
                </h4>
                <p className="p-color">@{state.user_name}</p>
                <div className="left-icon bg-white d-flex justify-content-center vw-50 mt-lg-3 mb-lg-3 m-auto mb-3">
                  <button
                    className="btn border border-top border-bottom  px-3 shadow-none report-icon"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                  </button>
                  <ul
                    className="dropdown-menu p-2 share-dropdown dropdown-menu-end share"
                    aria-labelledby="dropdownMenu2"
                  >
                    <p className="fw-semi-bold text-center">Share Link</p>
                    <div>
                      <ul className="d-flex ps-0 share-drop">
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`https://www.twitter.com/share?url=https://${window.location.origin
                              }${`profile/${match.params.id}/created`}`}
                            target="_blank"
                          >
                            <span>
                              <img src={Twitter} />
                            </span>
                            <p>Twitter</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin
                              }${`profile/${match.params.id}/created`}`}
                            target="_blank"
                          >
                            <span>
                              <img src={Facebook} />
                            </span>
                            <p>Facebook</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`mailto:info@example.com?subject=V3nus NFTs&body=https://${window.location.origin
                              }${`profile/${match.params.id}/created`}:`}
                          >
                            <span>
                              <img src={Email} />
                            </span>
                            <p>Email</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href="#"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.origin}${`/profile/created`}`
                              );
                              toast.success("Copied to clipboard");
                            }}
                          >
                            <span>
                              <img src={Copy} />
                            </span>
                            <p>Copy</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                  <div>
                    <button
                      className="btn border border-top border-bottom report-icon px-3 shadow-none br-rounded me-2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-h text-muted fs-6"></i>
                    </button>
                    <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                      <li>
                        <button
                          className="dropdown-item fw-semi-bold text-center fs-14"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#ReportUserModal"
                        >
                          Report
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="folloers border d-flex justify-content-center align-items-center w-75 m-auto border-end-0 border-start-0">
                  <div className="five-nine me-4 lh-sm" data-bs-toggle="modal" data-bs-target={state.total_following ? "#followingModalOther" : ""}>
                    <p className="text-start mb-0 fs-6  pt-3 text-center">
                      {state.total_following}
                    </p>
                    <p className="fs-6 text-muted" >
                      <small>Following</small>
                    </p>
                  </div>
                  <div className="five-nine me-2 lh-sm me-2" data-bs-toggle="modal" data-bs-target={state.total_followers ? "#followersModalOther" : ""}>
                    <p className="text-start mb-0 fw-bold pt-3 fs-6 text-center" >
                      {state.total_followers}
                    </p>
                    <p className="text-muted fs-6"> <small>Followers</small></p>
                  </div>
                </div>
                <div className="modal fade" id="followingModalOther" tabindex="-1" aria-labelledby="exampleModalLabel22" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered w-lg-25 modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="text-end p-2 pb-0 closee--custom ">
                        <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-header justify-content-center pt-0 headingg-modall">
                        <h5 className="modal-title" id="exampleModalLabel22">Following </h5>
                      </div>
                      <div className="modal-body modall-heght">
                        <div className="horiontal">
                          <div className="border-bottom mb-3">
                            {Array.isArray(followings.data) && followings.data.map((res, index) => {
                              return <FollowUserListCompo index={index} key={res._id} {...res} myProfile={true} onClickAction={followUnFollow} type="followings" authState={authState} />
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="followersModalOther" tabindex="-1" aria-labelledby="exampleModalLabel22" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered w-lg-25 modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="text-end p-2 pb-0 closee--custom ">
                        <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-header justify-content-center pt-0 headingg-modall">
                        <h5 className="modal-title" id="exampleModalLabel22">Followers</h5>
                      </div>
                      <div className="modal-body modall-heght">
                        <div className='border-bottom'>
                          {Array.isArray(followers.data) && followers.data.map((res, index) => {
                            return <FollowUserListCompo index={index} key={res._id} {...res} myProfile={true} onClickAction={followUnFollow} type="followers" authState={authState} />
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="trant-btn w-75 btn btn-light Create-btn mb-4 mt-3 follow-btn"
                  onClick={() => initialiseFollow()}
                  disabled={followLoading}
                >
                  <span>
                    {followLoading || state.following == null ? (
                      <Spinner />
                    ) : state.following ? (
                      "Unfollow"
                    ) : (
                      "Follow"
                    )}
                  </span>
                </button>
                <div className="bio">
                  <p className="text-muted mb-0 mt-lg-3">Bio</p>
                  <p className="fs-6 p-3 pt-0">
                    <small className="bio-profile">
                      {showMore ? `${state.bio}` : `${state.bio?.substring(0, 100)}`}
                      {state.bio?.length > 100 ? <button className="btn-readmore" onClick={() => setShowMore(!showMore)}>{showMore ? "Read less" : "Read more"}</button> : ""}
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="on-salle pb-5 mb-lg-5">
                <ul
                  className="nav nav-pills mb-4 border-bottom mt-lg-4 "
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "on_sale" ? "active" : ""
                        } text-secondary fs-6 f bg-transparent  `}
                      id="pills-onsale-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-onsale"
                      type="button"
                      role="tab"
                      aria-controls="pills-onsale"
                      aria-selected="true"
                      onClick={() =>
                        navigate(`/profile/${match.params.id}/on_sale`, {
                          replace: true,
                        })
                      }
                    >
                      On Sale
                      <small className="light-color px-1 p-color ms-1">
                        {onSaleItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "sold" ? "active" : ""
                        } text-secondary fs-6  bg-transparent `}
                      id="pills-sold-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-sold"
                      type="button"
                      role="tab"
                      aria-controls="pills-sold"
                      aria-selected="false"
                      onClick={() =>
                        navigate(`/profile/${match.params.id}/sold`, {
                          replace: true,
                        })
                      }
                    >
                      Sold
                      <small className="light-color px-1 p-color ms-1">
                        {soldItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "owned" ? "active" : ""
                        } text-secondary fs-6 bg-transparent `}
                      id="pills-owned-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-owned"
                      type="button"
                      role="tab"
                      aria-controls="pills-owned"
                      aria-selected="false"
                      onClick={() =>
                        navigate(`/profile/${match.params.id}/owned`, {
                          replace: true,
                        })
                      }
                    >
                      Owned
                      <small className="light-color px-1 p-color ms-1">
                        {ownItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "created" ? "active" : ""
                        } text-secondary fs-6 bg-transparent `}
                      id="pills-created-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-created"
                      type="button"
                      role="tab"
                      aria-controls="pills-created-tab"
                      aria-selected="false"
                      onClick={() =>
                        navigate(`/profile/${match.params.id}/created`, {
                          replace: true,
                        })
                      }
                    >
                      Created{" "}
                      <small className="light-color p-color px-1">
                        {createdItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "activity" ? "active" : ""
                        } text-secondary fs-6 bg-transparent `}
                      id="pills-activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() =>
                        navigate(`/profile/${match.params.id}/activity`, {
                          replace: true,
                        })
                      }
                    >
                      Activity
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <ExploreFilter />
                  <div
                    className={`tab-pane fade  ${match.params.tab == "on_sale" ? "show active" : ""}`}
                    id="pills-onsale"
                    role="tabpanel"
                    aria-labelledby="pills-onsale-tab">
                    <div className="container p-0">
                      <div className="row">
                        {onSaleItems.data.length ? (
                          onSaleItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "sold" ? "show active" : ""
                      }`}
                    id="pills-sold"
                    role="tabpanel"
                    aria-labelledby="pills-sold-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {soldItems.data.length ? soldItems.data.map((res, index) => {
                          return res.auction_type === 1 || res.auction_type === 2 ? (
                            <LiveFixedCompo
                              key={res._id}
                              {...res}
                              usdPrice={usdPrice}
                              currentUser={authState._id}
                              index={index}
                              col="col-md-4 mb-3"
                            />
                          ) : (
                            <LiveAuctionsCompo
                              key={res._id}
                              {...res}
                              usdPrice={usdPrice}
                              currentUser={authState._id}
                              index={index}
                              col="col-md-4 mb-3"
                            />
                          );
                        }) :
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "owned" ? "show active" : ""}`}
                    id="pills-owned"
                    role="tabpanel"
                    aria-labelledby="pills-owned-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {ownItems.data.length ? ownItems.data.map((res, index) => {
                          return res.auction_type === 1 || res.auction_type === 2 ? (
                            <LiveFixedCompo
                              key={res._id}
                              {...res}
                              usdPrice={usdPrice}
                              currentUser={authState._id}
                              index={index}
                              col="col-md-4 mb-3"
                            />
                          ) : (
                            <LiveAuctionsCompo
                              key={res._id}
                              {...res}
                              usdPrice={usdPrice}
                              currentUser={authState._id}
                              index={index}
                              col="col-md-4 mb-3"
                            />
                          );
                        }) :
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "created" ? "show active" : ""
                      }`}
                    id="pills-created"
                    role="tabpanel"
                    aria-labelledby="pills-created-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {createdItems.data.length ? (
                          createdItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "activity" ? "show active" : ""
                      }`}
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="pills-activity-tab"
                  >
                    <div className="nft-activity mt-lg-4">
                      <div className="profile-name bg-light rounded-3 mb-2 mt-4">
                        {activityItems.data.length ? activityItems.data.map((res, index) => {
                          return <Activity
                            key={res._id}
                            {...res} />
                        }) :
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modals.ReportUserModal reportUserText={reportUserText} setUserReportText={setUserReportText} submitUserReport={submitUserReport} />
    </>
  );
};
