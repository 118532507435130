
import { useContext, useState } from "react";
import { Spinner } from "../BootstrapCompo";
import { GlobalContext } from "../../context/Provider";
import walletConnectImg from "../../../src/assets/images/walletconnect.png"
import henceforthApi from "../../utils/henceforthApi";
import { useLocation, useNavigate } from "react-router-dom";
import { REDIRECT_PROFILE, SOCIAL_TYPE_META_MASK, SOCIAL_TYPE_WALLET_CONNECT } from "../../context/actionTypes";
import { toast } from "react-toastify";
import authAction from "../../context/actions/authAction";
import { getFirebaseMessageToken } from "../../utils/firebase";

export default () => {
    let navigate = useNavigate();
    let location = useLocation();
    const searchQuery = new URLSearchParams(location.search)
    const { authDispatch, loginWithMetamaskConnect, loginWithWalletConnect, logOutNow } = useContext(GlobalContext)
    const [metamaskLoading, setMetamaskLoading] = useState(false)
    const [walletConnectLoading, setWalletLoading] = useState(false)
    const requestNotification = async (access_token) => {
        henceforthApi.setToken(access_token)
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
            return ""
        }
        else if (Notification.permission === "granted") {
            let { tokenId, error } = await getFirebaseMessageToken()
            if (tokenId) {
                await henceforthApi.Profile.fcmToken(tokenId)
            }
            if (error) {
                console.log("enableNotification error", error);
                return ""
            }
            return tokenId
        }
        else if (Notification.permission !== 'denied' || Notification.permission === "default") {
            Notification.requestPermission(async (permission) => {
                if (permission === "granted") {
                    let { tokenId, error } = await getFirebaseMessageToken()
                    if (error) {
                        console.log("enableNotification error", error);
                        return ""
                    }
                    if (tokenId) {
                        let apiRes = await henceforthApi.Profile.fcmToken(tokenId)
                        console.log("enableNotification tokenId", tokenId, apiRes);
                    }
                    return tokenId

                } else {
                    console.log("permission", permission);
                    return ""
                }
            });
        } else {
            return ""
        }
    }

    const loginNow = async ({ provider }, wallet_type) => {
        debugger

        const message = [
            "I have read and accept the terms and conditions (https://www.henceforthsolutions.com/) of this app.",
            "Please sign me in!",
        ].join("\n");
        const signer = provider.getSigner()
        try {
            let notificationToken = await requestNotification();

            const signatureEthers = await signer.signMessage(message)
            let items = {
                social_type: wallet_type,
                social_token: signatureEthers,
                device_type: "Web",
                language: "ENGLISH",
            };
            if (notificationToken) {
                items["fcm_token"] = notificationToken;
            }

            try {
                let apiRes = await henceforthApi.Auth.signInUserByMetamask(items)
                let data = apiRes.data
                let wallet_address = data.social_token
                let myWalletAddress = await signer.getAddress()
                if (String(wallet_address).toLowerCase() == String(myWalletAddress).toLowerCase()) {
                    authAction.login({ ...data, provider: wallet_type })(authDispatch);
                    closeMetamaskModalFun()
                    if (searchQuery.has("redirect")) {
                        navigate(`${searchQuery.get("redirect")}`, { replace: true });
                    } else {
                        navigate(`/profile/on_sale`, { replace: true });
                    }
                } else {
                    setMetamaskLoading(false)
                    setWalletLoading(false)
                    toast.warn("Something wrong with wallet.")
                }
            } catch (error) {
                debugger
                logOutNow("loginNow")
                setMetamaskLoading(false)
                setWalletLoading(false)
            }
        } catch (err) {
            setMetamaskLoading(false)
            setWalletLoading(false)
        }
    }

    const requestLoginWithWalletConnect = async () => {
        setWalletLoading(true)
        let walletRes = await loginWithWalletConnect()
        debugger
        if (walletRes) {
            await loginNow(walletRes, SOCIAL_TYPE_WALLET_CONNECT)
        }
        closeMetamaskModalFun()
        setWalletLoading(false)
    }
    const requestLoginWithMetamaskConnect = async () => {
        debugger
        setMetamaskLoading(true)
        let walletRes = await loginWithMetamaskConnect()
        if (walletRes) {
            await loginNow(walletRes, SOCIAL_TYPE_META_MASK)
        }
        closeMetamaskModalFun()
        setMetamaskLoading(false)
    }

    const closeMetamaskModalFun = () => {
        let closeLoginWithSocial = document.getElementById("closeLoginWithSocial");
        if (closeLoginWithSocial) {
            closeLoginWithSocial.click()
        }
    }

    return <div className="modal signin fade" id="LoginWithSocial" tabIndex="-1" aria-labelledby="LoginWithSocialLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" id="closeLoginWithSocial" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                        className="fa fa-times"></i></button>
                </div>
                <div className="modal-body text-center">
                    <h5 className="modal-title sigin-login">Sign In</h5>
                    <p className="">Sign In with metamask to create a new wallet.</p>
                </div>
                <div className="modal-footer text-center">
                    {!walletConnectLoading &&
                        <button type="button" className="btn banner-button primery-button" onClick={() => requestLoginWithMetamaskConnect()} disabled={metamaskLoading} >
                            <img src={'https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg'} width="18px" className="me-2" />{metamaskLoading ? <Spinner /> : 'SIGN IN WITH METAMASK'}
                        </button>}
                    {!metamaskLoading &&
                        <button type="button" className="btn banner-button primery-button" onClick={() => requestLoginWithWalletConnect()} disabled={walletConnectLoading} >
                            <img src={walletConnectImg} width="18px" className="me-2" />{walletConnectLoading ? <Spinner /> : 'SIGN IN WALLET CONNECT'}
                        </button>}
                </div>
            </div>
        </div>
    </div>
}