import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import henceforthIPFS from "./henceforthIPFS";
const superagent = superagentPromise(_superagent, global.Promise);

const IS_STAGING = (window.origin.includes('staging') || window.origin.includes('localhost'));

const LIVE_BUCKET = 'https://liveacriamarket.s3.eu-central-1.amazonaws.com/'
const STAGING_BUCKET = 'https://stagingacriamarket.s3.eu-central-1.amazonaws.com/'
const BUCKET_LINK = IS_STAGING ? STAGING_BUCKET : LIVE_BUCKET

const LIVE_API_ROOT = "https://acria.market:2053/"; //live
const STAGING_API_ROOT = "https://staging.acria.market:2083/"; //live
const API_ROOT = IS_STAGING ? STAGING_API_ROOT : LIVE_API_ROOT

const API_FILE_ROOT_MEDIUM =
  `${BUCKET_LINK}uploads/images/medium/`;
const API_FILE_ROOT_ORIGINAL =
  `${BUCKET_LINK}uploads/images/original/`;
const API_FILE_ROOT_SMALL =
  `${BUCKET_LINK}uploads/images/small/`;
const API_FILE_ROOT_AUDIO =
  `${BUCKET_LINK}uploads/audio/`;
const API_FILE_ROOT_VIDEO =
  `${BUCKET_LINK}uploads/video/`;
const API_FILE_ROOT_DOCUMENTS =
  `${BUCKET_LINK}uploads/documents/`;

const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    // req.set('authorization', `Bearer ${token}`);
    req.set("token", token);
  }
};

const requests = {
  del: (url) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
};

const Auth = {
  login: (info) => requests.post("User/login", info),
  signInUserByMetamask: (info) => requests.post("User/wallet_login", info),
  signUpAsUser: (info) => requests.post("User/signup_as_user", info),
  signUpAsCreater: (info) => requests.post("User/sigup_as_creater", info),
  resendOtp: (info) => requests.post("User/otp", info),
  forgotPassword: (info) => requests.put("User/forgot_password", info),
  checkOtp: (info) => requests.post("check-email-otp", info),
  emailVerification: (info) => requests.post("User/verify", info),
  ageVerification: (info) => requests.post("User/age_verification", info),
  resetPassword: (info) => requests.post("reset/password", info),
  changePassword: (info) => requests.put("Profile/change_password", info),
  forgotChangePassword: (info) => requests.post("User/set_new_password", info),
  profile: () => requests.get(`user/detail`),
};
const Dashboard = {
  get: () => requests.get(`admin/dashboard`),
};
const Activity = {
  create: (info) => requests.post("nft", info),
  facilityActivity: (id) =>
    requests.get(`facility/activity?room_type_id=${id}`),
  getDetails: (id) => requests.get(`nft/${id}`),
};
const Search = {
  pagination: (search, limit, pagination) =>
    requests.get(
      `Nft/search?search=${search}&limit=100&pagination=0&language=ENGLISH`
    ),
  reels: (user_id, amount) =>
    requests.get(
      `reels/getReels?user_id=${user_id}&amount=${amount ? amount : 10}`
    ),
  getById: (id) => requests.get(`profile?id=${id}`),
};
const Social = {
  addEdit: (info) => requests.post(`Profile/add_edit_social_media`, info),
};
const Profile = {
  getFaq: (info) => requests.get("Admin/faqs?language=ENGLISH", info),
  report: (info) => requests.post("NftV2/report", info),
  edit: (info) => requests.put("User/profile", info),
  reportBlock: (info) => requests.put("Profile/other_user/report_block", info),
  deactivateAccount: (info) => requests.put("Profile/deactivate_account", info),
  get: () => requests.get(`User/profile?limit=10&language=ENGLISH`),
  getById: (id) => requests.get(`User/profile/${id}?limit=10&language=ENGLISH`),
  getByTab: (q, id, type, limit) =>
    requests.get(
      `User/profile/details?user_id=${id}&${q ? q : ""}&type=${type}&language=ENGLISH&limit=${limit ? limit : 8}`
    ),

  // getDetailsById: (user_id, type, limit, q) =>
  //   requests.get(`User/profile/details?user_id=${user_id}&type=${String(type).toLocaleUpperCase()}&language=ENGLISH&limit=${limit}${q ? `&${q}` : ''}`),
  getDetailsById: (user_id, type, limit, q, page) =>
    requests.get(`User/profile/details?user_id=${user_id}&type=${String(type).toLocaleUpperCase()}&pagination=${page ? page : 0}&language=ENGLISH&limit=${limit}${q ? `&${q}` : ''}`),
  getPublicPhotos: (page) =>
    requests.get(
      `Profile/list_photos?language=ENGLISH&type=PUBLIC&pagination=${page ? page : 0
      }&limit=12`
    ),
  getPrivatePhotos: (page) =>
    requests.get(
      `Profile/list_photos?language=ENGLISH&type=PRIVATE&pagination=${page ? page : 0
      }&limit=12`
    ),
  addPhotos: (info) => requests.post("Profile/add_photos", info),
  movePhotosToPublic: (_id) =>
    requests.put("Profile/move_photos", {
      _id,
      type: "PUBLIC",
      language: "ENGLISH",
    }),
  movePhotosToPrivate: (_id) =>
    requests.put("Profile/move_photos", {
      _id,
      type: "PRIVATE",
      language: "ENGLISH",
    }),
  connetWallet: (info) => requests.post("User/wallet/connect", info),
  setPrimaryWallet: (info) => requests.put("User/wallet/set_primary", info),
  fcmToken: fcm_token =>
    requests.put('User/fcm', {
      device_type: "Web",
      fcm_token,
      language: "ENGLISH"
    }),
  getWallet: () => requests.get(`User/wallet/listing?language=ENGLISH`),
  deleteWallet: (_id) => requests.del(`User/wallet/${_id}`),

};

const Collection = {
  create: (items) => requests.post(`Collection`, items),
  get: (_id, pagination, limit) =>
    requests.get(
      `Collection?limit=${limit ? limit : 10}${_id ? `${`&_id=${_id}`}` : ""
      }&pagination=${pagination ? pagination : 0}&language=ENGLISH`
    ),
  get2: (_id) => requests.get(`Collection/${_id}?language=ENGLISH`),
  getTrendingCollDetails: (_id) => requests.get(`Collection/trending/${_id}?language=ENGLISH`),

  getOpenseaNftDetails: (contract_address, token_id, token_type) => requests.get(`Collection/nft/details?contract_address=${contract_address}&token_id=${token_id}&token_type=${token_type}&language=ENGLISH`),


  getHome: (pagination, limit) =>
    // requests.get(`Collection/hot?limit=${limit ? limit : 8}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
    requests.get(`Collection/hot?language=ENGLISH`),
  getTrendingCollection: (pagination, limit) =>
    requests.get(`Collection/trending?limit=${limit ? limit : 8}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
  getHotCollection: (pagination, limit) =>
    requests.get(`Collection/top?limit=${limit ? limit : 8}&pagination=${pagination ? pagination : 0}`),
  getHotCollectionDetails: (_id) =>
    requests.get(`Collection/top?_id=${_id}&language=ENGLISH`),
  getAllCollection: (q, pagination, limit) => requests.get(`Collection/hot?language=ENGLISH&${q ? q : ""}&pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}`),


  getList: (search, pagination, limit) =>
    requests.get(
      `Collection?limit=${limit ? limit : 10}${search ? `${`&search=${search}`}` : ""
      }&pagination=${pagination ? pagination : 0}&language=ENGLISH`
    ),
  getCollectionActivity: (id, pagination, limit) =>
    requests.get(
      `Collection/activities/${id}?limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`
    ),
  report: (info) => requests.post("Collection/report", info),
  reqCollection: (info) => requests.post("Collection/request", info),
  importCollectionFromRirible: (collection_address, wallet_address) =>
    superagent.get(`https://rinkeby.rarible.com/marketplace/api/v4/collections/${collection_address}/owned/${wallet_address}`),

  importCollection: (collection_address, wallet_address) =>
    superagent.get(`http://139.59.47.49:4004/api/transactions/erc721?wallet_address=${wallet_address}&contract_address=${collection_address}`),
};

const NFT2 = {

  getTradingHistoryById: (_id, limit, pagination) =>
    requests.get(`NftV2/${_id}/trading_history?limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
  getTradingPriceById: (_id, graph_type) =>
    requests.get(`NftV2/${_id}/graph?graph_type=${String(graph_type).toUpperCase()}&language=ENGLISH`),
  create: (info) => requests.post("NftV2", info),
  buy: (info) => requests.post("NftV2/buy", info),
  bid: (info) => requests.post("NftV2/bid", info),
  openSeabid: (info) => requests.post("NftV2/bid/opensea", info),
  acceptBidOffer: (info) => requests.put("NftV2/bid", info),
  removeFromSale: _id => requests.put(`NftV2/${_id}`, {}),
  putOnSale: (info) => requests.post("NftV2/put_on_sale", info),
  getById: (_id, timezone) =>
    requests.get(
      `NftV2/${_id}?&timezone=${timezone}&language=ENGLISH`
    ),
  getBids: (_id, pagination, limit) =>
    requests.get(`NftV2/${_id}/bids?limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
  getBidsOpensea: (token_id, contract_address, pagination, limit) =>
    requests.get(`NftV2/bid/opensea?token_id=${token_id}&contract_address=${contract_address}&limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
}
const NFT = {
  get: () => requests.get(`Profile/my_profile?language=ENGLISH&limit=10`),
  categories: () => requests.get(`Nft/categories?language=ENGLISH`),
  deactivateAccount: (info) => requests.put("User/content/edit", info),
  create: (info) => requests.post("Nft", info),
  reCreate: (info) => requests.post("Nft/sell", info),
  addToFev: (info) => requests.post("Nft/favourite", info),
  auctionReCreate: (info) => requests.post("Nft/aution/sell", info),
  buy: (info) => requests.post("Nft/buy", info),
  finaliseUnlimitedAuction: (info) => requests.put("Nft/aution/buy", info),
  removeFromFev: (info) => requests.put("Nft/favourite", info),
  report: (info) => requests.post("NftV2/report", info),
  putOnSale: (_id) => requests.put(`Nft/put_on_sale/${_id}`, {}),
  removeFromSale: (_id) => requests.put(`Nft/remove_from_sale/${_id}`, { _id }),
  placeBid: (info) => requests.post("Nft/aution/bid", info),
  lock: (info) => requests.post("User/lock", info),
  unlock: (_id, info) => requests.post(`User/unlock/${_id}`, info),
  exploreHome: (
    type,
    search,
    category_id,
    sale_type,
    min_price,
    max_price,
    pagination
  ) =>
    requests.get(
      `NftV2/explore?language=ENGLISH${search ? `&search=${encode(search)}` : ""
      }${min_price ? `&min_price=${encode(min_price)}` : ""}${max_price ? `&max_price=${encode(max_price)}` : ""
      }${category_id ? `&category_id=${encode(category_id)}` : ""}&pagination=${pagination ? pagination : 0
      }&limit=8`
    ),
  explore: (
    q, pagination, limit, timezone
  ) =>
    requests.get(
      `NftV2/explore?language=ENGLISH&${q ? q : ""}&pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 8}`
    ),

  // explore: (
  //   pagination, limit, timezone
  //  ) =>
  //    requests.get(
  //      `Nft/explore?language=ENGLISH&pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&timezone=${timezone}`
  //    ),
  exploreLiveAuction: (search, pagination) =>
    requests.get(
      `Nft/live_auctions?language=ENGLISH${search ? `&search=${encode(search)}` : ""
      }&pagination=${pagination ? pagination : 0}&limit=8`
    ),
  exploreTopSellers: (search, pagination) =>
    requests.get(
      `Nft/top_sellers?language=ENGLISH${search ? `&search=${encode(search)}` : ""
      }&pagination=${pagination ? pagination : 0}&limit=8`
    ),
  exploreTrending: (pagination) =>
    requests.get(
      `Nft/trending?language=ENGLISH&pagination=${pagination ? pagination : 0}&limit=8`
    ),
  getBy_Id: (_id, graph_type, timezone) =>
    requests.get(
      `Nft/${_id}?graph_type=${String(
        graph_type
      ).toUpperCase()}&timezone=${timezone}&language=ENGLISH`
    ),
  exploreCollectionNfts: (collection_id, pagination, limit, q) =>
    requests.get(
      `Collection/items?language=ENGLISH&collection_id=${collection_id}&pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&${q ? q : ""}`
    ),

  trendingCollNfts: (_id, startToken, limit,


  ) =>
    requests.get(
      `Collection/trending/nfts/${_id}?startToken=${startToken ? startToken : 0}&limit=${limit ? limit : 10}&language=ENGLISH`
    ),
  hotcollectionnfts: (_id, startToken, limit,


  ) =>
    requests.get(
      `Collection/top/nfts/${_id}?startToken=${startToken ? startToken : 0}&limit=${limit ? limit : 10}&language=ENGLISH`
    ),
};
const Creator = {
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  pagination: (pagination, search) =>
    requests.get(
      `User/creator/list_all?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${pagination ? pagination : 0}&limit=10`
    ),
};
const Common = {
  contentUs: (items) => requests.post(`User/contact_us`, items),
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  TERMS_AND_CONDITIONS: (_id) =>
    requests.get(
      `User/policies/listing?language=ENGLISH&type=TERMS_AND_CONDITIONS`
    ),
  ethusd: () =>
    superagent.get(
      `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD`
    ),
  ethtoweth: () =>
    superagent.get(
      `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,WETH`
    ),
  getPublicPhotos: (user_id, page) =>
    requests.get(
      `Profile/other_user/public_photos?language=ENGLISH&type=PUBLIC&pagination=${page ? page : 0
      }&limit=12&user_id=${user_id}`
    ),
  getPrivatePhotos: (user_id, page) =>
    requests.get(
      `Profile/other_user/photos?language=ENGLISH&type=PRIVATE&pagination=${page ? page : 0
      }&limit=12&user_id=${user_id}`
    ),

  aboutUs: () =>
    requests.get(
      `User/policies?type=ABOUT_US&language=ENGLISH`
    ),
  termsAndConditions: () =>
    requests.get(
      `User/policies?type=TERMS_AND_CONDITIONS&language=ENGLISH`
    ),
  privacyPolicy: () =>
    requests.get(
      `User/policies?type=PRIVACY_POLICY&language=ENGLISH`
    ),
  faq: () =>
    requests.get(
      `User/faqs?language=ENGLISH`
    ),
};
const Tips = {
  searchUserPagination: (search, pagination) =>
    requests.get(
      `Profile/tips/search_users?language=ENGLISH&search=${encode(
        search
      )}&pagination=${pagination}&limit=9`
    ),
  searchCreatorPagination: (search, pagination) =>
    requests.get(
      `Profile/tips/search_creators?language=ENGLISH&search=${search}&pagination=${pagination}&limit=9`
    ),
  getTipRequest: (pagination) =>
    requests.get(
      `Profile/tips/received_requests?language=ENGLISH&pagination=${pagination}&limit=9`
    ),
  getTipRequestCreatorSide: (pagination) =>
    requests.get(
      `Profile/tips/sent_requests?language=ENGLISH&pagination=${pagination}&limit=9`
    ),
  getTipHistory: (pagination) =>
    requests.get(
      `Profile/tips/history?language=ENGLISH&pagination=${pagination}&limit=9`
    ),
  createTipRequest: (info) => requests.post("Profile/tips/send_request", info),
  createDirectTip: (info) => requests.post("Profile/tips/send", info),
  acceptTipRequest: (info) => requests.put("Profile/tips/manage_request", info),
  cancelTipRequest: (_id) =>
    requests.put("Profile/tips/manage_request", {
      _id,
      status: "CANCEL",
      language: "ENGLISH",
    }),
  rejectTipRequest: (_id) =>
    requests.put("Profile/tips/manage_request", {
      _id,
      status: "REJECT",
      language: "ENGLISH",
    }),
};

const Chat = {
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  users: (pagination) =>
    requests.get(
      `Chat/list_users?language=ENGLISH&pagination=${pagination}&limit=10`
    ),
  chats: (pagination, search) =>
    requests.get(
      `Chat/list_chat_users?language=ENGLISH&pagination=${pagination}&limit=10${search ? `&search=${search}` : ""
      }`
    ),
  chatHistory: (_id, pagination) =>
    requests.get(`Chat/history?_id=${_id}&pagination=${pagination}&limit=10`),
};
const Notification = {
  get: (pagination) =>
    requests.get(
      `User/notifications?language=ENGLISH`
    ),
  markRead: () => requests.put(`User/notifications`, {}),
};
const Follower = {
  get: (pagination) =>
    requests.get(
      `User/notifications?language=ENGLISH&pagination=${pagination}&limit=10`
    ),
  follow: (items) => requests.post(`User/follow`, items),
  unfollow: (items) => requests.put(`User/follow`, items),
  getFollowers: (user_id, pagination, limit) =>
    requests.get(`User/followers?user_id=${user_id}&limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
  getFollowings: (user_id, pagination, limit) =>
    requests.get(`User/followings?user_id=${user_id}&limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),

};
const MarketPlace = {
  getfee: () => requests.get("User/fee_percent?language=ENGLISH"),
};
const Ipfs = {
  metadata: (metadata) =>
    requests.post(`upload/ipfs/metadata`, { metadata }),
}
const FILES = {
  audio: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_AUDIO}${filename}` : henceforthIPFS.ipfsGetFileUrl(filename),
  video: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_VIDEO}${filename}` : henceforthIPFS.ipfsGetFileUrl(filename),
  imageOriginal: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_ORIGINAL}${filename}` : henceforthIPFS.ipfsGetFileUrl(filename),
  imageMedium: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_MEDIUM}${filename}` : henceforthIPFS.ipfsGetFileUrl(filename),
  imageSmall: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_SMALL}${filename}` : henceforthIPFS.ipfsGetFileUrl(filename),
}

export default {
  MarketPlace,
  token,
  Common,
  Chat,
  Follower,
  Notification,
  Tips,
  Ipfs,
  Auth,
  Profile,
  Creator,
  NFT,
  NFT2,
  Collection,
  Search,
  Social,
  Dashboard,
  Activity,
  API_ROOT,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  API_FILE_ROOT_DOCUMENTS,
  IS_STAGING,
  encode,
  FILES,
  setToken: (_token) => {
    token = _token;
  },
};
