import placeholder_image from "../assets/images/no-data-found.svg";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LiveAuctionsCompo from "../components/LiveAuctionsCompo";
import LiveFixedCompo from "../components/LiveFixedCompo";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import ExploreFilter from "../components/ExploreFilter";
import { toast } from "react-toastify";
import { FIXED_PRICE_AUCTION_ID, UNLIMITED_AUCTION_AUCTION_ID } from "../context/actionTypes";
export default () => {
    const location = useLocation()
    const { authState, exploreDispatch, usdPrice, scrollToTop, loading, setLoading } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)
    const query = new URLSearchParams(location.search)
    const [loadMore, setLoadMore] = useState(null)
    const [state, setState] = useState({
        data: [],
        total_count: 0,
        page: 0
    })

    const initialiseAllData = async (page) => {
        setLoading(true)
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let q = query.toString()
            let apiRes = await henceforthApi.NFT.explore(q, page, 8, timezone)
            let data = apiRes.data
            if (page) {
                let oldData = state.data
                let newData = [...oldData, ...data.data]
                setState({
                    ...state,
                    data: newData,
                    page
                })
            } else {
                setState({
                    ...state,
                    ...data,
                    page
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        initialiseAllData(0)
    }, [query.get("collection_id"), query.get("sale_type"), query.get("filter_by"), query.get("min_price"), query.get("max_price")])
    useEffect(() => {
        let page = state.page
        const pathname = location.pathname
        if (pathname !== "/") {
            if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
                if (state.data.length) {

                    if (!loading) {
                        if (state.total_count != state.data.length) {
                            initialiseAllData(Number(page) + 1)
                        } else {
                        }
                    }
                }

            }
        }
    }, [loadMore])

    useEffect(() => {
        window.addEventListener("scroll", setLoadMore)
        return () =>
            window.removeEventListener("scroll", setLoadMore)
    }, [])
    useEffect(() => {
        scrollToTop()
    }, [])

    return <section className="live-auctions  pb-5 mt-5 mb-lg-5">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-3">
                    <ExploreFilter name="Explore" />
                    <hr />
                </div>
                {state.data.length ? state.data.map((res, index) => {
                    return (res.type == FIXED_PRICE_AUCTION_ID || res.type == UNLIMITED_AUCTION_AUCTION_ID) ?
                        <LiveFixedCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" /> :
                        <LiveAuctionsCompo key={res._id} {...res} create_time_price={res.create_time_price} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" />
                }) : <div className="imge-placeholder text-center">
                    <img className="w-100 no-data-found" src={placeholder_image} />
                    <p className="mt-2 ms-lg-5">No Trading history found</p>
                </div>}
            </div>
        </div>
    </section>
}