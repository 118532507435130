import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import profile_placeholder from "../assets/images/profile_pic_profile_page.svg";

export default () => {
  const { loading, setLoading, scrollToTop, authState } = useContext(GlobalContext)
  const [state, setState] = useState({
    data: [],
    total_count: 0,
    page: 0
  })
  henceforthApi.setToken(authState.access_token)
  const [loadMore, setLoadMore] = useState(null)
  const initialiseCollection = async (page) => {
    setLoading(true)
    try {
      let apiRes = await henceforthApi.Collection.getHotCollection(page, 20)
      let data = apiRes.data
      if (page) {
        let oldData = state.data
        let newData = [...oldData, ...data.data]
        setState({
          ...state,
          data: newData,
          page
        })
      } else {
        setState({
          ...state,
          ...data,
          page
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    let page = state.page
    if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
      if (state.data.length) {
        if (!loading) {
          if (state.total_count != state.data.length) {
            initialiseCollection(Number(page) + 1)
          } else {
          }
        }
      }
    }
  }, [loadMore])

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore)
    return () =>
      window.removeEventListener("scroll", setLoadMore)
  }, [])

  useEffect(() => {
    initialiseCollection(0)
    scrollToTop()
  }, [])

  return <div className="container">
    <div className="row">
      <h2 className=" mt-4">Hot Collections</h2>
      {state.collections?.map((res) => {
        return <div key={res._id} className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3 my-3">
          <Link className="btnn-img" to={`/hot-collection/${res._id}`}>
            <div className="live-box p-4">
              <div className="live-img position-relative">
                <img src={res.cover_img ? res.cover_img : profile_placeholder} />
              </div>
              <div className="hot-collection live-text">
                <div className="text-center justify-content-center align-items-center im-whiteing">
                  <img src={res.image_url ? res.image_url : profile_placeholder} />
                  <h2 className="fw-bolderr mt-3 fs-5 minum-heit pt-4 px-4 text-truncate">{res.name ? res.name : "Not available"}</h2>
                </div>
              </div>
            </div>
          </Link>
        </div>
      })}
    </div>
  </div>
};
