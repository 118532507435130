import { ToastContainer } from "react-toastify";
import Footer from "./Footer";
import { useContext } from "react";
import Header from "./Header";
import { GlobalContext } from "../../context/Provider";
import { GlobalLoading } from "../BootstrapCompo";

export default ({ children }) => {
  const { loading } = useContext(GlobalContext)
  return <div className="layout">
    <Header />
    <main>{children}</main>
    <Footer />
    {loading ? <GlobalLoading /> : ''}

    <ToastContainer
      autoClose={2000} />
  </div>
}