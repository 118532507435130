import { Routes, Route, } from "react-router-dom";
import Home from "./pages/Home";
import "./assets/style/main.scss";
import Layout from "./components/layout/Layout";
import Explore from "./pages/Explore";
import EditProfile from "./pages/EditProfile";
import CreateContent from "./pages/CreateContent";
import ProfileActivity from "./pages/ProfileActivity";
import MyWallet from "./pages/MyWallet";
import CreaterProfile from "./pages/CreaterProfile";
import ContentDetails from "./pages/ContentDetails";
import OtherProfileActivity from "./pages/OtherProfileActivity";
import Collections from "./pages/collections";
import Activity from "./pages/activity";
import CollectionDetails from "./pages/collectionDetails"
import LiveAuctionsCompo from "./components/LiveAuctionsCompo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import TermsAndCondition from "./pages/TermsAndCondition";
import TopSellers from "./pages/TopSellers";
import TrendingCollections from "./pages/TrendingCollections";
import TrendingCollDetails from "./pages/TrendingCollDetails";
import HotCollections from "./pages/HotCollections";
import HotCollDetails from "./pages/HotCollDetails";
import OpenSeaNftDetails from "./pages/OpenSeaNftDetails";
import HotOpenseaNftDetails from "./pages/HotOpenseaNftDetails";

function App() {
  return <Layout>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="content/create" element={<CreateContent />} />
      <Route path="content/:id/:tab/:graph_type" element={<ContentDetails />} />
      <Route path="collection/:tab/:contract_address/:token_id/:token_type" element={<OpenSeaNftDetails />} />
      <Route path="hot-collection/:tab/:contract_address/:token_id/:token_type" element={<HotOpenseaNftDetails />} />
      <Route path="explore" element={<Explore />} />
      <Route path="liveauction" element={<LiveAuctionsCompo />} />

      <Route path="collections" element={<Collections />} />
      <Route path="trending-collections" element={<TrendingCollections />} />
      <Route path="hot-collections" element={<HotCollections />} />
      <Route path="activity" element={<Activity />} />
      <Route path="collection/:id" element={<CollectionDetails />} />
      <Route path="trending-collection/:id" element={<TrendingCollDetails />} />
      <Route path="hot-collection/:id" element={<HotCollDetails />} />
      <Route path="profile/edit" element={<EditProfile />} />
      <Route path="profile/:tab" element={<ProfileActivity />} />
      <Route path="profile/:id/:tab" element={<OtherProfileActivity />} />
      <Route path="wallet" element={<MyWallet />} />
      <Route path="createrProfile" element={<CreaterProfile />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="terms" element={<TermsAndCondition />} />
      <Route path="topsellers" element={<TopSellers />} />
    </Routes>
  </Layout>
}

export default App;
