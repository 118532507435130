import { Spinner } from "../components/BootstrapCompo";
import { NFT_STEP_1, NFT_STEP_2, NFT_STEP_3 } from "../context/actionTypes";
import { toast } from "react-toastify";
import henceforthApi from "../utils/henceforthApi";
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";

const VerifyEmail = ({ initialiseProfile, resendOtp, timerCount }) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const verify = async () => {
    try {
      let items = {
        otp: Number(otp),
        language: "ENGLISH"
      }
      setLoading(true)
      let apiRes = await henceforthApi.Auth.emailVerification(items)
      initialiseProfile(apiRes.data)
      setLoading(false)
      let closeVerifyEmail = document.getElementById("closeVerifyEmail")
      if (closeVerifyEmail) {
        closeVerifyEmail.click()
      }
    } catch (error) {
      setLoading(false)
      if (error.response && error.response && error.response.body && error.response.body.error_description) {
        toast.error(error.response.body.error_description)
      } else {
        toast.error(JSON.stringify(error))
      }
    }
  }

  const resendOtpNow = async () => {
    await resendOtp();
  };
  return <div
    className="modal VerifyEmailModal fade"
    id="VerifyEmailModal"
    data-bs-backdrop="static"
    tabIndex="-1"
    aria-labelledby="fixedPriceModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row" onSubmit={(e) => { e.preventDefault(); verify() }}>
          <div className="col-md-9 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5 className="modal-title w-100 text-center  fw-bold  pb-0  pt-4 d-flex justify-content-center" id="fixedPriceModalLabel">
                  <span className="">Verify Email</span></h5>
                <button id="closeVerifyEmail" type="button" className="btn  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close" onClick={() => setOtp("")}>
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-2 py-1">
                  <div className="input-group mb-2">
                    <input type="text" className="form-control border-pink pe-5" placeholder="Enter OTP " value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                  <p className="text-dark"> <span className="">Didn't receive any code?</span> {timerCount ? `Resend Code in ${timerCount}` : <b className="cursor-pointer" onClick={() => resendOtpNow()}>Resend Code</b>} </p>
                </div>
              </div>
              <div className="modal-footer border-0 pb-3 pt-2 p-4">
                <button type="submit" className="btn banner-button primery-button px-lg-3 w-100 m-auto" disabled={loading}>{loading ? <Spinner /> : 'VERIFY NOW !'}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
};

const ReportNft = ({ reportText, submitReport, setReportText }) => {
  return <div
    className="modal Reportmodal fade"
    id="Reportmodal"
    data-bs-backdrop="static"
    tabIndex="-1"
    aria-labelledby="fixedPriceModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row">
          <div className="col-md-9 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5
                  className="modal-title w-100 text-center  fw-bold  pb-0  pt-4 d-flex justify-content-center"
                  id="fixedPriceModalLabel"
                >
                  <span className="">Report Nft</span>
                </h5>
                <button
                  id="closeReportModal"
                  type="button"
                  className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-2 py-1">
                  <div className="input-group mb-2">
                    <textarea
                      type="text"
                      className="form-control border-pink pe-5"
                      placeholder="Enter message  "
                      value={reportText}
                      onChange={(e) => setReportText(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 pb-3 pt-2 p-4">
                <button
                  type="submit"
                  className="btn banner-button primery-button px-lg-3 w-100 m-auto"
                  onClick={submitReport}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
};

const ReportUserModal = ({ reportUserText, submitUserReport, setUserReportText }) => {
  return <div
    className="modal ReportUserModal fade"
    id="ReportUserModal"
    data-bs-backdrop="static"
    tabIndex="-1"
    aria-labelledby="fixedPriceModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row">
          <div className="col-md-9 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5
                  className="modal-title w-100 text-center  fw-bold  pb-0  pt-4 d-flex justify-content-center"
                  id="fixedPriceModalLabel"
                >
                  <span className="">Report User</span>
                </h5>
                <button
                  id="closeReportUserModal"
                  type="button"
                  className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-2 py-1">
                  <div className="input-group mb-2">
                    <textarea
                      type="text"
                      className="form-control border-pink pe-5"
                      placeholder="Enter message  "
                      value={reportUserText}
                      onChange={(e) => setUserReportText(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 pb-3 pt-2 p-4">
                <button
                  type="submit"
                  className="btn banner-button primery-button px-lg-3 w-100 m-auto"
                  onClick={submitUserReport}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
};

const ReportCollection = ({ reportCollText, submitCollectionReport, setCollReportText }) => {
  return <div
    className="modal ReportCollectionmodal fade"
    id="ReportCollectionmodal"
    data-bs-backdrop="static"
    tabIndex="-1"
    aria-labelledby="fixedPriceModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row">
          <div className="col-md-9 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5
                  className="modal-title w-100 text-center tiitle  pb-0  pt-4 d-flex justify-content-center"
                  id="fixedPriceModalLabel"
                >
                  <span className="">Report Collection</span>
                </h5>
                <button
                  id="closeCollectionModal"
                  type="button"
                  className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-2 py-1">
                  <div className="input-group mb-2">
                    <textarea
                      type="text"
                      className="form-control border-pink pe-5"
                      placeholder="Enter message  "
                      value={reportCollText}
                      onChange={(e) => setCollReportText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 pb-3 pt-2 p-4 d-flex">
                <button
                  type="submit"
                  className="btn banner-button primery-button px-lg-3 w-50 m-auto"
                  onClick={submitCollectionReport}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
};

const RequestCollectionModal = () => {
  const [loading, setLoading] = useState(false)
  const [collectionAddress, setCollectionAddress] = useState("");
  const requestCollection = async () => {
    if (!collectionAddress) {
      toast.warn("Please enter collection address")
    }
    try {
      setLoading(true)
      const items = {
        address: collectionAddress,
        language: "ENGLISH"
      }
      let closeReqCollectionModal = document.getElementById("closeReqCollectionModal")
      let apiRes = await henceforthApi.Collection.reqCollection(items)
      if (closeReqCollectionModal) {
        closeReqCollectionModal.click()
      }
      setCollectionAddress("")
      setLoading(false)
      toast.success("Requested successfully")
    } catch (error) {
      setLoading(false)
      toast.error(error.response.body.error_description)
    }
  }
  return <div
    className="modal RequestCollectionmodal fade"
    id="RequestCollectionmodal"
    data-bs-backdrop="static"
    tabIndex="-1"
    aria-labelledby="fixedPriceModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row" onSubmit={(e) => { e.preventDefault(); requestCollection() }}>
          <div className="col-md-9 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5
                  className="modal-title w-100 text-center  fw-bold  pb-0  pt-4 d-flex justify-content-center"
                  id="fixedPriceModalLabel"
                >
                  <span className="">Request Collection</span>
                </h5>
                <button
                  id="closeReqCollectionModal"
                  type="button"
                  className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-2 py-1">
                  <div className="input-group mb-2">
                    <textarea
                      type="text"
                      className="form-control border-pink pe-5"
                      placeholder="Enter collection address"
                      disabled={loading}
                      value={collectionAddress}
                      onChange={(e) => setCollectionAddress(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="collection-name">
                <div className="modal-footer border-0 pb-3 pt-2 p-4 d-flex">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn banner-button primery-button px-lg-3 w-50 m-auto"
                  >
                    {loading ? <Spinner /> : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
};

const NftCreateStep = ({ createNftStep }) => {
  console.log('createNftStep', createNftStep);
  return <div>
    <button
      type="button"
      id="btnOpenMintModal"
      className="btn btn-primary d-none"
      data-bs-toggle="modal"
      data-bs-target="#steps"
    ></button>
    <div className="modals-name steps">
      <div
        className="modal fade steps common-modal steps-modal"
        data-bs-backdrop="static"
        id="steps"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              id="btnCloseMintModal"
              type="button"
              className="btn d-none shadow-none fs-6 position-absolute top-0 end-0 z-index-1"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body py-lg-4 py-0">
              <h5
                className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-0 d-flex justify-content-center"
                id="buystepsModalLabel"
              >
                <span className="text-gradient">Follow Steps</span>
              </h5>
              <div className="nft-body-modal">
                <div className="upload-steps my-0">
                  <div className="upload-status">
                    {createNftStep == NFT_STEP_1 ? (
                      <Spinner />
                    ) : createNftStep > NFT_STEP_1 ? (
                      <div className="step-img">
                        <i
                          className="fa fa-check  text-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="step-img">
                        <i
                          className="fa fa-check color-inherit"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="step-text">
                    <h2 className="fw-bold">Upload files & Mint token </h2>
                    <p>
                      Adding your asset to the blockchain and putting it into
                      circulation to be bought or sold.
                    </p>
                  </div>
                </div>
                <div className="upload-steps">
                  <div className="upload-status">
                    {createNftStep == NFT_STEP_2 ? (
                      <Spinner />
                    ) : createNftStep > NFT_STEP_2 ? (
                      <div className="step-img">
                        <i
                          className="fa fa-check  text-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="step-img">
                        <i
                          className="fa fa-check color-inherit"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="step-text">
                    <h2 className="fw-bold"> Approve</h2>
                    <p>
                      AcriaNetwork is approving this asset for your
                      collection.
                    </p>
                  </div>
                </div>
                <div className="upload-steps">
                  <div className="upload-status">
                    {createNftStep == NFT_STEP_3 ? (
                      <Spinner />
                    ) : createNftStep > NFT_STEP_3 ? (
                      <div className="step-img">
                        <i
                          className="fa fa-check text-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="step-img">
                        <i
                          className="fa fa-check color-inherit"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="step-text">
                    <h2 className="fw-bold">List</h2>
                    <p>
                      Listing your asset on AcriaNetwork so it can be found
                      and purchased by users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};

const WethStep = ({ steps, title, description }) => {
  return <div>
    <div className="modals-name steps">
      <div
        className={`modal fade steps common-modal steps-modal ${steps > 0 ? 'show' : ''}`}
        data-bs-backdrop="static"
        id="wEthSteps"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        role="dialog"
        style={{ display: steps > 0 ? 'block' : '' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              id="btnCloseMintModal"
              type="button"
              className="btn d-none shadow-none fs-6 position-absolute top-0 end-0 z-index-1"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body py-4">
              <h5
                className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-0 d-flex justify-content-center"
                id="buystepsModalLabel"
              >
                <span className="text-gradient">Follow Steps</span>
              </h5>
              <div className="nft-body-modal">
                <div className="upload-steps my-0">
                  <div className="upload-status">
                    {steps == NFT_STEP_1 ? (
                      <Spinner />
                    ) : steps > NFT_STEP_1 ? (
                      <div className="step-img">
                        <i
                          className="fa fa-check  text-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="step-img">
                        <i
                          className="fa fa-check color-inherit"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="step-text">
                    <h2 className="fw-bold">Deposit wETH</h2>
                    <p>
                      Converting amount ETH to wETH
                    </p>
                  </div>
                </div>
                <div className="upload-steps">
                  <div className="upload-status">
                    {steps == NFT_STEP_2 ? (
                      <Spinner />
                    ) : steps > NFT_STEP_2 ? (
                      <div className="step-img">
                        <i
                          className="fa fa-check  text-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="step-img">
                        <i
                          className="fa fa-check color-inherit"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="step-text">
                    <h2 className="fw-bold"> Approve wETH</h2>
                    <p>
                      AcriaNetwork is approving this wETH in marketplace
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default {
  VerifyEmail,
  ReportNft,
  NftCreateStep,
  WethStep,
  ReportCollection,
  ReportUserModal,
  RequestCollectionModal
};
