import React, { Component } from 'react';
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "../assets/images/profile_pic_profile_page.svg";

export default ({ index, user_name, user_id, address, profile_pic, myProfile, is_following, onClickAction, type, authState }) => {
    return <div className="d-flex justify-content-between align-items-center inner-modimg mb-2">
        <div className="d-flex justify-content-around align-items-center plce-img">
            <img src={profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${profile_pic}` : profile_placeholder} />
            <p className="f-bold mb-0 ps-1">{user_name ? `@${user_name}` : `${address.slice(0, 4)}.....${address.slice(-4)}`}</p>
        </div>
        {authState?._id !== user_id &&
            <button type="button" className="btn btn-secondary btn-primary btnn-changes" onClick={() => onClickAction(index, user_id, type, !is_following)}>{myProfile ? is_following ? 'Remove' : 'Follow' : is_following ? 'Unfollow' : 'Follow'}</button>
        }
    </div>
}