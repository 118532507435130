import { Link, } from "react-router-dom";
import henceforthIPFS from "../utils/henceforthIPFS";
import profile_placeholder from "./../assets/images/view_profile.svg";

export default ({
  title,
  metadata,
  contract,
  id,
  col,
}) => {
  return <div className={col}>
    <div className="live-box p-4">
      <Link to={`/hot-collection/offer/${contract?.address}/${id?.tokenId}/${id?.tokenMetadata?.tokenType}`}>
        <div className="live-img position-relative">
          {/* {metadata.image?.includes(".mp4") ? <video autoPlay loop src={metadata?.image}></video> : <img src={metadata?.image?.startsWith("data:") ? metadata?.image : henceforthIPFS.getWithIPFS(metadata?.image) ? henceforthIPFS.getWithIPFS(metadata?.image_url) : profile_placeholder} className="w-100" />} */}
          {metadata.image && metadata.image ? metadata.image?.includes(".mp4") ? <video autoPlay loop src={metadata?.image}></video> : <img src={metadata?.image?.startsWith("data:") ? metadata?.image : henceforthIPFS.getWithIPFS(metadata?.image) ? henceforthIPFS.getWithIPFS(metadata?.image) : profile_placeholder} className="w-100" />
            : metadata.image_url?.includes(".mp4") ? <video autoPlay loop src={metadata?.image_url}></video> : <img src={metadata?.image_url?.startsWith("data:") ? metadata?.image_url : henceforthIPFS.getWithIPFS(metadata?.image_url) ? henceforthIPFS.getWithIPFS(metadata?.image_url) : profile_placeholder} className="w-100" />}
        </div>
      </Link>
      <div className="live-text pt-3">
        <div className="d-flex justify-content-between align-items-center">
        </div>
        <div className="owner-name d-flex pt-2">
          <div className="name-img">
            {/* {metadata.image?.includes(".mp4") ? <video autoPlay loop src={metadata?.image}></video> : <img src={metadata?.image?.startsWith("data:") ? metadata?.image : henceforthIPFS.getWithIPFS(metadata?.image) ? henceforthIPFS.getWithIPFS(metadata?.image_url) : profile_placeholder} className="w-100" />} */}
            {metadata.image && metadata.image ? metadata.image?.includes(".mp4") ? <video autoPlay loop src={metadata?.image}></video> : <img src={metadata?.image?.startsWith("data:") ? metadata?.image : henceforthIPFS.getWithIPFS(metadata?.image) ? henceforthIPFS.getWithIPFS(metadata?.image) : profile_placeholder} className="w-100" />
              : metadata.image_url?.includes(".mp4") ? <video autoPlay loop src={metadata?.image_url}></video> : <img src={metadata?.image_url?.startsWith("data:") ? metadata?.image_url : henceforthIPFS.getWithIPFS(metadata?.image_url) ? henceforthIPFS.getWithIPFS(metadata?.image_url) : profile_placeholder} className="w-100" />}
          </div>
          <div className="name-text ms-2">
            <h6>{title ? title : parseInt(id?.tokenId, 16)}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
};
