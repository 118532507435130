
import profile_placeholder from "../assets/images/profile_pic_profile_page.svg";
import {useMatch } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import { WALLET_ADDRESS_SLICE_FROM } from "./../context/actionTypes";
import HotCollectionNfts from "../components/HotCollectionNfts";

export default () => {
  const {
    authState,
    setLoading,
    loading,
    usdPrice,
    scrollToTop,
    exploreDispatch,
  } = useContext(GlobalContext);
  const [loadMore, setLoadMore] = useState(null)
  const [showMore, setShowMore] = useState(false);
  const [state, setState] = useState({
    data: [],
    total_count: 0,
    page: 0
  });
  const [collectionNfts, setCollectionNfts] = useState({
    nfts: [],
  });
  henceforthApi.setToken(authState.access_token);
  const match = useMatch("/hot-collection/:id");
  const initialiseCollNfts = async (nextToken) => {
    setLoading(true)
    try {
      let apiRes = await henceforthApi.NFT.hotcollectionnfts(match.params.id, nextToken, 12)
      let data = apiRes.data
      let nfts = data.nfts
      debugger
      if (nextToken) {
        let oldNfts = collectionNfts.nfts
        let newNfts = [...oldNfts, ...nfts]
        setCollectionNfts({
          ...collectionNfts,
          nfts: newNfts,
          nextToken: data.nextToken
        })
      } else {
        setCollectionNfts({
          ...collectionNfts,
          ...data,
          nextToken: data.nextToken
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initialiseCollDetails = async () => {
    try {
      let apiRes = await henceforthApi.Collection.getHotCollectionDetails(match.params.id);
      let data = apiRes.data;
      setState(apiRes.data);
    } catch (error) { }
  };

  useEffect(() => {
    if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
      if (collectionNfts?.nfts?.length) {
        if (!loading) {
          if (collectionNfts?.nfts?.length) {
            initialiseCollNfts(collectionNfts.nextToken)
          } else {
          }
        }
      }
    }
  }, [loadMore])

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore)
    return () =>
      window.removeEventListener("scroll", setLoadMore)
  }, [])

  useEffect(() => {
    scrollToTop()
    initialiseCollNfts(0);
    initialiseCollDetails();
  }, [match.params.id]);

  return <section className="activity-wrapper mt-lg-5 mt-4">
    <div className="container">
      <div className="row">
        {Array.isArray(state?.collections) && state?.collections?.map((res) => {
          return (
            <>
              <div key={res._id} className="row">
                <div className="col-md-12">
                  <div>
                    <img
                      src={
                        res?.cover_img ? res?.cover_img : profile_placeholder
                      }
                      className="cover-img cover-hot-featured"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 position-relative mb-3">
                <div className="collection-profile p-4">
                  <div className="profile-image position-relative m-auto  dddddddd">
                    <img
                      src={
                        res?.image_url ? res?.image_url : profile_placeholder
                      }
                    />
                    <div className="position-absolute bottom-0 end-0">
                      <label htmlFor="fil">
                        <span className="btn pe-0">
                        </span>
                        <input className="d-none" type="file" id="fil" />
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className=" mt-4 mb-1 profile-name">
                      {res?.name ? res?.name : "Not available"}
                    </h4>
                    <h6 className="text-gray profile-id mb-0" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(res?.address); toast.success("Copied") }}>{res?.address?.length ? `${res?.address?.slice(0, WALLET_ADDRESS_SLICE_FROM)}....${res?.address?.slice(res?.address?.length - WALLET_ADDRESS_SLICE_FROM, res?.address?.length)}` : ''}</h6>
                  </div>
                  <hr />
                  <div className="row justify-content-center">
                    <div className="col-md-6 pe-1">
                      <div className="border item-box mb-3 mb-lg-0">
                        <p className="mb-0 text-gray fs-12">Items</p>
                        <p className="mb-0 text-dark fw-bold fs-18 detail-collectionn">
                          {res.total_supply ? res.total_supply : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-break detail-collectionn">
                    {showMore ? `${res.description}` : `${res.description?.substring(0, 100)}...`}
                    {res.description?.length > 100 ?
                      <button className="btn-readmore" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "Read less" : "Read more"}
                      </button>
                      : ""}
                  </div>
                </div>
              </div>
            </>
          )
        })}
        <div className="col-xl-9 col-lg-8 nav-tabss">
          <div className="on-salle pb-5 mb-lg-5 mt-4 pt-lg-2">
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade  show active `}
                id="pills-item"
                role="tabpanel"
                aria-labelledby="pills-item-tab">
                <div className="container p-0">
                  <div className="row">
                    {collectionNfts?.nfts?.map((res, index) => {
                      return <HotCollectionNfts
                        key={res._id}
                        {...res}
                        usdPrice={usdPrice}
                        currentUser={authState._id}
                        index={index}
                        exploreDispatch={exploreDispatch}
                        col="col-lg-4 col-md-6 mb-md-4 mb-sm-3 mb-4"
                      />
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}