import { Link } from "react-router-dom";
import name from "./../assets/images/view_profile.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import headercard from "./../assets/images/header_card.png";
import circleorange from "./../assets/images/bg_circle_orange.png";
import circlered from "./../assets/images/bg_circle_red.png";
import circleblue from "./../assets/images/bg_circle_blue.png";
import imgone from "./../assets/images/img_one.png";
import imgtwo from "./../assets/images/img_two.png";
import imgthree from "./../assets/images/img_three.png";
import imgfour from "./../assets/images/img_four.png";
import sellone from "./../assets/images/add_your_nft.png";
import selltwo from "./../assets/images/create_your_collection.png";
import sellthree from "./../assets/images/setup_your_wallet.png";
import sellfour from "./../assets/images/list_them_for_sale.png";
import React from "react";
import Slider from "react-slick";
import LiveAuctionsCompo from "../components/LiveAuctionsCompo";
import LiveFixedCompo from "../components/LiveFixedCompo";
import { useContext } from "react";
import { GlobalContext } from "../context/Provider";
import TopSellersCompo from "../components/TopSellersCompo";
import { useEffect } from "react";
import ContectUs from "../components/ContectUs";
import { useState } from 'react'
import henceforthApi from '../utils/henceforthApi';
import HotCollectionCompo from "../components/HotCollectionCompo";
import ExploreFilter from "../components/ExploreFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { FIXED_PRICE_AUCTION_ID, UNLIMITED_AUCTION_AUCTION_ID } from "../context/actionTypes";
import Faqpage from "./Faqpage";
import TrendingCollCompo from "../components/TrendingCollCompo";
import HotCollOpenSea from "../components/HotCollOpenSea";
export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, usdPrice, exploreDispatch, collectionData, trendingCollection, hotCollection } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)
    const query = new URLSearchParams(location.search)
    const [nftsList, setNftsList] = useState({
        data: [],
    })
    const [liveAuctionList, setLiveAuctionList] = useState({
        data: [],
    })
    const [trendingList, setTrendingList] = useState({
        data: [],
    })
    const [topSellersList, setTopSellersList] = useState({
        data: [],
    })
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        centerMode: true,
        autoplay: false,
        slidesToScroll: 2,
        adaptiveHeight: true,
        variableWidth: true,
        variableHeight: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };

    const followFun = async (id, b, index) => {
        if (authState.access_token) {
            const allData = topSellersList.data
            allData[index]["loading"] = true
            setTopSellersList({
                ...topSellersList,
                data: allData
            })
            const items = {
                user_id: id,
                language: "ENGLISH"
            }
            try {
                if (b) {
                    await henceforthApi.Follower.unfollow(items)
                    allData[index]["following"] = !b
                    allData[index]["followers"] = allData[index]["followers"] - 1

                } else {
                    await henceforthApi.Follower.follow(items)
                    allData[index]["following"] = !b
                    allData[index]["followers"] = allData[index]["followers"] + 1
                }
                allData[index]["loading"] = false
            } catch (error) {
                allData[index]["loading"] = false
            }
            setTopSellersList({
                ...topSellersList,
                data: allData
            })
            topSellersData()

        } else {
            query.set("action", "login")
            query.set("page", "/")
            query.set("work", "artists")
            query.set("id", id)
            query.set("b", b)
            query.set("index", index)
            navigate({ search: query.toString() })
        }
    }
    const topSellersData = async () => {
        let exploreTopSellers = await henceforthApi.NFT.exploreTopSellers()
        setTopSellersList({ ...exploreTopSellers.data })
    }
    const initialiseData = async () => {
        let q = query.toString()
        let explore = await henceforthApi.NFT.explore(q)
        setNftsList({ ...explore.data })
        let exploreLiveAuction = await henceforthApi.NFT.exploreLiveAuction()
        setLiveAuctionList({ ...exploreLiveAuction.data })
        let exploreTrending = await henceforthApi.NFT.exploreTrending()
        setTrendingList({ ...exploreTrending.data })
    }
    useEffect(() => {
        topSellersData()
    }, [])
    useEffect(() => {
        initialiseData()
    }, [query.get("collection_id"), query.get("sale_type"), query.get("filter_by"), query.get("min_price"), query.get("max_price")])
    return <>
        <section className="banner pt-lg-5 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-lg-5 mb-lg-5 pt-lg-3 pb-lg-5 order-last order-lg-first order-md-first">
                        <div className="banner-text ">
                            <h2 className="fw-bold">The Only Marketplace With No Fees!</h2>
                            <h1 className=" fw-bold">Discover, Create,<br /> Sell <span> Extraordinary </span> <br /> Digital Artworks </h1>
                            <p className="fw-normal pt-3 pb-3 ">The world's first and largest digital marketplace for <br /> crypto collections & non-fungible token(NFTs).</p>
                            <Link to="/explore"> <button className="orange-btn btn btn-light Create-btn rounded-pill pe-4 ps-4 me-3"> Discover NFT</button> </Link>
                            <Link to="/content/create"> <button className="trant-btn btn btn-light Create-btn rounded-pill pe-4 ps-4"> Create NFT <i className="fa fa-angle-right" aria-hidden="true"></i> </button></Link>
                        </div>
                        <img src={circleorange} className="circleorange" />
                    </div>
                    <div className="col-md-6 position-relative">
                        <div className="head-img">
                            <img src={headercard} className="w-100" />
                            <img src={circlered} className="circlered" />
                            <img src={circleblue} className="circleblue circlered" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="live-auctions pt-lg-5 pb-lg-5 mt-lg-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Trending NFTs</h2>
                            <Link to="/explore"> <p className="view-color mb-0 fs-5 ">View all trendings <i className="fa fa-angle-right" aria-hidden="true"></i></p>
                            </Link>
                        </div>
                        <hr />
                    </div>
                    {trendingList.data.length ? trendingList.data.map((res, index) => {
                        return (res.type == FIXED_PRICE_AUCTION_ID || res.type == UNLIMITED_AUCTION_AUCTION_ID) ?
                            <LiveFixedCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" /> :
                            <LiveAuctionsCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" />
                    })
                        : nftsList.data.map((res, index) => {
                            return (res.type === FIXED_PRICE_AUCTION_ID || res.type == UNLIMITED_AUCTION_AUCTION_ID) ?
                                <LiveFixedCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" /> :
                                <LiveAuctionsCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" />
                        })}
                </div>
            </div>
        </section>

        <section className="live-auctions pt-lg-5 pb-5 mt-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3 ">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Featured User Requested Collections</h2>
                            <Link to="/trending-collections">  <p className="view-color mb-0 fs-5 ">View all Featured collection <i className="fa fa-angle-right" aria-hidden="true"></i></p></Link>
                        </div>
                        <hr />
                    </div>
                    {Array.isArray(trendingCollection.data) && trendingCollection.data.map((res) => {
                        return <TrendingCollCompo col="col-md-3" key={res._id} {...res} />
                    })}
                </div>
            </div>
        </section>

        <section className="live-auctions pt-lg-5 pb-5 mt-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3 ">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Hot Collections</h2>
                            <Link to="/hot-collections">  <p className="view-color mb-0 fs-5 ">View all Hot collection <i className="fa fa-angle-right" aria-hidden="true"></i></p></Link>
                        </div>
                        <hr />
                    </div>
                    {Array.isArray(hotCollection.collections) && hotCollection.collections.map((res) => {
                        return <HotCollOpenSea col="col-md-3" key={res._id} {...res} />
                    })}
                </div>
            </div>
        </section>

        <section className="sliderss live-auctions position-relative pb-lg-0 pt-5 mt-5 mb-0">
            <div className="container">
                <div className="col-md-12 mb-4 mt-4">
                    <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                        <h2 className=" mb-sm-0">Top Sellers</h2>
                        <Link to="/topsellers"> <p className=" mb-0 fs-5 ">View all sellers <i className="fa fa-angle-right" aria-hidden="true"></i></p> </Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div className="slider-topp">
                            <Slider {...settings}>
                                {Array.isArray(topSellersList.data) && topSellersList.data.map((res, index) => {
                                    return <TopSellersCompo key={res._id} index={index} {...res} followFun={followFun} col="col-md-12" />
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="live-auctions d-none pb-lg-5 mt-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Top Sellers</h2>
                            <p className="view-color mb-0 fs-5 ">View all sellers <i className="fa fa-angle-right" aria-hidden="true"></i></p>
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3">
                        <div className="live-box p-4">
                            <div className="live-img position-relative">
                                <img src={imgfour} className="w-100 " />
                            </div>
                            <div className="live-text pt-3">
                                <div className="owner-name d-flex pt-2">
                                    <div className="name-img">
                                        <img src={name} />
                                    </div>
                                    <div className="name-text ms-2">
                                        <h6 className="mb-0 fw-bolderr">@owner_name</h6>
                                        <p className="fs-6 text-black-50 mb-0">Art by creator_name</p>
                                    </div>
                                </div>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                    <div className="bid-left">
                                        <h6 className="mb-0 fw-bolderr text-start">100</h6>
                                        <p className="text-black-50 mb-0">Followers</p>
                                    </div>
                                    <div className="bid-right">
                                        <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" href="#">Follow</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3">
                        <div className="live-box p-4">
                            <div className="live-img position-relative">
                                <img src={imgthree} className="w-100" />
                            </div>
                            <div className="live-text pt-3">
                                <div className="owner-name d-flex pt-2">
                                    <div className="name-img">
                                        <img src={name} />
                                    </div>
                                    <div className="name-text ms-2">
                                        <h6 className="mb-0 fw-bolderr">@owner_name</h6>
                                        <p className="fs-6 text-black-50 mb-0">Art by creator_name</p>
                                    </div>
                                </div>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                    <div className="bid-left">
                                        <h6 className="mb-0 fw-bolderr text-start">100</h6>
                                        <p className="text-black-50 mb-0">Followers</p>
                                    </div>
                                    <div className="bid-right">
                                        <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" href="#">Follow</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3">
                        <div className="live-box p-4">
                            <div className="live-img position-relative">
                                <img src={imgtwo} className="w-100" />
                            </div>
                            <div className="live-text pt-3">
                                <div className="owner-name d-flex pt-2">
                                    <div className="name-img">
                                        <img src={name} />
                                    </div>
                                    <div className="name-text ms-2">
                                        <h6 className="mb-0 fw-bolderr">@owner_name</h6>
                                        <p className="fs-6 text-black-50 mb-0">Art by creator_name</p>
                                    </div>
                                </div>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                    <div className="bid-left">
                                        <h6 className="mb-0 fw-bolderr text-start">100</h6>
                                        <p className="text-black-50 mb-0">Followers</p>
                                    </div>
                                    <div className="bid-right">
                                        <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" href="#">Follow</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3">
                        <div className="live-box p-4">
                            <div className="live-img position-relative">
                                <img src={imgone} className="w-100" />
                            </div>
                            <div className="live-text pt-3">

                                <div className="owner-name d-flex pt-2">
                                    <div className="name-img">
                                        <img src={name} />
                                    </div>
                                    <div className="name-text ms-2">
                                        <h6 className="mb-0 fw-bolderr">@owner_name</h6>
                                        <p className="fs-6 text-black-50 mb-0">Art by creator_name</p>
                                    </div>
                                </div>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                                <div className="highhest-bid d-flex justify-content-between align-items-center pt-3">
                                    <div className="bid-left">
                                        <h6 className="mb-0 fw-bolderr text-start">100</h6>
                                        <p className="text-black-50 mb-0">Followers</p>
                                    </div>
                                    <div className="bid-right">
                                        <button className="trant-btn btn btn-light Create-btn pe-4 ps-4" href="#">Follow</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="live-auctions pt-5 pb-lg-5 mt-lg-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Live Auctions</h2>
                            <Link to="/explore?filter_by=LIVE_AUCTIONS">  <p className="view-color mb-0 fs-5 ">View all live auctions <i className="fa fa-angle-right" aria-hidden="true"></i></p></Link>
                        </div>
                        <hr />
                    </div>
                    {liveAuctionList.data.map((res, index) => {
                        return <LiveAuctionsCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" />
                    })}
                </div>
            </div>
        </section>

        <section className="live-auctions pt-lg-5 pb-5 mt-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2">
                            <h2 className=" mb-sm-0">Featured Collections on Acria</h2>
                            <Link to="/collections">  <p className="view-color mb-0 fs-5 ">View all Featured collection <i className="fa fa-angle-right" aria-hidden="true"></i></p></Link>
                        </div>
                        <hr />
                    </div>
                    {Array.isArray(collectionData.data) && collectionData.data?.slice(0, 8).map((res) => {
                        return <HotCollectionCompo col="col-md-3" key={res._id} {...res} />
                    })}
                </div>
            </div>
        </section>

        <section className="create-sell live-auctions pt-lg-5 pb-lg-5 mt-lg-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="d-lg-flex justify-content-between align-items-center pb-lg-2 text-sm-center">
                            <h2 className=" mb-0 mt-lg-0 mt-5">Create And Sell Your NFT's</h2>
                            <Link to="/content/create"><button type="button" className="orange-btn btn pe-4 ps-4 btn-light me-lg-3 ms-lg-3 Create-btn shadow-none my-lg-0 my-2">Create and sell NFT's </button></Link>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-md-4 mb-sm-3 mb-3">
                        <div className="craete-div p-4">
                            <img src={sellthree} className="" />
                            <h2 className="fw-bolderr mt-2 fs-5">Setup your wallet</h2>
                            <p className="mb-0  text-black-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-md-4 mb-sm-3 mb-3">
                        <div className="craete-div p-4">
                            <img src={selltwo} className="" />
                            <h2 className="fw-bolderr mt-2 fs-5">Setup your wallet</h2>
                            <p className="mb-0  text-black-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-md-4 mb-sm-3 mb-3">
                        <div className="craete-div p-4">
                            <img src={sellone} className="" />
                            <h2 className="fw-bolderr mt-2 fs-5">Setup your wallet</h2>
                            <p className="mb-0  text-black-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-md-4 mb-sm-3 mb-3">
                        <div className="craete-div p-4">
                            <img src={sellfour} className="" />
                            <h2 className="fw-bolderr mt-2 fs-5">Setup your wallet</h2>
                            <p className="mb-0  text-black-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum lectus diam, eget porttitor elit imperdiet ut. In hac habitasse platea dictumst. Mauris blandit pharetra convallis.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="logo-form mb-lg-5 pb-lg-0">
            <Faqpage />
        </section>

        <section className="live-auctions pt-lg-5 pb-5 mt-5 mb-lg-5">
            <div className="container">
                <div className="row">
                    <ExploreFilter name="Explore" />
                    <hr />
                    {nftsList.data.map((res, index) => {
                        return (res.type === FIXED_PRICE_AUCTION_ID) ?
                            <LiveFixedCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" /> :
                            <LiveAuctionsCompo key={res._id} {...res} usdPrice={usdPrice} currentUser={authState._id} index={index} exploreDispatch={exploreDispatch} col="col-lg-3 col-md-6 mb-md-4 mb-sm-3 mb-3" />
                    })}
                    {nftsList.data.length === 0 ?
                        <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5">No Trading history found</p>
                        </div>
                        : <div className="col-md-12 text-center mt-4 pt-lg-4">
                            <button className="orange-btn btn bg-dark  Create-btn rounded-pill pe-5 ps-5 border" href="#"><Link to="/explore" className="text-white text-decoration-none">View All</Link></button>
                        </div>}
                </div>
            </div>
        </section>

        <section className="logo-form mb-5 pb-5">
            <ContectUs />
        </section>
    </>
}