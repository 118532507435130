import { Link } from "react-router-dom";
import Modals from "../../pages/Modals";
import facebook from "./../../assets/images/facebook.svg";
import youtube from "./../../assets/images/youtube.svg";
import telegram from "./../../assets/images/telegram.svg";
import twitter from "./../../assets/images/twitter.svg";
import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";

export default () => {
  const {
    authState,

  } = useContext(GlobalContext);
  return <footer className="footer pt-3 pb-3 mt-2">
    <div className="container">
      <div className="footer-spacee d-lg-flex justify-content-lg-between align-items-center">
        <div className=" mb-md-0 mb-sm-3 mb-3">
          <div className="social d-flex justify-content-lg-start justify-content-center align-items-center">
            <small className="text-white me-2 footer-social fw-4 footerr-copy">Follow Us:</small>
            <a className="mx-1" href="https://www.facebook.com/" target="_blank">
              <img src={facebook} /></a>
            {/* <a className="mx-1" href="https://www.instagram.com/" target="_blank">
              <img src={instagram} /></a> */}
                <a className="mx-2" href="https://youtube.com/c/AcriaNetwork" target="_blank">
              <img src={youtube} /></a>
            {/* <a className="mx-1" href="https://www.linkedin.com/" target="_blank">
              <img src={linkedin} /></a> */}
              <a className="mx-2" href="https://www.linkedin.com/" target="_blank">
              <img src={telegram} /></a>
              <a className="mx-1" href="https://twitter.com/acrianetwork" target="_blank">
              <img src={twitter} /></a>
            {/* <img className="me-2 ms-2" src={instagram} />
            <img src={linkedin} /> */}
          </div>
        </div>
        <div className=" ">
          <p className="text-white text-center mb-0 footerr-copy">©2022 NFTs Marketplace. All rights reserved.</p>
        </div>
        <div className="">
          <div className="footer-social">
            <ul className="d-lg-flex mb-0 ps-0 justify-content-lg-end justify-content-center text-center mt-3 mt-lg-0 align-items-center">
              <li><Link to="/about-us" className="text-white fw-light footerr-copy">About Us</Link></li>
              <li className="  mx-md-4 mx-sm-3 mx-lg-3"><Link to="/terms" className="text-white fw-light footerr-copy">Terms of Service</Link></li>
              <li><Link to="/privacy" className="text-white fw-light footerr-copy">Privacy Policy</Link></li>
              {authState.access_token ?
                <li className="text-white fw-light footerr-copy p-0 reqcollcursor ms-lg-3" data-bs-toggle="modal"
                  data-bs-target="#RequestCollectionmodal">
                  Request Collection
                </li> :
                <li className="text-white fw-light footerr-copy reqcollcursor   ms-lg-3" data-bs-toggle="modal"
                  data-bs-target="#LoginWithSocial">
                  Request Collection
                </li>}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Modals.RequestCollectionModal />
  </footer>
}
