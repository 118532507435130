
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";
import profile_placeholder from "./../assets/images/view_profile.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import LiveFixedCompo from "../components/LiveFixedCompo";
import LiveAuctionsCompo from "../components/LiveAuctionsCompo";
import CreateCollection from "../components/collection/CreateCollection";
import exploreAction from "../context/actions/auth/exploreAction";
import Activity from "../pages/activity";
import authAction from "../context/actions/auth/authAction";
import { ERROR_UNAUTHORIZED } from "../context/actionTypes";
import { Spinner } from "../components/BootstrapCompo";
import loginSuccess from "../context/actions/auth/loginSuccess";
import FollowUserListCompo from "../components/FollowUserListCompo";
export default ({
  is_favourite,
  index,
  exploreDispatch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/profile/:tab");
  var query = new URLSearchParams(location.search);
  const {
    authState,
    accounts,
    authDispatch,
    loading,
    setLoading,
    scrollToTop,
    usdPrice,
    fileUpload,
    logOutNow,
  } = useContext(GlobalContext);
  const [loadMore, setLoadMore] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [is_favourite_state, setis_favourite] = useState(is_favourite ? is_favourite : false)
  const [followers, setFollowers] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [followings, setFollowings] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [onSaleItems, setOnSaleItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [soldItems, setSoldItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [ownItems, setOwnItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [createdItems, setCreatedItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [collectionNfts, setCollectionNfts] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [favoritedItems, setFavouriteItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });
  const [activityItems, setActivityItems] = useState({
    data: [],
    total_count: 0,
    page: 0,
  });

  const [state, setState] = useState({
    account_status: "",
    admin_verified: false,
    connection_id: null,
    created_at: "",
    device_type: null,
    email: "",
    email_verified: false,
    fcm_token: null,
    is_following: false,
    following: false,
    is_blocked: false,
    is_deleted: false,
    last_seen: null,
    name: "",
    profile_pic: null,
    followers: 0,
    followings: 0,
    unique_code: null,
    updated_at: "",
    user_name: "",
    user_status: 0,
    you_are_blocked: false,
    you_block_to: false,
  });

  henceforthApi.setToken(authState.access_token);

  const initialiseFev = async (index, _id, is_favourite) => {
    if (authState.access_token) {
      try {
        let data = [];
        data = onSaleItems.data.filter((res) => res._id === _id);
        if (data.length) {
          onSaleItems.data[index]["is_favourite"] = is_favourite;
          if (is_favourite) {
            onSaleItems.data[index]["total_favourites"] =
              data[0].total_favourites + 1;
          } else {
            onSaleItems.data[index]["total_favourites"] =
              data[0].total_favourites - 1;
          }
          setOnSaleItems({ ...onSaleItems });
        }
        data = soldItems.data.filter((res) => res._id === _id);
        if (data.length) {
          soldItems.data[index]["is_favourite"] = is_favourite;
          if (is_favourite) {
            soldItems.data[index]["total_favourites"] =
              data[0].total_favourites + 1;
          } else {
            soldItems.data[index]["total_favourites"] =
              data[0].total_favourites - 1;
          }
          setSoldItems({ ...soldItems });
        }
        data = ownItems.data.filter((res) => res._id === _id);
        if (data.length) {
          ownItems.data[index]["is_favourite"] = is_favourite;
          if (is_favourite) {
            ownItems.data[index]["total_favourites"] =
              data[0].total_favourites + 1;
          } else {
            ownItems.data[index]["total_favourites"] =
              data[0].total_favourites - 1;
          }
          setOwnItems({ ...ownItems });
        }
        data = createdItems.data.filter((res) => res._id === _id);
        if (data.length) {
          createdItems.data[index]["is_favourite"] = is_favourite;
          if (is_favourite) {
            createdItems.data[index]["total_favourites"] =
              data[0].total_favourites + 1;
          } else {
            createdItems.data[index]["total_favourites"] =
              data[0].total_favourites - 1;
          }
          setCreatedItems({ ...createdItems });
        }

        data = favoritedItems.data.filter((res) => res._id === _id);
        if (data.length) {
          favoritedItems.data[index]["is_favourite"] = is_favourite;
          if (is_favourite) {
            favoritedItems.data[index]["total_favourites"] =
              data[0].total_favourites + 1;
          } else {
            favoritedItems.data[index]["total_favourites"] =
              data[0].total_favourites - 1;
          }
          setFavouriteItems({ ...favoritedItems });
        }
        if (is_favourite) {
          let apiRes = await henceforthApi.Favourite.add(_id);
          toast.success(apiRes.message);
        } else {
          let apiRes = await henceforthApi.Favourite.remove(_id);
          toast.success(apiRes.message);
        }
      } catch (error) {
      }
    }
  };

  const addToFev = async (id) => {
    try {
      let items = {
        "nft_id": id,
        "language": "ENGLISH"
      }
      exploreAction.exploreFav({ index, value: true })(exploreDispatch)
      setis_favourite(true)
      let apiRes = await henceforthApi.NFT.addToFev(items)
    } catch (error) {
      if (error.response && error.response.body.error == "UNAUTHORIZED") {
        toast.warn("You must login first.")
      } else if (error.response && error.response.body.error == "ALREADY_MARKED_AS_FAVOURITE") {
        removeToFev(id)
      } else {
        setis_favourite(false)
        exploreAction.exploreFav({ index, value: false })(exploreDispatch)
      }
    }
  }
  const removeToFev = async (id) => {
    try {
      let items = {
        "nft_id": id,
        "language": "ENGLISH"
      }
      exploreAction.exploreFav({ index, value: false })(exploreDispatch)
      setis_favourite(false)
      if (location.pathname.includes("profile/favorite")) {
        authAction.authFav({ index })(authDispatch)
      }
      let apiRes = await henceforthApi.NFT.removeFromFev(items)
    } catch (error) {
      if (error.response && error.response.body.error == "UNAUTHORIZED") {
        toast.warn("You must login first.")
      } else {
        setis_favourite(true)
        exploreAction.exploreFav({ index, value: true })(exploreDispatch)
      }
    }
  }

  const initialiseCollectionNfts = async () => {
    if (!collectionNfts.total_count) {
      let apiRes = await henceforthApi.Collection.get(match.params.id, 0);
      setCollectionNfts(apiRes.data);
      setLoading(false)

    }
    navigate("/profile/collection");
  };

  const initialiseCollectionData = async () => {
    try {
      let apiRes = await henceforthApi.Collection.getList();
      let data = apiRes.data;
      setCollectionNfts(data);
      setLoading(false)
    } catch (error) { }
  };
  useEffect(() => {
    initialiseCollectionData();
  }, [query.get("collection")]);

  useEffect(() => {
    if (match.params.tab == "collection")
      initialiseCollectionNfts()
  }, [match.params.tab]);


  const updateProfile = async (items) => {
    try {
      let apiRes = await henceforthApi.Profile.edit(items);
      let data = apiRes.data;
      debugger
      loginSuccess(data)(authDispatch);
      setState({
        ...state,
        loading: false,
        loading_profile_icon: false,
        loading_banner_icon: false,
      });
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        toast.error(error.response.body.error_description);
      }
      setState({
        ...state,
        loading: false,
        loading_profile_icon: false,
      });
    }
  };

  const onUpdateProfilePicture = async (file) => {
    setState({
      ...state,
      loading_profile_icon: true,
    });
    let fileName = await fileUpload(file);
    let items = {
      profile_pic: fileName,
      language: "ENGLISH",
    };
    await updateProfile(items);
  };
  const onUpdateProfileBanner = async (file) => {
    setState({
      ...state,
      loading_banner_icon: true,
    });
    let fileName = await fileUpload(file);
    let items = {
      cover_img: fileName,
      language: "ENGLISH",
    };
    await updateProfile(items);
  };

  useEffect(() => {
    if (authState.access_token) {
      scrollToTop();
    } else {
      navigate(`/?action=login&redirect=${location.pathname}`, {
        replace: true,
      });
    }
  }, [accounts, authState.access_token]);

  const initialiseProfile = async () => {
    try {
      let apiRes = await henceforthApi.Profile.get();
      let data = apiRes.data;
      setState({ ...data });
      await inialiseTabData("on_sale", 0);
      await inialiseTabData("sold", 0);
      await inialiseTabData("owned", 0);
      await inialiseTabData("created", 0);
      await inialiseTabData("favourite", 0);
      await inialiseTabData("activity", 0);
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        if (error.response.body.error == ERROR_UNAUTHORIZED) {
          logOutNow();
          navigate(`/?action=login&redirect=${location.pathname}`, {
            replace: true,
          });
        } else {
          toast.error(error.response.body.error_description);
        }
      }
    }
  };
  useEffect(() => {
    initialiseProfile();
  }, []);

  useEffect(() => {
    if (match.params.tab == "collection")
      initialiseCollectionNfts()
  }, [match.params.tab]);

  const inialiseProfileTab = async (tab, page) => {
    let q = query.toString();
    try {
      let apiRes = await henceforthApi.Profile.getDetailsById(
        authState._id,
        tab,
        9,
        q,
        page
      );
      return apiRes.data;
    } catch (error) {
    }
  };
  const inialiseTabData = async (tab, page) => {
    // setLoading(true);
    let { data, total_count } = await inialiseProfileTab(
      String(tab).toUpperCase(),
      page
    );
    let arrData = Array.isArray(data) ? data : [];
    switch (tab) {
      case "on_sale": {

        let oldData = onSaleItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setOnSaleItems({
          ...onSaleItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "sold": {
        let oldData = soldItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setSoldItems({
          ...soldItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "owned": {
        let oldData = ownItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setOwnItems({
          ...ownItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }
      case "created": {
        let oldData = createdItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setCreatedItems({
          ...createdItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }

      case "favourite": {
        let oldData = favoritedItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setFavouriteItems({
          ...favoritedItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }

      case "activity": {
        let oldData = activityItems.data;
        let newData = [...oldData, ...arrData];
        const uniqueData = Array.from(new Set(newData.map((a) => a._id))).map(
          (id) => {
            return newData.find((a) => a._id === id);
          }
        );
        setActivityItems({
          ...activityItems,
          total_count: total_count ? total_count : 0,
          data: uniqueData,
          page: page + 1,
        });
        break;
      }

    }
    setLoading(false);
  };

  useEffect(async () => {
    if (match.params.tab) {
      inialiseTabData(match.params.tab, 0);
    }
  }, [
    match.params.tab,
    query.get("collection_id"),
    query.get("sale_type"),
    query.get("filter_by"),
    query.get("min_price"),
    query.get("max_price"),
  ]);

  const followUnFollow = async (index, user_id, type, is_following) => {
    const items = {
      user_id: user_id ? user_id : "",
      language: "ENGLISH",
    };
    try {
      if (is_following) {
        await henceforthApi.Follower.follow(items);
      } else {
        await henceforthApi.Follower.unfollow(items);
      }
      if (type == "followings") {
        let temp = followings.data;
        temp[index]["is_following"] = is_following;
        setFollowings({
          ...followings,
          data: temp,
        });
      } else {
        let temp = followers.data;
        temp[index]["is_following"] = is_following;
        setFollowers({
          ...followers,
          data: temp,
        });
      }
      await initialiseProfile();
    } catch (error) {
      if (
        error.response &&
        error.response &&
        error.response.body &&
        error.response.body.error_description
      ) {
        toast.error(error.response.body.error_description);
      } else {
        toast.error(JSON.stringify(error));
      }
    }
  };
  const initialiseFollowers = async () => {
    try {
      let apiRes = await henceforthApi.Follower.getFollowers(authState._id);
      setFollowers(apiRes.data);
    } catch (error) { }
  };
  const initialiseFollowings = async () => {
    try {
      let apiRes = await henceforthApi.Follower.getFollowings(authState._id);
      setFollowings(apiRes.data);
    } catch (error) { }
  };
  useEffect(() => {
    initialiseFollowings();
    initialiseFollowers();
  }, []);

  useEffect(() => {
    let total_count = 0;
    let data_length = 0;
    let page = 0;
    switch (match.params.tab) {
      case "on_sale": {
        total_count = onSaleItems.total_count;
        data_length = onSaleItems.data.length;
        page = onSaleItems.page;
        break;
      }
      case "sold": {
        total_count = soldItems.total_count;
        data_length = soldItems.data.length;
        page = soldItems.page;
        break;
      }
      case "owned": {
        total_count = ownItems.total_count;
        data_length = ownItems.data.length;
        page = ownItems.page;
        break;
      }
      case "created": {
        total_count = createdItems.total_count;
        data_length = createdItems.data.length;
        page = createdItems.page;
        break;
      }

      case "favourite": {
        total_count = favoritedItems.total_count;
        data_length = favoritedItems.data.length;
        page = favoritedItems.page;
        break;
      }

      case "activity": {
        total_count = activityItems.total_count;
        data_length = activityItems.data.length;
        page = activityItems.page;
        break;
      }

    }
    if (
      window.innerHeight + document.documentElement.scrollTop + 400 >=
      document.scrollingElement.scrollHeight
    ) {
      if (data_length) {
        if (!loading) {
          if (total_count != data_length) {
            inialiseTabData(match.params.tab, page);
          } else {
          }
        }
      }
    }
  }, [loadMore]);

  useEffect(() => {
    window.addEventListener("scroll", setLoadMore);
    return () => window.removeEventListener("scroll", setLoadMore);
  }, []);

  return (
    <>
      <section className="profile-activity profileactivity position-relative">
        <div className="container-fluid p-0">
          <div className="bg-imgae profile-bs">
            <div className="bg-grey w-100  bg-light d-inline-block">
              <img
                className="w-100"
                src={
                  authState.cover_img
                    ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.cover_img}`
                    : profile_placeholder
                }
              />
            </div>
            <div className="input-edit">
              <input type="file" accept="image/*" onChange={(e) => onUpdateProfileBanner(e.target.files[0])} />
              {state.loading_banner_icon ?
                '' :
                <button className="btn banner-button primery-button px-lg-4">Edit Cover</button>
              }
            </div>
            {state.loading_banner_icon &&
              <Spinner />}
          </div>
        </div>
      </section>
      <section className="activity-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 position-relative">
              <div className="profile-image top-part text-center d-flex justify-content-center align-items-center ">
                <div className="imgesss  bg-light text-center">
                  <div className="icon-img position-relative">
                    <img
                      className="border border-4 border-white"
                      src={
                        authState.profile_pic
                          ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}`
                          : profile_placeholder
                      }
                    />
                    <div className="camera-img position-absolute">
                      <label htmlFor="filBanner">
                        <a className="btn pe-0">
                          <i
                            className={`fas ${state.loading_profile_icon
                              ? "fa-spinner"
                              : "fa-camera"
                              } bg-light border border-2  rounded-circle p-1`}
                          ></i>
                        </a>
                      </label>
                      <input
                        className="d-none"
                        type="file"
                        id="filBanner"
                        accept="image/*"
                        onChange={(e) =>
                          onUpdateProfilePicture(e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-inline pt-5 text-center">
                <h4 className=" mt-3  mb-0">
                  {state.display_name}
                </h4>
                <p className="p-color">@{state.user_name}</p>
                <div className="left-icon bg-white d-flex justify-content-center vw-50 mt-lg-3 mb-lg-3 m-auto mb-3">
                  <Link
                    className="p-2 bg-white text-muted m-0 border-start text-muted fs-6 border-end-0 border fs-6 btn like-icon"
                    to={`/profile/edit`}
                  >
                    <i className="fas fa-pencil "></i>
                  </Link>
                  <button
                    className="btn border border-top border-bottom  px-3 shadow-none  report-icon"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                  </button>
                  <ul
                    className="dropdown-menu p-2 share-dropdown dropdown-menu-end share"
                    aria-labelledby="dropdownMenu2"
                  >
                    <p className="fw-semi-bold text-center">Share Link</p>
                    <div>
                      <ul className="d-flex ps-0 share-drop">
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`https://www.twitter.com/share?url=https://${window.location.origin
                              }${`profile/${match.params.id}/created`}`}
                            target="_blank"
                          >
                            <span>
                              <img src={Twitter} />
                            </span>
                            <p>Twitter</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin
                              }${`profile/${match.params.id}/created`}`}
                            target="_blank"
                          >
                            <span>
                              <img src={Facebook} />
                            </span>
                            <p>Facebook</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href={`mailto:info@example.com?subject=V3nus NFTs&body=https://${window.location.origin
                              }${`profile/${match.params.id}/created`}:`}
                          >
                            <span>
                              <img src={Email} />
                            </span>
                            <p>Email</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-center"
                            href="#"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.origin}${`/profile/created`}`
                              );
                              toast.success("Copied to clipboard");
                            }}
                          >
                            <span>
                              <img src={Copy} />
                            </span>
                            <p>Copy</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
                <div className="folloers border d-flex justify-content-center align-items-center w-75 m-auto border-end-0 border-start-0">
                  <div className="five-nine me-4 lh-sm">
                    <p className="text-start mb-0 fs-6 pt-3 text-center">
                      {state.total_following
                        ? state.total_following
                        : 0}
                    </p>
                    <p className="fs-6 text-muted">
                      <small className="btn" data-bs-toggle="modal" type="button" data-bs-target={state.total_following ? "#exampleModalFollowing" : ''}>Following</small>
                    </p>
                  </div>
                  <div className="five-nine me-2 lh-sm me-2">
                    <p className="text-start mb-0 fw-bold pt-3 fs-6 text-center">
                      {state.total_followers
                        ? state.total_followers
                        : 0}
                    </p>
                    <p className="text-muted fs-6 btn" data-bs-toggle="modal" data-bs-target={state.total_followers ? "#exampleModal420" : ''} type="button">Followers</p>
                  </div>
                </div>
                <div className="bio">
                  <p className="text-muted mb-0 mt-lg-3">Bio</p>
                  <p className="fs-6 p-3 pt-0">
                    <small className="bio-profile">
                      {showMore
                        ? `${state.bio}`
                        : `${state.bio?.substring(0, 100) + "...."}`}
                      {state.bio?.length > 100 ? (
                        <button
                          className="btn-readmore"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Read less" : "Read more"}
                        </button>
                      ) : (
                        ""
                      )}
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="on-salle pb-5 mb-lg-5">
                <ul
                  className="nav nav-pills mb-4 border-bottom mt-lg-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "on_sale" ? "active" : ""
                        } text-secondary fs-6  bg-transparent  `}
                      id="pills-onsale-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-onsale"
                      type="button"
                      role="tab"
                      aria-controls="pills-onsale"
                      aria-selected="true"
                      onClick={() => navigate("/profile/on_sale")}
                    >
                      On Sale
                      <small className="light-color px-1 p-color ms-1">
                        {onSaleItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "sold" ? "active" : ""
                        } text-secondary fs-6  bg-transparent `}
                      id="pills-sold-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-sold"
                      type="button"
                      role="tab"
                      aria-controls="pills-sold"
                      aria-selected="false"
                      onClick={() => navigate("/profile/sold")}
                    >
                      Sold
                      <small className="light-color px-1 p-color ms-1">
                        {soldItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "owned" ? "active" : ""
                        } text-secondary fs-6  bg-transparent `}
                      id="pills-owned-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-owned"
                      type="button"
                      role="tab"
                      aria-controls="pills-owned"
                      aria-selected="false"
                      onClick={() => navigate("/profile/owned")}
                    >
                      Owned
                      <small className="light-color px-1 p-color ms-1">
                        {ownItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "created" ? "active" : ""
                        } text-secondary fs-6 bg-transparent `}
                      id="pills-created-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-created"
                      type="button"
                      role="tab"
                      aria-controls="pills-created"
                      aria-selected="false"
                      onClick={() => navigate("/profile/created")}
                    >
                      Created{" "}
                      <small className="light-color p-color px-1">
                        {createdItems.total_count}
                      </small>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link text-secondary fs-6 bg-transparent `}
                      id="pills-collection-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-collection"
                      type="button"
                      role="tab"
                      aria-controls="pills-collection"
                      aria-selected="false"
                      onClick={() => initialiseCollectionNfts()}
                    >
                      Collections{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "favourite" ? "active" : ""
                        } text-secondary fs-6 bg-transparent `}
                      id="pills-favorited-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-favorite"
                      type="button"
                      role="tab"
                      aria-controls="pills-favorite"
                      aria-selected="false"
                      onClick={() => navigate("/profile/favourite")}>
                      Favorite
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${match.params.tab == "activity" ? "active" : ""
                        } text-secondary fs-6  bg-transparent `}
                      id="pills-activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => navigate("/profile/activity")}
                    >
                      Activity
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade  ${match.params.tab == "on_sale" ? "show active" : ""
                      }`}
                    id="pills-onsale"
                    role="tabpanel"
                    aria-labelledby="pills-onsale-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {onSaleItems.data.length ? (
                          onSaleItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "sold" ? "show active" : ""
                      }`}
                    id="pills-sold"
                    role="tabpanel"
                    aria-labelledby="pills-sold-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {soldItems.data.length ? (
                          soldItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "owned" ? "show active" : ""
                      }`}
                    id="pills-owned"
                    role="tabpanel"
                    aria-labelledby="pills-owned-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {ownItems.data.length ? (
                          ownItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "created" ? "show active" : ""
                      }`}
                    id="pills-created"
                    role="tabpanel"
                    aria-labelledby="pills-favorited-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {createdItems.data.length ? (
                          createdItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "favourite" ? "show active" : ""
                      }`}
                    id="pills-favorite"
                    role="tabpanel"
                    aria-labelledby="pills-created-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        {favoritedItems.data.length ? (
                          favoritedItems.data.map((res, index) => {
                            return res.auction_type === 1 || res.auction_type === 2 ? (
                              <LiveFixedCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                removeToFev={removeToFev}
                                addToFev={addToFev}
                                col="col-md-4 mb-3"
                              />
                            ) : (
                              <LiveAuctionsCompo
                                key={res._id}
                                {...res}
                                usdPrice={usdPrice}
                                currentUser={authState._id}
                                index={index}
                                authDispatch={authDispatch}
                                removeToFev={removeToFev}
                                addToFev={addToFev}
                                col="col-md-4 mb-3"
                              />
                            );
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "collection" ? "show active" : ""
                      }`}
                    id="pills-collection"
                    role="tabpanel"
                    aria-labelledby="pills-collection-tab"
                  >
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-md-4 mb-4">
                          <div className="collection-box">
                            <button
                              className="orange-btn btn pe-4 ps-4 btn-light me-lg-3 ms-lg-3 Create-btn shadow-none my-lg-0 my-2"
                              data-bs-toggle="modal"
                              data-bs-target="#CreateCollectionModal"
                            >
                              CREATE A COLLECTION
                            </button>
                            <button
                              className="orange-outline-btn white-btn btn pe-4 ps-4 btn-light me-lg-3 ms-lg-3  Create-btn shadow-none my-lg-0 my-2"
                              data-bs-toggle="modal"
                              data-bs-target="#ImportCollectionModal"
                            >
                              IMPORT EXISTING
                            </button>
                          </div>
                        </div>
                        {Array.isArray(collectionNfts.data) &&
                          collectionNfts.data.map((res) => {
                            return <div key={res._id} className="col-md-4 mb-4">
                              <Link to={`/collection/${res._id}`}>
                                <div className="collections">
                                  <img
                                    src={res.img_url ? String(res.img_url).includes("https://") ? res.img_url : `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.img_url}` : profile_placeholder}
                                    className="col-img" />
                                  <p className="col-name">{res.name.length > 15 ? `${res.name?.slice(0, 8)}...` : res.name}</p>
                                </div>
                              </Link>
                            </div>
                          })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${match.params.tab == "activity" ? "show active" : ""
                      }`}
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="pills-activity-tab"
                  >
                    <div className="nft-activity mt-lg-4">
                      <div className="profile-name bg-light py-2 rounded-3 mb-2 mt-4">
                        {activityItems.data?.length ? (
                          activityItems.data?.map((res) => {
                            return <Activity key={res._id} {...res} />;
                          })
                        ) : (
                          <div className="imge-placeholder text-center">
                            <img className="w-100 no-data-found" src={placeholder_image} />
                            <p className="mt-2 ms-lg-5"> No result found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateCollection />
      </section>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModalFollowing" tabindex="-1" aria-labelledby="exampleModalLabel22" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-lg-25 modal-dialog-scrollable">
          <div className="modal-content">
            <div className="text-end p-2 pb-0 closee--custom ">
              <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-header justify-content-center pt-0 headingg-modall">
              <h5 className="modal-title" id="exampleModalLabel22">Following</h5>
            </div>
            <div className="modal-body modall-heght">
              <div className='border-bottom'>
                <ul className="p-0">
                  {Array.isArray(followings.data) && followings.data.map((res, index) => {
                    return <FollowUserListCompo index={index} key={res._id} {...res} myProfile={true} onClickAction={followUnFollow} type="followings" authState={authState} />
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal-second --> */}
      <div className="modal fade" id="exampleModal420" tabindex="-1" aria-labelledby="exampleModalLabel420" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-lg-25 modal-dialog-scrollable">
          <div className="modal-content">
            <div className="text-end p-2 pb-0 closee--custom">
              <button type="button" className="btn-close pb-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-header justify-content-center pt-0 headingg-modall">
              <h5 className="modal-title" id="exampleModalLabel420">Followers</h5>
            </div>
            <div className="modal-body modall-heght">
              <div className="horiontal">
                <div className="border-bottom mb-3">
                  {Array.isArray(followers.data) && followers.data.map((res, index) => {
                    return <FollowUserListCompo index={index} key={res._id} {...res} myProfile={true} onClickAction={followUnFollow} type="followers" authState={authState} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};




