import React, { Component, useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { FIXED_PRICE_AUCTION_ID, TIMED_AUCTION_AUCTION_ID, UNLIMITED_AUCTION_AUCTION_ID } from '../context/actionTypes';
import { GlobalContext } from '../context/Provider';

export default (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { collectionData } = useContext(GlobalContext)

    const [maxPrice, setMaxPrice] = useState(0)
    const [minPrice, setMinPrice] = useState(0)
    const filters = [
        { name: "Buy It Now", value: "BUY_IT_NOW" },
        { name: "Live Auctions", value: "LIVE_AUCTIONS" },
    ]
    const onFilterApply = (name, value) => {
        if (query.has(name)) {
            let queryValue = query.get(name)
            // if (queryValue == value) {
            if (value == "") {
                query.delete(name)
            } else {
                query.set(name, value)
            }
        } else {
            query.set(name, value)
        }
        navigate({ search: query.toString() }, { replace: true })
    }
    const enableDisablePrice = (b) => {
        if (b) {
            query.delete("min_price")
            query.delete("max_price")
            setMinPrice(0)
            setMaxPrice(0)
        } else {
            query.set("min_price", minPrice)
            query.set("max_price", maxPrice)
        }
        navigate({ search: query.toString() })
    }
    const { name } = props

    return <div className="d-lg-flex justify-content-between pb-lg-2">
        <div className="explore-btns">
            <div className="btnss-cat d-lg-flex text-sm-center text-center mb-sm-2 mb-2">
                <h2 className="me-3 w-md-100">{name}</h2>
                <div className="dropdown">
                    <button className="trant-btn text-truncate btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4 pe-lg-2 px-sm-3 py-sm-2 me-sm-2 mb-sm-3 mb-lg-0 mb-2 text-truncate min-width" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        {
                            (collectionData.data.filter(res => res._id == query.get("collection_id"))).length ? (collectionData.data.filter(res => res._id == query.get("collection_id")))[0].name
                                : 'Collection'
                        }</button>
                    <ul className="dropdown-menu share-icons text-truncate" aria-labelledby="dropdownMenuCollectionFilter">
                        {Array.isArray(collectionData.data) && collectionData.data.map((res, index) => {
                            return <li key={res._id}>
                                <button className="dropdown-item text-truncate" type="button" onClick={() => onFilterApply("collection_id",
                                    res._id)}>{res.name}
                                </button>
                            </li>
                        })}
                        {query.has("collection_id") && <>
                            <hr />
                            <li className='text-center mb-1 text-truncate'><button className=" btn btn-outline-primary btn-sm px-4 ms-2" type="button" onClick={() => onFilterApply("collection_id", "")}>CLEAR</button></li>
                        </>}
                    </ul>
                </div>

                <div className="dropdown">
                    <button className={`trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4 position-relative
                     dropdown-toggle pe-lg-2 px-sm-3 py-sm-2 me-sm-2 mb-sm-3 ${query.has("sale_type") ? '' : ''}`}
                        id="saleTypeDropdown" data-bs-toggle="dropdown">{query.has("sale_type") ? query.get("sale_type")
                            == FIXED_PRICE_AUCTION_ID ? 'Fixed Auction' : query.get("sale_type") == UNLIMITED_AUCTION_AUCTION_ID ?
                            'Unlimited Auction' : 'Timed Auction' : 'Sale Type'}
                        <p className='sallers position-absolute'>Sale</p>
                    </button>

                    <ul className="dropdown-menu share-icons" aria-labelledby="saleTypeDropdown">
                        <li key={'removesaletype1'} className="cursor-pointer "><a className="dropdown-item" onClick={() => onFilterApply("sale_type", FIXED_PRICE_AUCTION_ID)}>Fixed Auction</a></li>
                        <li key={'removesaletype3'} className="cursor-pointer"><a className="dropdown-item" onClick={() => onFilterApply("sale_type", UNLIMITED_AUCTION_AUCTION_ID)}>Unlimited Auction</a></li>
                        <li key={'removesaletype2'} className="cursor-pointer"><a className="dropdown-item" onClick={() => onFilterApply("sale_type", TIMED_AUCTION_AUCTION_ID)}>Timed Auction</a></li>
                        {query.has("sale_type") && <>
                            <hr />
                            <li className='text-center'>
                                <button key={'removesaletype3'} type="button" className="btn btn-outline-primary btn-sm px-4 ms-2" onClick={() => onFilterApply("sale_type", "")}>CLEAR</button></li>
                        </>
                        }
                    </ul>
                </div>
                <div className="dropdown">
                    <button className={`trant-btn btn btn-outline-dark Create-btn me-lg-3 pe-lg-4 ps-lg-4  
                      pe-lg-2 px-sm-3 py-sm-2 me-sm-2  mt-md-0 mt-2  ${query.has("min_price") ? '' : ''}`}
                        id="dropdownMenuPriceFilter" data-bs-toggle="dropdown"> {query.has("min_price") ? `${query.get("min_price")} 
                       ETH - ${query.get("max_price")} ETH` : 'Price Range'}</button>
                    <ul className="dropdown-menu common-dropdown p-0 filters range-price" aria-labelledby="dropdownMenuPriceFilter">
                        <li><span className="fw-medium py-1 px-4 my-2 d-flex align-items-center" >
                            <input type="number" className="form-control me-2 border-pink" min={0} value={minPrice} placeholder="From" onChange={(e) => setMinPrice(e.target.value)} />
                            <input type="number" className="form-control border-pink" min={0} value={maxPrice} placeholder="To" onChange={(e) => setMaxPrice(e.target.value)} />
                        </span></li>
                        <li className="d-flex px-4 py-3 border-top justify-content-center ">
                            <button type="button" className="btn btn-outline-primary me-1 cler-aply px-4 " onClick={() => enableDisablePrice(true)}>CLEAR</button>
                            <button type="button" className="btn btn-primary fs-12 px-4 cler-aply" onClick={() => enableDisablePrice(false)}>APPLY</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="text-sm-center text-center mb-sm-2 mb-2">
            <div className="dropdown">
                <button className="trant-btn btn btn-light Create-btn pe-4 ps-4 dropdown-toggle shadow-none" id="sortDropdown" data-bs-toggle="dropdown"
                    aria-expanded="false">{(filters.filter(res => res.value == query.get("filter_by"))).length ? (filters.filter(res => res.value == query.get("filter_by")))[0].name
                        : 'All'}</button>
                <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                    {query.has("filter_by") &&
                        <li className="cursor-pointer"><a className="dropdown-item" onClick={() => onFilterApply("filter_by", "")}>All</a></li>
                    }
                    {filters.map(res => {
                        return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => onFilterApply("filter_by", res.value)}>{res.name}</a></li>
                    })}
                    {/* {query.has("filter_by") &&
                        <li className="text-center py-2 border-top ">
                            <button className=" btn btn-outline-primary btn-sm px-4" type="button" onClick={() => onFilterApply("filter_by", "")}>CLEAR
                            </button>
                        </li>} */}
                </ul>
            </div>
        </div>
    </div>
}