import React from 'react'
import moment from "moment";
import henceforthApi from "../utils/henceforthApi";
import profile_placeholder from "./../assets/images/view_profile.svg";

const activity = ({ nft_id, created_at, message }) => {
  return <div className=" ms-3 d-flex justify-content-start align-items-center shadow-profile table-scroll-userpage">
    <div className="profile-image nft-nameimg bg-secondary me-3 profile-rounded">
      <div className='video-sizing'>
        {nft_id?.file_ext?.includes("video") ? <video className='video-activity' autoPlay loop src={henceforthApi.FILES.video(nft_id?.file_url)}></video> : <img className="w-100" src={nft_id?.file_url ? henceforthApi.FILES.imageSmall(nft_id?.file_url) : profile_placeholder} />}
      </div>
    </div>
    <div>
      <p className="mb-0 color-red">
        <strong>{nft_id?.name ? nft_id?.name : "Not available"}</strong>
      </p>
      <div className='d-flex align-items-center'>
        <span className='dottedd'></span><h6 className='meage'>{message}</h6>
      </div>
      <p className="text-muted mb-0">
        <small>{moment(Number(created_at)).format("MMM DD, YYYY")}</small>
      </p>
    </div>
  </div>
}

export default activity
