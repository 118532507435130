import logo from "../assets/images/other/acrialogo.svg"
import profile_placeholder from "./../assets/images/view_profile.svg";
import placeholder_image from "../assets/images/no-data-found.svg";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ERC721_CONTRACT_ADDRESS_KEY, GlobalContext, paybleAmount } from "../context/Provider";
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";
import henceforthApi from "../utils/henceforthApi";
import moment from "moment";
import { ipfsGetFileUrl } from "../utils/henceforthIPFS";
import { toast } from "react-toastify";
import {
    DEFAULT_COLLECTION_ID,
    EXPIRE_1_DAY,
    EXPIRE_3_DAY,
    EXPIRE_5_DAY,
    FIXED_PRICE_AUCTION_ID,
    PICK_SPECIFIC_DATE,
    RIGHT_AFTER_LISTING,
    TIMED_AUCTION_AUCTION_ID,
    UNLIMITED_AUCTION_AUCTION_ID,
    WALLET_ADDRESS_SLICE_FROM,
} from "../context/actionTypes";
import MarketDetailsPlayer from "../components/MarketDetailsPlayer";
import { Spinner } from "../components/BootstrapCompo";
import Modals from "./Modals";
import { BigNumber, ethers } from "ethers";
import Graphs from "../components/Graphs/index";

var countdownTimer;
export default () => {
    const navigate = useNavigate();
    const location = useLocation();
    const match = useMatch("/content/:id/:tab/:graph_type");
    const {
        authState,
        accounts,
        loading,
        balanceInEth,
        setLoading,
        usdPrice,
        createVoucher,
        finaliseFixedPriceAuction,
        bidOnTimedAuction,
        finaliseERC721Auction,
        scrollToTop,
        wethStep,
        setApproveFun,
        feePercentGloble,
        wethPrice
    } = useContext(GlobalContext);
    const [media_url, setMediaUrl] = useState("");
    const [reportText, setReportText] = useState("");
    const [bidAmount, setBidAmount] = useState("");
    const [bidTitle, setBidTitle] = useState("");
    const [bidDescription, setBidDescription] = useState("");
    const [resaleAmount, setResaleAmount] = useState("");
    const [isExpire, setIsExpire] = useState(false);
    const [timerCount, setTimerCount] = useState({
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
        title: "",
    });
    const [startDate, setStartDate] = useState(RIGHT_AFTER_LISTING)
    const [endDate, setEndDate] = useState(EXPIRE_1_DAY)
    const [startSpecificDate, setStartSpecificDate] = useState("")
    const [endSpecificDate, setEndSpecificDate] = useState("")
    const [removeFromSaleLoading, setRemoveFromSaleLoading] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [is_favourite, setIsFavourite] = useState(false);
    const [showMore, setShowMore] = useState(false)
    const [buyLoading, setBuyLoading] = useState(false)
    const [bidOfferLoading, setBidOfferLoading] = useState(false)
    const [putOnSaleLoading, setPutOnSaleLoading] = useState(false)
    const [bidsData, setBidsData] = useState({
        bids: [],
        total_count: 0,
        page: 0
    })
    const [tradingHistory, setTradingHistory] = useState({
        total_count: 0,
        trading_history: [],
        page: 0
    })
    const [priceHistory, setPriceHistory] = useState({
        data: []
    })
    const [state, setState] = useState({
        description: "",
        end_date: "",
        file_url: "",
        file_ext: "",
        video_url: "",
        name: "",
        min_bid: "",
        royalties: "",
        start_date: "",
        created_at: "",
        tags: [],
        creator_address: "",
        type: "",
        owner_address: "",
        price: "",
        product_id: "",
        unlockable: "",
        created_by: {
            profile_pic: "",
            first_name: "",
            last_name: "",
            user_name: "",
            _id: "",
        },
        highest_bidder: {
        },
        bids_placed_by: [],
        bid_id: null,
        current_owner: {
            user_name: "",
            _id: "",
        },
        is_sold: null,
        order_id: "",
        trading_hstory: [],
        graph_data: null,
    });
    henceforthApi.setToken(authState.access_token);
    const initialiseInterval = (end_date, title) => {
        if (end_date) {
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var end = new Date(Number(end_date));
            function timer() {
                var now = new Date();
                var distance = end - now;
                if (distance < 0) {
                    clearInterval(countdownTimer);
                    setIsExpire(true);
                    return;
                }
                var days = Math.floor(distance / _day);
                var hours = Math.floor((distance % _day) / _hour);
                var minutes = Math.floor((distance % _hour) / _minute);
                var seconds = Math.floor((distance % _minute) / _second);
                setTimerCount({
                    ...timerCount,
                    days,
                    hours,
                    minutes,
                    seconds,
                    title,
                });
            }
            countdownTimer = setInterval(() => timer(), 1000);
        }
    };

    const changeGraphType = async (type) => {
        let params = match.params
        navigate({ pathname: `/content/${params.id}/${params.tab}/${type}` }, { replace: true })
    }
    const initialisePriceHistory = async () => {
        try {
            let apiRes = await henceforthApi.NFT2.getTradingPriceById(match.params.id, match.params.graph_type)
            setPriceHistory({
                ...priceHistory,
                ...apiRes.data
            })
        } catch (error) {
        }
    }
    const initialiseTradingHistory = async () => {
        try {
            let apiRes = await henceforthApi.NFT2.getTradingHistoryById(match.params.id)
            setTradingHistory({
                ...tradingHistory,
                ...apiRes
            })
            await initialisePriceHistory()
        } catch (error) {
        }
    }
    const initialiseFev = async () => {
        setIsFavourite(!is_favourite);
        try {
            let items = {
                nft_id: match.params.id,
                language: "ENGLISH",
            };
            let apiRes;
            if (is_favourite) {
                apiRes = await henceforthApi.NFT.removeFromFev(items);
            } else {
                apiRes = await henceforthApi.NFT.addToFev(items);
            }
        } catch (error) { }
    };
    const inialiseBids = async (page) => {
        try {
            let apiRes = await henceforthApi.NFT2.getBids(match.params.id, page)
            let data = apiRes.data
            setBidsData({
                ...bidsData,
                ...data,
                page: page + 1
            })
        } catch (error) {
        }
    }

    const initialiseFromDb = async () => {
        let params = match.params;
        let id = params.id;
        if (id) {
            try {
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let apiRes = await henceforthApi.NFT2.getById(
                    match.params.id,
                    timezone
                );
                if (
                    moment(Number(apiRes.data.start_date)).utc().valueOf() >
                    moment().utc().valueOf()
                ) {
                    initialiseInterval(apiRes.data.start_date, "Auction starts in");
                } else {
                    initialiseInterval(apiRes.data.end_date, "Auction ends in");
                }
                let media_url = await ipfsGetFileUrl(apiRes.data.file_url);
                setMediaUrl(media_url);
                setState({
                    ...state,
                    ...apiRes.data,
                    media_url,
                });
                setIsFavourite(apiRes.data.is_favourite);
                closeModals()
                await inialiseBids(0)
            } catch (error) {
            }
        } else {
            toast.warn("ID not found, Please visit explore page.");
        }
    };

    const closeModals = () => {
        let makeAnOfferModalCloseModal = document.getElementById("makeAnOfferModalCloseModal")
        if (makeAnOfferModalCloseModal) {
            makeAnOfferModalCloseModal.click()
        }
        let bidCloseModal = document.getElementById("bidCloseModal")
        if (bidCloseModal) {
            bidCloseModal.click()
        }
    }

    useEffect(() => {
        initialiseFromDb()
    }, [match.params.id])

    useEffect(() => {
        if (match.params.tab == "bids") {
            inialiseBids(0)
        } else if (match.params.tab == "trading") {
            initialiseTradingHistory(0)
        }
    }, [match.params.tab])

    useEffect(() => {
        initialisePriceHistory();
    }, [match.params.graph_type]);

    const purchaseNFT = async () => {
        if (authState.access_token) {
            try {
                setBuyLoading(true)
                let _etherPrice = ethers.utils.parseEther(Number(state.price).toFixed(18));
                let _token_id = BigNumber.from(state.token_id)
                let _tokenContract = state.collection_id ? state.collection_id.address : ERC721_CONTRACT_ADDRESS_KEY
                const voucher = [_token_id, _etherPrice, Number(state.auction_type), 1, 0, state.salt ? Number(state.salt) : 0]
                let contractRes = await finaliseFixedPriceAuction(String(state.owner_address).toLocaleLowerCase(), voucher, state.signature, String(_tokenContract).toLocaleLowerCase(), state.price);
                if (contractRes) {
                    let { commission, ...props } = contractRes
                    try {
                        let items = {
                            _id: match.params.id,
                            commission,
                            language: "ENGLISH",
                        };
                        let apiRes = await henceforthApi.NFT2.buy(items);
                        setState({
                            ...state,
                            ...apiRes.nft_details,
                        });
                        await initialiseTradingHistory()
                        hidemodals();
                        setBuyLoading(false)
                        toast.success(apiRes.message);
                    } catch (error) {
                        setBuyLoading(false)
                        toast.error(error.error_description);
                    }
                } else {
                    setBuyLoading(false)
                }
            } catch (error) {
                setBuyLoading(false)
            }
        } else {
            setBuyLoading(false)
            hidemodals();
            toast.warn("You must login first.");
            navigate(`/?signin=true&redirect=${location.pathname}`);
        }
    }

    const closeAllModal = () => {
        const fixedPriceAuctionCloseModal = document.getElementById("fixedPriceAuctionCloseModal")
        if (fixedPriceAuctionCloseModal) {
            fixedPriceAuctionCloseModal.click()
        }
        const unlimitedAuctionCloseModal = document.getElementById("unlimitedAuctionCloseModal")
        if (unlimitedAuctionCloseModal) {
            unlimitedAuctionCloseModal.click()
        }
        const timedAuctionCloseModal = document.getElementById("timedAuctionCloseModal")
        if (timedAuctionCloseModal) {
            timedAuctionCloseModal.click()
        }
    }

    const putOnSale = async (items, auction_type, endTime) => {
        debugger
        setPutOnSaleLoading(true)
        let _tokenContract = state.collection_id ? state.collection_id.address : ERC721_CONTRACT_ADDRESS_KEY
        try {
            let auctionValue = auction_type == 1 ? FIXED_PRICE_AUCTION_ID : auction_type == 2 ? UNLIMITED_AUCTION_AUCTION_ID : TIMED_AUCTION_AUCTION_ID
            let approveMarket = await setApproveFun(_tokenContract)
            if (approveMarket) {

                let nftRes = await createVoucher(resaleAmount, auction_type, _tokenContract, endTime ? endTime : 0);
                let apiItems = { ...items, ...nftRes, type: auctionValue }
                delete apiItems.owner_address
                let apiRes = await henceforthApi.NFT2.putOnSale(apiItems)
                setState({
                    ...state,
                    ...apiRes.put_on_sale
                })
            }
            closeAllModal()

            setResaleAmount("")
            setPutOnSaleLoading(false)
            initialiseFromDb()
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.body &&
                error.response.body.error_description
            ) {
                toast.error(error.response.body.error_description);
            } else {
                toast.error(JSON.stringify(error?.message));
            }
            setPutOnSaleLoading(false)
            closeAllModal()
        }
    }
    const reCreateFixedNft = async (auction_type) => {
        if (!resaleAmount) {
            return toast.warn("please enter price");
        }
        let items = {
            _id: match.params.id,
            start_date: 0,
            language: "ENGLISH"
        }
        await putOnSale(items, auction_type, 0)
    };

    const reCreateAcutionNft = async () => {
        let startDateTemp = startDate;
        let endDateTemp = endDate;
        if (startDateTemp == RIGHT_AFTER_LISTING) {
            startDateTemp = moment.utc().valueOf();
        } else {
            startDateTemp = Number(startSpecificDate);
        }
        if (endDateTemp == EXPIRE_1_DAY) {
            endDateTemp = moment().add(1, "days").utc().valueOf();
        } else if (endDateTemp == EXPIRE_3_DAY) {
            endDateTemp = moment().add(3, "days").utc().valueOf();
        } else if (endDateTemp == EXPIRE_5_DAY) {
            endDateTemp = moment().add(3, "days").utc().valueOf();
        } else {
            endDateTemp = Number(endSpecificDate);
        }
        if (!startDateTemp) {
            setLoading(false);
            return toast.warn("Please select start date");
        }
        if (!endDateTemp) {
            setLoading(false);
            return toast.warn("Please select end date");
        }
        if (Number(resaleAmount) < 0) {
            setLoading(false);
            return toast.warn("Invalid Minimum Bid value");
        }
        let items = {
            _id: match.params.id,
            start_date: startDateTemp,
            language: "ENGLISH"
        }
        await putOnSale(items, 3, endDateTemp)
    };
    const onPlaceABid = async (bid_amount, title, description) => {
        debugger
        if (!bid_amount) {
            return toast.warn("Invalid amount")
        }
        if (title === 'Make Offer') {
            if (bidAmount <= state?.price) {
                return toast.warn(`Offer amount should be greater then ${state?.price}`)
            }
        } else {
            if (bidAmount <= state?.price) {
                return toast.warn("Bid amount should be greater then price")
            }
            if (bidAmount <= state.highest_bidder?.bid_amount) {
                return toast.warn("Amount should be greater then highest bid")
            }
        }

        if (state.highest_bidder?.bid_placed_by?._id === authState?._id) {
            return toast.warn("You already have a highest bid")
        }
        if (authState.access_token) {
            if (state.current_owner && state.current_owner._id == authState._id) {
                return toast.warn("You can't bid on your own NFT.");
            }
            try {
                setBidOfferLoading(true)
                setBidTitle(title)
                setBidDescription(description)
                let contractRes = await bidOnTimedAuction(bid_amount, feePercentGloble)
                debugger
                if (contractRes.commission !== undefined) {
                    let items = {
                        _id: match.params.id,
                        bid_amount: Number(bid_amount),
                        commission: Number(contractRes.commission),
                        language: "ENGLISH"
                    }
                    await henceforthApi.NFT2.bid(items)
                    await initialiseFromDb()
                }
                setBidAmount("")
            } catch (error) {
                console.log('onPlaceABid error', error);
            } finally {
                setBidOfferLoading(false)
            }
        } else {
            navigate(`/?signin=true&redirect=${location.pathname}`);
        }
    };

    const placeOrderAction = async (placeOrderAddress, placeOrderPrice, _id) => {
        setPlaceOrderLoading(true);
        try {
            let _etherPrice = ethers.utils.parseEther(Number(state.price).toFixed(18));
            let _token_id = BigNumber.from(state.token_id)
            let _end_date = Math.round(state.end_date / 1000)

            let _tokenContract = state.collection_id ? state.collection_id.address : ERC721_CONTRACT_ADDRESS_KEY
            const voucher = [_token_id, _etherPrice, Number(state.auction_type), 1, _end_date, state.salt ? Number(state.salt) : 0]
            let approveMarket = await setApproveFun(_tokenContract)
            if (approveMarket) {
                const finaliseERC721AuctionRes = await finaliseERC721Auction(
                    placeOrderAddress,
                    voucher,
                    state.signature,
                    String(_tokenContract).toLocaleLowerCase(),
                    placeOrderPrice
                )
                if (finaliseERC721AuctionRes) {
                    let items = {
                        _id,
                        language: "ENGLISH"
                    }
                    await henceforthApi.NFT2.acceptBidOffer(items)
                    await initialiseFromDb()
                    await initialiseTradingHistory()
                }
            }
            setPlaceOrderLoading(false);
        } catch (error) {
            setPlaceOrderLoading(false);
        }
    };
    const removeFromSale = async () => {
        if (countdownTimer) {
            clearInterval(countdownTimer)
        }
        setLoading(true);
        setRemoveFromSaleLoading(true);
        try {
            let apiRes = await henceforthApi.NFT2.removeFromSale(match.params.id);
            setState({
                ...state,
                ...apiRes.remove_from_sale,
            });
            setLoading(false);
            setRemoveFromSaleLoading(false);
        } catch (error) {
            setLoading(false);
            setRemoveFromSaleLoading(false);
            toast.error(error.response.body.error_description);
        }
    };
    const hidemodals = () => {
        let purchaseCloseModal = document.getElementById("purchaseCloseModal");
        if (purchaseCloseModal) {
            purchaseCloseModal.click();
        }
    };
    useEffect(() => {
        scrollToTop();
    }, []);

    const submitReport = async (e) => {
        e.preventDefault()
        if (reportText) {
            let items = {
                report_reason: reportText,
                _id: match.params.id,
                language: "ENGLISH"
            }

            try {
                let apiRes = await henceforthApi.NFT.report(items)
                let closeReportModal = document.getElementById("closeReportModal")
                if (closeReportModal) {
                    closeReportModal.click()
                }
                toast.success(apiRes?.message ? apiRes?.message : "Reported successfully")
                setReportText("")
            } catch (error) {
                if (error.response && error.response && error.response.body && error.response.body.error_description) {
                    toast.error(error.response.body.error_description)
                } else {
                }
            }
        }
    }

    return <section className="bid-page mt-5 pb-0 mb-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-12">
                    <div className="img-bid">
                        <MarketDetailsPlayer
                            file_ext={state.file_ext}
                            file_url={state.file_url}
                            media_url={media_url}
                        />
                    </div>
                    <div className="highlighted" id="checkoutHide">
                        {state.type ? (
                            state.type == FIXED_PRICE_AUCTION_ID ? (
                                state.current_owner._id !== authState._id &&
                                state.is_sold !== null && !state.is_sold &&
                                <button
                                    className="orange-btn btn w-100 Create-btn pe-4 ps-4 mt-4"
                                    data-bs-toggle="modal"
                                    data-bs-target="#buybid"                                    >
                                    Buy for {state.price} wETH
                                </button>

                            ) : state.type == UNLIMITED_AUCTION_AUCTION_ID ? (
                                state.current_owner._id !== authState._id &&
                                state.is_sold !== null && !state.is_sold &&
                                <button
                                    className="orange-btn btn w-100 Create-btn pe-4 ps-4 mt-4"
                                    data-bs-toggle="modal"
                                    data-bs-target="#makeAnOfferModal">
                                    Make an offer
                                </button>
                            ) :
                                state.is_sold !== null && !state.is_sold &&
                                <div className="row border rounded-3 p-4 me-0 ms-0 mt-lg-4 mt-5">
                                    <div className="col-md-6">
                                        {state.highest_bidder &&
                                            <div className="time-auticon">
                                                <h4 className="">
                                                    {state.highest_bidder.bid_amount ? " Highest bid by" : "Price"}
                                                    <span className="color-red"> {state.highest_bidder?.bid_placed_by?._id ? `@${state.highest_bidder?.bid_placed_by?.user_name ? state.highest_bidder?.bid_placed_by?.user_name : `${state.highest_bidder?.bid_placed_by?._id?.slice(
                                                        0,
                                                        WALLET_ADDRESS_SLICE_FROM
                                                    )}....${state.highest_bidder?.bid_placed_by?._id?.slice(
                                                        state.highest_bidder?.bid_placed_by?._id?.length -
                                                        WALLET_ADDRESS_SLICE_FROM,
                                                        state.highest_bidder?.bid_placed_by?._id?.length
                                                    )}`}` : state.current_owner?.user_name}</span>
                                                </h4>
                                                <div className="owner-name d-flex pt-2">
                                                    <div className="name-img">
                                                        <img src={state.highest_bidder?.bid_placed_by?._id ? state.highest_bidder?.bid_placed_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${state.highest_bidder?.bid_placed_by?.profile_pic}` : state.created_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${state.created_by?.profile_pic}` : profile_placeholder : profile_placeholder} />
                                                    </div>
                                                    <div className="name-text ms-2">
                                                        <h6 className="mb-0 fw-bolderr text-truncate">
                                                            {state.highest_bidder.bid_amount ? state.highest_bidder.bid_amount : state.price}
                                                        </h6>
                                                        <p className="text-black-50 mb-0">
                                                            ${Number(Number(state.highest_bidder.bid_amount ? state.highest_bidder.bid_amount : state.price) * usdPrice).toFixed(4)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="live-auticon-right">
                                            <h6 className="color-red fw-bold">
                                                {timerCount.title}
                                            </h6>
                                            <div className="times-ends">
                                                <ul className="d-flex justify-content-between ps-0 align-items-center mb-0">
                                                    <li>
                                                        <h4 className="sizing-hfour mb-0">
                                                            {String(timerCount.days).length == 1
                                                                ? `0${timerCount.days}`
                                                                : timerCount.days}
                                                        </h4>
                                                        <p className="mb-0 p-color">Days</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="sizing-hfour mb-0">
                                                            {String(timerCount.hours).length == 1
                                                                ? `0${timerCount.hours}`
                                                                : timerCount.hours}
                                                        </h4>
                                                        <p className="mb-0 p-color">Hour</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="sizing-hfour mb-0">
                                                            {String(timerCount.minutes).length == 1
                                                                ? `0${timerCount.minutes}`
                                                                : timerCount.minutes}
                                                        </h4>
                                                        <p className="mb-0 p-color">Min</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="sizing-hfour mb-0">
                                                            {String(timerCount.seconds).length == 1
                                                                ? `0${timerCount.seconds}`
                                                                : timerCount.seconds}
                                                        </h4>
                                                        <p className="mb-0 p-color">Sec</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {state.current_owner._id !== authState._id &&
                                        !state.is_sold ? (
                                        <div className="col-md-12 text-center pt-4">
                                            {moment(Number(state.end_date)).utc().valueOf() >
                                                moment().utc().valueOf() ? (
                                                <button
                                                    className="orange-btn btn w-100 Create-btn pe-lg-4 ps-lg-4"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#placeABidModal"
                                                    disabled={String(state?.highest_bidder?.owner_address).toLowerCase() === String(accounts[0]).toLowerCase()}
                                                >{String(state?.highest_bidder?.owner_address).toLowerCase() === String(accounts[0]).toLowerCase() ? 'You are the highest bidder' : 'Place a Bid'}</button>
                                            ) :
                                                <button
                                                    className="orange-btn btn w-100 Create-btn pe-4 ps-4"
                                                    disabled
                                                >
                                                    Time ended
                                                </button>
                                            }
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {state.current_owner._id == authState._id &&
                                        !state.is_sold ? (
                                        <div className="mt-4">
                                            <div className="highlighted d-lg-flex align-items-center justify-content-between">
                                                {isExpire ? (
                                                    <button
                                                        className="orange-btn btn w-100 Create-btn pe-4 ps-4 me-3"
                                                        disabled={
                                                            !bidsData.bids.length ||
                                                            placeOrderLoading
                                                        }
                                                        onClick={() => placeOrderAction(state.highest_bidder.owner_address, Number(state.highest_bidder.bid_amount), state.highest_bidder._id)}>
                                                        {placeOrderLoading ? <Spinner /> : "Place a Order"}
                                                    </button>
                                                ) :
                                                    <button className="orange-btn btn w-100 Create-btn pe-4 ps-4 me-3" disabled>Place a Order </button>}
                                                <button
                                                    className="trant-btn btn w-100 Create-btn pe-4 ps-4 ms-lg-3"
                                                    onClick={() => removeFromSale(state.order_id)}
                                                    disabled={removeFromSaleLoading}>
                                                    {removeFromSaleLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        "Remove from sale"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {state.current_owner._id == authState._id && (state.is_sold !== null && state.is_sold) ? (
                        <div className="mt-4">
                            <div className="highlighted d-lg-flex align-items-center justify-content-between my-2 my-lg-0">
                                <button
                                    className="orange-btn btn w-100 Create-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#fixedPriceAuctionModal">
                                    Fixed Auction
                                </button>
                                <button
                                    className="trant-btn btn w-100 Create-btn ms-1 me-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#unlimitedAuctionModal">
                                    Unlimited Auction
                                </button>
                                <button
                                    className="trant-btn btn w-100 Create-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#timedAuctionModal">
                                    Timed Auction
                                </button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {state.current_owner._id == authState._id &&
                        !state.is_sold &&
                        (state.type == FIXED_PRICE_AUCTION_ID || state.type == UNLIMITED_AUCTION_AUCTION_ID) ? (
                        <div className="mt-4">
                            <div className="highlighted d-lg-flex align-items-center justify-content-between">
                                <button
                                    className="trant-btn btn w-100 Create-btn pe-4 ps-4 ms-lg-3 "
                                    onClick={() => removeFromSale()}
                                    disabled={removeFromSaleLoading}>
                                    {removeFromSaleLoading ? <Spinner /> : "Remove from sale"}
                                </button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-lg-7 col-md-12">
                    <div className="right-side-live">
                        <div className="d-flex justify-content-between align-items-center mt-5 mt-lg-0">
                            <div>
                                <h2 className="text-truncate fs-3 search-word">{state.name}</h2>
                                <h6 className="fs-6 p-color">
                                    Minted on{" "}
                                    {moment(Number(state.created_at)).format("MMM DD, YYYY")}
                                </h6>
                            </div>
                            <div className="left-icon bg-white d-flex mt-lg-4 mb-lg-4 my-2">
                                <i
                                    className={`btn fa fa-heart like-icon p-2 bg-white m-0 border-start border-end-0 border fs-6 ms-1 ms-lg-0 ${is_favourite ? "text-danger" : ""
                                        }`}
                                    onClick={() => initialiseFev()}
                                ></i>
                                <div>
                                    <button
                                        className="btn border border-top border-bottom share-icon px-3 shadow-none"
                                        id="dropdownMenu2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                                    </button>
                                    <ul
                                        className="dropdown-menu p-2 share-dropdown dropdown-menu-end share share-link"
                                        aria-labelledby="dropdownMenu2"
                                    >
                                        <p className="fw-semi-bold text-center mb-1">Share Link</p>
                                        <div>
                                            <ul className="d-flex ps-0 share-drop justify-content-between">
                                                <li>
                                                    <a
                                                        className="dropdown-item text-center pb-0 "
                                                        href={`https://www.twitter.com/share?url=https://${window.location.origin
                                                            }${`profile/${match.params.id}/created`}`}
                                                        target="_blank"
                                                    >
                                                        <span>
                                                            <img src={Twitter} />
                                                        </span>
                                                        <p className="mb-1">Twitter</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item text-center pb-0"
                                                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin
                                                            }`}
                                                        target="_blank"
                                                    >
                                                        <span>
                                                            <img src={Facebook} />
                                                        </span>
                                                        <p className="mb-1">Facebook</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item text-center pb-0"
                                                        href={`mailto:info@example.com?subject=Acriamarketplace&body=https://${window.location.origin
                                                            }${`profile/${match.params.id}/created`}:`}
                                                    >
                                                        <span>
                                                            <img src={Email} />
                                                        </span>
                                                        <p className="mb-1">Email</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item text-center pb-0"
                                                        href="#"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                `${window.location.origin
                                                                }${`/profile/created`}`
                                                            );
                                                            toast.success("Copied to clipboard");
                                                        }}
                                                    >
                                                        <span>
                                                            <img src={Copy} />
                                                        </span>
                                                        <p className="mb-1">Copy</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <button
                                        className="btn border border-top border-bottom report-icon px-3 shadow-none br-rounded me-lg-2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-ellipsis-h text-muted fs-6"></i>
                                    </button>
                                    <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                                        <li>
                                            <button
                                                className="dropdown-item fw-semi-bold text-center fs-14"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#Reportmodal"
                                            >
                                                Report
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h6>
                            {showMore ? `${state.description}` : `${state.description.slice(0, 100)}...`}
                            {state.description.length > 100 ? <button className="btn-readmore" onClick={() => setShowMore(!showMore)}>{showMore ? "Read less" : "Read more"}</button> : ""}
                        </h6>
                        <div className="three-img d-lg-flex mt-2">
                            <div className="owner-name d-flex align-items-center pt-2">
                                <div className="name-img">
                                    <img src={state.created_by.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.created_by.profile_pic}`
                                        : profile_placeholder} />
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0"> creator</p>
                                    <h6 className="mb-0 fw-bolderr text-truncate">
                                        <Link className="text-truncate" to={`/profile/${state.created_by._id}/on_sale`}>
                                            <strong>
                                                @
                                                {state.created_by?.user_name && state.created_by?.user_name
                                                    ? state.created_by?.user_name
                                                    : `${state?.creator_address?.slice(
                                                        0,
                                                        WALLET_ADDRESS_SLICE_FROM
                                                    )}....${state.creator_address?.slice(
                                                        state?.creator_address?.length -
                                                        WALLET_ADDRESS_SLICE_FROM,
                                                        state?.creator_address?.length
                                                    )}`}
                                            </strong>
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                            <div className="owner-name me-4 ms-lg-4 d-flex align-items-center pt-2">
                                <div className="name-img">
                                    <img src={state.current_owner.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.current_owner.profile_pic}`
                                        : profile_placeholder} />
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0">owner</p>
                                    <h6 className="mb-0 fw-bolderr text-truncate">
                                        <Link className="text-truncate" to={`/profile/${state.current_owner?._id}/on_sale`}>
                                            @
                                            {state.current_owner?.user_name && state.current_owner?.user_name
                                                ? state.current_owner.user_name
                                                : state?.current_owner?.address ? `${state?.current_owner?.address?.slice(
                                                    0,
                                                    WALLET_ADDRESS_SLICE_FROM
                                                )}....${state.current_owner?.address?.slice(
                                                    state?.current_owner?.address?.length -
                                                    WALLET_ADDRESS_SLICE_FROM,
                                                    state?.current_owner?.address?.length
                                                )}` : '...'}
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                            <div className="owner-name me-4 ms-lg-4 d-flex align-items-center pt-2">
                                <div className="name-img">
                                    {state.collection_id?.img_url?.includes("https") ? <img src={
                                        `${state.collection_id?.img_url}`
                                    } /> : <img
                                        src={
                                            state.collection_id?.img_url
                                                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.collection_id?.img_url}`
                                                : logo
                                        }
                                    />}
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0">Collection</p>
                                    <h6 className="mb-0 fw-bolderr text-truncate">
                                        <Link className="text-truncate" to={`/collection/${state.collection_id?._id ? state.collection_id?._id : DEFAULT_COLLECTION_ID}`}>
                                            {state.collection_id?.name ? <>{state.collection_id?.name?.length > 15 ? `${state.collection_id?.name.slice(0, 8)}...` : state.collection_id?.name}</> : "Acria Network"}
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                            {state.type == TIMED_AUCTION_AUCTION_ID && (
                                <div className="owner-name d-flex align-items-center pt-2">
                                    <div className="name-img">
                                        <img src={
                                            state.current_owner.profile_pic
                                                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.current_owner.profile_pic}`
                                                : profile_placeholder
                                        } className="" />
                                    </div>
                                    <div className="name-text ms-2">
                                        <p className="text-black-50 mb-0">Minimum bid</p>
                                        <h6 className="mb-0 fw-bolderr text-truncate">
                                            <span className="color-red text-truncate">
                                                {state.price} wETH
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="nav-tabss mt-4">
                            <div className="on-salle pb-4 mb-lg-5">
                                <ul
                                    className="nav nav-pills mb-4 border-bottom mt-lg-4"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    {(state.type == TIMED_AUCTION_AUCTION_ID || state.type == UNLIMITED_AUCTION_AUCTION_ID || match.params.tab == "bids") && (
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${match.params.tab == "bids" ? "active" : ""} text-secondary  bg-transparent  ps-0`}
                                                id="pills-home-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-home"
                                                aria-selected="true"
                                                onClick={() => navigate(`/content/${match.params.id}/bids/${match.params.graph_type}`, { replace: true })}
                                            >

                                                {state.type == TIMED_AUCTION_AUCTION_ID ? 'Bids' : 'Offers'}
                                            </button>
                                        </li>
                                    )}
                                    {(state.type == TIMED_AUCTION_AUCTION_ID || state.type == UNLIMITED_AUCTION_AUCTION_ID || match.params.tab == "trading") ? (
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${match.params.tab == "trading" ? "active" : ""}  text-secondary bg-transparent`}
                                                id="pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-profile"
                                                aria-selected="true"
                                                onClick={() => navigate(`/content/${match.params.id}/trading/${match.params.graph_type}`, { replace: true })}
                                            >
                                                Trading History
                                            </button>
                                        </li>
                                    ) : (
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link text-secondary ${match.params.tab == "trading" ? "active" : " "}     bg-transparent`}

                                                id="pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-profile"
                                                aria-selected="true"
                                                onClick={() => navigate(`/content/${match.params.id}/trading/${match.params.graph_type}`, { replace: true })}
                                            >
                                                Trading History
                                            </button>
                                        </li>
                                    )}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link text-secondary  bg-transparent"
                                            id="pills-contact-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contact"
                                            aria-selected="false"
                                            onClick={() => navigate(`/content/${match.params.id}/price/${match.params.graph_type}`, { replace: true })}
                                        >
                                            Price History
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    {(state.type == TIMED_AUCTION_AUCTION_ID || state.type == UNLIMITED_AUCTION_AUCTION_ID) && (
                                        <div
                                            className={`tab-pane fade  ${match.params.tab == "bids" ? "show active" : " "}`}
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab"
                                        >
                                            {bidsData.bids.length ? Array.isArray(bidsData.bids) &&
                                                bidsData.bids.map((res) => {
                                                    return <div key={res._id} className="bid-owner w-lg-75 p-3 d-lg-flex justify-content-between mb-3 ">
                                                        <div className="owner-name d-flex align-items-center ">
                                                            <div className="name-img">
                                                                <img
                                                                    src={
                                                                        res.bid_placed_by.profile_pic
                                                                            ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.bid_placed_by.profile_pic}`
                                                                            : profile_placeholder
                                                                    }
                                                                    className=""
                                                                />
                                                            </div>
                                                            <div className="name-text ms-2">
                                                                <p className=" mb-0">
                                                                    {state.type == TIMED_AUCTION_AUCTION_ID ? 'Bid place by' : "Offer place by"}
                                                                    <Link
                                                                        to={`/${res.bid_placed_by.account_type ==
                                                                            "USER"
                                                                            ? "profile"
                                                                            : "profile"
                                                                            }/${res.bid_placed_by._id}/on_sale`}
                                                                    >
                                                                        <strong className="color-red">
                                                                            {res.bid_placed_by.user_name ? res.bid_placed_by.user_name : res.bid_placed_by._id}
                                                                        </strong>
                                                                    </Link>
                                                                </p>
                                                                <p className=" mb-0">
                                                                    {moment(Number(res.created_at)).format(
                                                                        "MMM DD, YYYY"
                                                                    )}{" "}
                                                                    at{" "}
                                                                    {moment(Number(res.created_at)).format(
                                                                        "hh:mm:a"
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="name-text ms-2">
                                                            <h6 className="fs-5 fw-bolderr text-black mb-0 text-end mt-2 mt-lg-0">
                                                                {res.bid_amount} wETH
                                                            </h6>
                                                            <p className="text-black-50 mb-lg-0 text-end mb-2">${Number(Number(res.bid_amount) * usdPrice).toFixed(4)}</p>
                                                            {state.current_owner?._id === authState._id && state.type == UNLIMITED_AUCTION_AUCTION_ID &&
                                                                <button
                                                                    className="trant-btn btn w-100"
                                                                    disabled={removeFromSaleLoading || loading}
                                                                    onClick={() => placeOrderAction(res.owner_address, res.bid_amount, res._id)}>
                                                                    {removeFromSaleLoading ? <Spinner /> : "Accept Offer"}
                                                                </button>}
                                                        </div>
                                                    </div>
                                                }) : <div className="bid-owner w-lg-75 p-3 d-flex justify-content-between mb-3">
                                                <div className="owner-name d-flex align-items-center justify-content-center w-100">

                                                    <div className="name-text ms-2">
                                                        <div className="imge-placeholder text-center">
                                                            <img className="w-100 no-data-found" src={placeholder_image} />
                                                            <p className="mt-2 ms-lg-5">No Bids found</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    )}
                                    <div
                                        className={`tab-pane fade ${match.params.tab == "trading" ? "show active" : ""} `}
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        {
                                            tradingHistory.trading_history.length ?
                                                <div className="trade-history scrolling">
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="">
                                                                <th className="ps-5" scope="col">Date</th>
                                                                <th scope="col">From</th>
                                                                <th scope="col">To</th>
                                                                <th scope="col">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tradingHistory.trading_history.length ? Array.isArray(tradingHistory.trading_history) &&
                                                                tradingHistory.trading_history.map((res) => {
                                                                    return (
                                                                        <tr key={res._id}>
                                                                            <td className="ps-5">
                                                                                {moment(Number(res.created_at)).format(
                                                                                    "MMM DD, YYYY"
                                                                                )}{" "}
                                                                                at{" "}
                                                                                {moment(Number(res.created_at)).format(
                                                                                    "hh:mma"
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/profile/${res.sold_by._id}/on_sale`}
                                                                                >
                                                                                    <span>
                                                                                        @
                                                                                        {res.sold_by.user_name
                                                                                            ? res.sold_by.user_name
                                                                                            : res.sold_by._id}
                                                                                    </span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/profile/${res.sold_to._id}/on_sale`}
                                                                                >
                                                                                    <span>
                                                                                        @
                                                                                        {res.sold_to.user_name
                                                                                            ? res.sold_to.user_name
                                                                                            : res.sold_to._id}
                                                                                    </span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>{res.price} wETH</td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                <div className="owner-name d-flex align-items-center justify-content-center">
                                                                    <div className="name-text ms-2 ">
                                                                        <div className="imge-placeholder text-center">
                                                                            <img className="w-100 no-data-found" src={placeholder_image} />
                                                                            <p className="mt-2 ms-lg-5">No Trading history found</p>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                        </tbody>
                                                    </table>
                                                </div> : <div className="bid-owner w-lg-75 p-3 d-flex justify-content-between mb-3">
                                                    <div className="owner-name d-flex align-items-center justify-content-center w-100">

                                                        <div className="name-text ms-2">
                                                            <div className="imge-placeholder text-center">
                                                                <img className="w-100 no-data-found" src={placeholder_image} />
                                                                <p className="mt-2 ms-lg-5">No Trading history found</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                    </div>
                                    <div
                                        className={`tab-pane fade ${match.params.tab == "price" ? "show active" : ""}`}
                                        id="pills-contact"
                                        role="tabpanel"
                                        aria-labelledby="pills-contact-tab"
                                    >
                                        {priceHistory.data.length ?
                                            <div className="downn-arrow position-relative text-end">
                                                <i className="far fa-angle-down position-absolute "></i>
                                                <select className="trant-btn btn btn-light Create-btn rounded-pill pe-4 ps-4 " name="month" onChange={(e) => changeGraphType(String(e.target.value).toLowerCase())} value={String(match.params.graph_type).toUpperCase()}>

                                                    <option value="LAST_7_DAYS">Last 7 days</option>
                                                    <option value="LAST_14_DAYS">Last 14 days</option>
                                                    <option value="LAST_30_DAYS">Last 30 days</option>
                                                    <option value="LAST_60_DAYS">Last 60 days</option>
                                                    <option value="LAST_90_DAYS">Last 90 days</option>
                                                    <option value="LAST_YEAR">Last year</option>
                                                    <option value="ALL_TIME">All time</option>
                                                </select>
                                            </div>
                                            : ""}
                                        <div className="chartjs scrolling">
                                            {priceHistory.data.length ? <Graphs.LineChart {...priceHistory} /> : <div className="bid-owner w-lg-75 p-3 d-flex justify-content-between mb-3">
                                                <div className="owner-name d-flex align-items-center justify-content-center w-100">
                                                    <div className="name-text ms-2">
                                                        <div className="imge-placeholder text-center">
                                                            <img className="w-100 no-data-found" src={placeholder_image} />
                                                            <p className="mt-2 ms-lg-5"> No Price history found</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* fix price modal */}
        <div
            className="modal fade"
            data-bs-backdrop="static"
            id="fixedPriceAuctionModal"
            tabIndex="-1"
            aria-labelledby="fixedPriceAuctionModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="fixedPriceAuctionModalLabel">
                                        Fixed Auction
                                    </h5>
                                    <button
                                        id="fixedPriceAuctionCloseModal"
                                        type="button"
                                        className="btn shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4">
                                    <p className="mb-2 text-center text-truncate">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state.current_owner.user_name
                                            ? state.current_owner.user_name
                                            : `${state?.current_owner?.address?.slice(
                                                0,
                                                WALLET_ADDRESS_SLICE_FROM
                                            )}....${state.current_owner?.address?.slice(
                                                state?.current_owner?.address?.length -
                                                WALLET_ADDRESS_SLICE_FROM,
                                                state?.current_owner?.address?.length
                                            )}`}</strong> {state.collection_id?.name ? <>{state.collection_id?.name?.length > 15 ? `${state.collection_id?.name.slice(0, 8)}...` : state.collection_id?.name}</> : "Acria Network"}
                                    </p>
                                    <div className="input-group mb-3 border-bid mt-3">
                                        <input
                                            type="text"
                                            className="form-control shadow-none border-0"
                                            placeholder="Enter resale amount"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => setResaleAmount(e.target.value)}
                                            value={resaleAmount}
                                        />
                                        <span
                                            className="input-group-text border-0 bg-transparent"
                                            id="basic-addon2"
                                        >
                                            <small className="text-muted">
                                                wETH<i className="fal fa-angle-down"></i>
                                            </small>
                                        </span>
                                    </div>

                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0 text-start">
                                                <small>Fixed Amount</small>
                                            </p>
                                            {/* <h6 className="mb-0 fw-bolderr text-truncate"><small>3,333 wETH</small></h6> */}
                                        </div>
                                        <div className="bid-right">
                                            <p className=" mb-0 text-end">
                                                <small className="text-break">{resaleAmount} wETH</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>Your balance</small>
                                        </p>

                                        <h6 className="mb-0 fw-bolderr text-truncate  make-offer">
                                            <small className="text-truncate">{Number(balanceInEth * wethPrice).toFixed(3)} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        disabled={0 >= resaleAmount || putOnSaleLoading}
                                        onClick={() => reCreateFixedNft(1)}>
                                        <small className="text-truncate">
                                            {putOnSaleLoading ? <Spinner /> : "Create Fixed Auction"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* unlimited modal */}
        <div
            className="modal fade"
            data-bs-backdrop="static"
            id="unlimitedAuctionModal"
            tabIndex="-1"
            aria-labelledby="unlimitedAuctionModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="unlimitedAuctionModalLabel">
                                        Unlimited Auction
                                    </h5>
                                    <button
                                        id="unlimitedAuctionCloseModal"
                                        type="button"
                                        className="btn shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4">
                                    <p className="mb-2 text-center text-truncate">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state.current_owner.user_name
                                            ? state.current_owner.user_name
                                            : `${state?.current_owner?.address?.slice(
                                                0,
                                                WALLET_ADDRESS_SLICE_FROM
                                            )}....${state.current_owner?.address?.slice(
                                                state?.current_owner?.address?.length -
                                                WALLET_ADDRESS_SLICE_FROM,
                                                state?.current_owner?.address?.length
                                            )}`}</strong> {state.collection_id?.name ? <>{state.collection_id?.name?.length > 15 ? `${state.collection_id?.name.slice(0, 8)}...` : state.collection_id?.name}</> : "Acria Network"}
                                    </p>
                                    <div className="input-group mb-3 border-bid mt-3">
                                        <input
                                            type="text"
                                            className="form-control shadow-none border-0"
                                            placeholder="Enter resale amount"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => setResaleAmount(e.target.value)}
                                            value={resaleAmount}
                                        />
                                        <span
                                            className="input-group-text border-0 bg-transparent"
                                            id="basic-addon2"
                                        >
                                            <small className="text-muted">
                                                wETH<i className="fal fa-angle-down"></i>
                                            </small>
                                        </span>
                                    </div>

                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0 text-start">
                                                <small>Minimum Amount</small>
                                            </p>
                                            {/* <p className=" mb-0">
                                                    <small>Service fee</small>
                                                </p> */}
                                            {/* <h6 className="mb-0 fw-bolderr text-truncate"><small>3,333 wETH</small></h6> */}
                                        </div>
                                        <div className="bid-right">
                                            <p className=" mb-0 text-end text-truncate make-offer">
                                                <small className="text-truncate">{resaleAmount} wETH</small>
                                            </p>
                                            {/* <p className=" mb-0">
                                                    <small>0.0002625 wETH</small>
                                                </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>Your balance</small>
                                        </p>
                                        <h6 className="mb-0 fw-bolderr">
                                            <small className="text-truncate">{Number(balanceInEth * wethPrice).toFixed(3)} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        disabled={0 >= resaleAmount || putOnSaleLoading}
                                        onClick={() => reCreateFixedNft(2)}
                                    >
                                        <small className="">
                                            {putOnSaleLoading ? <Spinner /> : "Create Unlimited Auction"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* timed Auction */}
        <div
            className="modal fade"
            data-bs-backdrop="static"
            id="timedAuctionModal"
            tabIndex="-1"
            aria-labelledby="timedAuctionModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className=" m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red  fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="timedAuctionModalLabel">
                                        Timed Auction
                                    </h5>
                                    <button
                                        id="timedAuctionCloseModal"
                                        type="button"
                                        className="btn shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4 text-truncate">
                                    <p className="mb-2 text-center">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state.current_owner.user_name
                                            ? state.current_owner.user_name
                                            : `${state?.current_owner?.address?.slice(
                                                0,
                                                WALLET_ADDRESS_SLICE_FROM
                                            )}....${state.current_owner?.address?.slice(
                                                state?.current_owner?.address?.length -
                                                WALLET_ADDRESS_SLICE_FROM,
                                                state?.current_owner?.address?.length
                                            )}`}</strong> {state.collection_id?.name ? <>{state.collection_id?.name?.length > 15 ? `${state.collection_id?.name.slice(0, 8)}...` : state.collection_id?.name}</> : "Acria Network"}
                                    </p>
                                    <div className="input-group mb-3 border-bid mt-3">
                                        <input
                                            type="text"
                                            className="form-control shadow-none border-0"
                                            placeholder="Enter resale amount"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => setResaleAmount(e.target.value)}
                                            value={resaleAmount} />
                                        <span
                                            className="input-group-text border-0 bg-transparent"
                                            id="basic-addon2">
                                            <small className="text-muted">
                                                wETH<i className="fal fa-angle-down"></i>
                                            </small>
                                        </span>
                                    </div>
                                    <div className="input-wrapper w-100 d-flex justify-content-between align-items-center">
                                        <div className="me-1">
                                            <label htmlFor="">Starting Date</label>
                                            <div className="input-group">
                                                <select
                                                    className="form-select bg-transparent shadow-none border-0 w-100"
                                                    name="startDate"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setStartDate(e.target.value)}>
                                                    <option value={RIGHT_AFTER_LISTING}>
                                                        Right After Listing
                                                    </option>
                                                    <option value={PICK_SPECIFIC_DATE}>
                                                        Pick Specific Date
                                                    </option>
                                                </select>
                                            </div>
                                            {startDate == PICK_SPECIFIC_DATE &&
                                                <input
                                                    type={`date`}
                                                    className="form-control mt-1"
                                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                                    name="startSpecificDate"
                                                    onChange={(e) =>
                                                        setStartSpecificDate(e.target.valueAsNumber)
                                                    } />
                                            }
                                        </div>
                                        <div>
                                            <label htmlFor="">Expiration Date</label>
                                            <div className="input-group border">
                                                <select
                                                    name="endDate"
                                                    className="form-select bg-transparent shadow-none border-0 text-muted w-100"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setEndDate(e.target.value)}>
                                                    <option value={EXPIRE_1_DAY}>1 Day</option>
                                                    <option value={EXPIRE_3_DAY}>3 Days</option>
                                                    <option value={EXPIRE_5_DAY}>5 Days</option>
                                                    <option value={PICK_SPECIFIC_DATE}>
                                                        Pick Specific Date
                                                    </option>
                                                </select>
                                            </div>
                                            {endDate == PICK_SPECIFIC_DATE &&
                                                <input
                                                    type={`date`}
                                                    className="form-control mt-1"
                                                    min={moment(Number(startSpecificDate))
                                                        .add(1, "days")
                                                        .format("YYYY-MM-DD")}
                                                    name="endSpecificDate"
                                                    onChange={(e) =>
                                                        setEndSpecificDate(e.target.valueAsNumber)
                                                    } />
                                            }
                                        </div>
                                    </div>
                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0 text-start">
                                                <small>Minimum Amount</small>
                                            </p>
                                        </div>
                                        <div className="bid-right">
                                            <p className=" mb-0 text-end text-truncate make-offer">
                                                <small className="text-truncate">{resaleAmount} wETH</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>Your balance</small>
                                        </p>
                                        <h6 className="mb-0 fw-bolderr ">
                                            <small className="text-truncate">{Number(balanceInEth * wethPrice).toFixed(3)} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        disabled={0 >= resaleAmount || putOnSaleLoading}
                                        onClick={() => reCreateAcutionNft()}>
                                        <small className="">
                                            {putOnSaleLoading ? <Spinner /> : "Create Timed Acution"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* fixed price */}
        <div
            className="modal fade"
            data-bs-backdrop="static"
            id="buybid"
            tabIndex="-1"
            aria-labelledby="buybidModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="buybidModalLabel"
                                    >
                                        Checkout
                                    </h5>
                                    <button
                                        id="purchaseCloseModal"
                                        type="button"
                                        className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4">
                                    <p className="mb-2 text-center  ">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state?.current_owner?.name ? state?.current_owner?.name : state.current_owner?._id ? state.current_owner?._id : ''}</strong> {state.collection_id?.name ? <>{state.collection_id?.name?.length > 15 ? `${state.collection_id?.name.slice(0, 8)}...` : state.collection_id?.name}</> : "Acria Network"}</p>

                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0">
                                                <small>NFT price</small>
                                            </p>
                                            <p className=" mb-0">
                                                <small>Service fee</small>
                                            </p>
                                            {/* <h6 className="mb-0 fw-bolderr text-truncate"><small>3,333 wETH</small></h6> */}
                                        </div>
                                        <div className="bid-right">
                                            <p className="mb-0 text-end ">
                                                <small className="text-break">{state.price} wETH</small>
                                            </p>
                                            <p className=" mb-0 ">
                                                <small className="text-break">{feePercentGloble}%</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>You will pay</small>
                                        </p>
                                        <h6 className="mb-0 fw-bolderr">
                                            {/* <small className="text-break">{Number(state.price) + Number(state.price / 10)} wETH</small> */}
                                            <small className="text-break">{paybleAmount(state?.price, feePercentGloble)?.bidAmount} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        onClick={() => purchaseNFT()}
                                        disabled={buyLoading}>
                                        <small className="">
                                            {buyLoading ? <Spinner /> : "Buy"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* place-bid modal */}
        <div
            className="modal fade "
            id="makeAnOfferModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="makeAnOfferModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="makeAnOfferModalLabel">
                                        Make an offer
                                    </h5>
                                    <button
                                        id="makeAnOfferModalCloseModal"
                                        type="button"
                                        className="btn shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4">
                                    <p className="mb-2 text-center">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state.current_owner.user_name
                                            ? state.current_owner.user_name
                                            : `${state?.current_owner?.address?.slice(
                                                0,
                                                WALLET_ADDRESS_SLICE_FROM
                                            )}....${state.current_owner?.address?.slice(
                                                state?.current_owner?.address?.length -
                                                WALLET_ADDRESS_SLICE_FROM,
                                                state?.current_owner?.address?.length
                                            )}`}</strong> {state.collection_id ? state.collection_id?.name : "Acria network"}
                                    </p>
                                    <div className="input-group mb-3 border-bid mt-3">
                                        <input
                                            type="text"
                                            className="form-control shadow-none border-0 text-truncate "
                                            placeholder="Make an offer"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => setBidAmount(e.target.value)}
                                            value={bidAmount} />
                                        <span
                                            className="input-group-text border-0 bg-transparent"
                                            id="basic-addon2">
                                            <small className="text-muted">
                                                wETH<i className="fal fa-angle-down"></i>
                                            </small>
                                        </span>
                                    </div>
                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0">
                                                <small>Your offer balance</small>
                                            </p>
                                            <p className=" mb-0">
                                                <small>Service fee</small>
                                            </p>
                                        </div>
                                        <div className="bid-right">
                                            <p className=" mb-0 text-end text-truncate make-offer">
                                                <small className="text-truncate">{bidAmount} wETH</small>
                                            </p>
                                            <p className=" mb-0">
                                                <small className="text-break">{feePercentGloble}%</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>You will pay</small>
                                        </p>
                                        <h6 className="mb-0 fw-bolderr text-truncate make-offer">
                                            <small className="text-truncate">{paybleAmount(bidAmount, feePercentGloble).bidAmount} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        onClick={() => onPlaceABid(bidAmount, "Make Offer", "Offer in progress...")}
                                        disabled={Number(bidAmount ? bidAmount : 0) <= 0 || bidOfferLoading}>
                                        <small className="">
                                            {bidOfferLoading ? <Spinner /> : "Make offer"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* place-bid modal */}
        <div
            className="modal fade "
            id="placeABidModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="placeABidModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            <div className="modal-content">
                                <div className="modal-header border-0 p-0 ">
                                    <h5
                                        className="modal-title w-100 text-center color-red fw-6 fs-4 pb-2  pt-4 d-flex justify-content-center "
                                        id="placeABidModalLabel"
                                    >
                                        Place a bid
                                    </h5>
                                    <button
                                        id="bidCloseModal"
                                        type="button"
                                        className="btn  shadow-none fs-6 position-absolute top-0 end-0"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body pt-1 pb-0 p-4">
                                    <p className="mb-2 text-center text-truncate">
                                        You are about to bid for <strong>{state.name}</strong>{" "}
                                        from <strong>@{state.current_owner.user_name
                                            ? state.current_owner.user_name
                                            : `${state?.current_owner?.address?.slice(
                                                0,
                                                WALLET_ADDRESS_SLICE_FROM
                                            )}....${state.current_owner?.address?.slice(
                                                state?.current_owner?.address?.length -
                                                WALLET_ADDRESS_SLICE_FROM,
                                                state?.current_owner?.address?.length
                                            )}`}</strong> {state.collection_id ? state.collection_id?.name : "Acria network"}
                                    </p>
                                    <div className="input-group mb-3 border-bid mt-3">
                                        <input
                                            type="text"
                                            className="form-control shadow-none border-0"
                                            placeholder="Enter bid"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => setBidAmount(e.target.value)}
                                            value={bidAmount}
                                        />
                                        <span
                                            className="input-group-text border-0 bg-transparent"
                                            id="basic-addon2"
                                        >
                                            <small className="text-muted">wETH<i className="fal fa-angle-down"></i>
                                            </small>
                                        </span>
                                    </div>
                                    <div className="highhest-bid d-flex justify-content-between align-items-center pt-3  border-bottom pb-2">
                                        <div className="bid-left">
                                            <p className=" mb-0">
                                                <small>Your bidding balance</small>
                                            </p>
                                            <p className=" mb-0">
                                                <small>Service fee</small>
                                            </p>
                                        </div>
                                        <div className="bid-right">
                                            <p className="mb-0 text-end text-truncate make-offer">
                                                <small className=" text-truncate">{bidAmount} wETH</small>
                                            </p>
                                            <p className="mb-0 text-end ">
                                                <small className="text-break">10%</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className=" mb-0">
                                            <small>You will pay</small>
                                        </p>
                                        <h6 className="mb-0 fw-bolderr text-end make-offer">
                                            <small className=" text-break">{paybleAmount(bidAmount).bidAmount} wETH</small>
                                        </h6>
                                    </div>
                                    <button
                                        id="submit_id"
                                        type="button"
                                        className="orange-btn btn btn-primary shadow-none w-100 m-auto mx-3 mb-2"
                                        onClick={() => onPlaceABid(bidAmount, "Place A Bid", "Bid in progress...")}
                                        disabled={bidOfferLoading}>
                                        <small className="">
                                            {bidOfferLoading ? <Spinner /> : "Place A Bid"}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modals.ReportNft reportText={reportText} setReportText={setReportText} submitReport={submitReport} />
        <Modals.WethStep steps={wethStep} title={bidTitle} description={bidDescription} />
    </section >
};