import { useState } from "react"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import henceforthApi from "../utils/henceforthApi"
import logo from "./../assets/images/agria_logo_footer.svg";

export default () => {
    const [state, setState] = useState({
        email: "",
        message: ""
    })
    const handleInput = async ({ name, value }) => {
        setState({
            ...state,
            [name]: value
        })
    }
    const clearState = () => {
        setState({
            email: "",
            message: ""
        })
    }
    const onSubmitContectUs = async () => {
        try {
            let items = {
                "email": state.email,
                "message": state.message,
                "language": "ENGLISH"
            }
            let apiRes = await henceforthApi.Common.contentUs(items)
            toast.success(apiRes?.message ? apiRes?.message : 'Submitted')
            clearState()
        } catch (error) {
            toast.error(error?.response?.body?.error_description);
        }
    }
    return <div className="container shadow">
        <div className="row pt-5 pb-5 align-items-center">
            <div className="col-lg-6 col-md-12">
                <div className="nft-logo ms-lg-5 ps-lg-5">
                    <Link className="navbar-brand text-white fw-bold fs-2 me-5" to="/">
                        <img src={logo} className="w-50 mb-3" />
                    </Link>
                    <p>We are here to support you. Let us know how we can help,<br /> make the platform better or just say "hi".</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-12">
                <div className="nft-form me-lg-5 pe-lg-5">
                    <form onSubmit={(e) => { e.preventDefault(); onSubmitContectUs() }}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                            <input type="email" placeholder="Email" className="form-control shadow-none border-1 border" id="exampleInputEmail1" aria-describedby="emailHelp" value={state.email} name="email" onChange={(e) => handleInput(e.target)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Message</label>
                            <textarea className="form-control shadow-none border-1 border" placeholder="Message..." id="floatingTextarea2" rows="4" cols="50" value={state.message} name="message" onChange={(e) => handleInput(e.target)}></textarea>
                        </div>
                        <button type="submit" className="orange-btn btn bg-dark text-white w-100 Create-btn rounded-pill pe-4 ps-4 mt-3 border">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
}