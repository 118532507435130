import React, { useState, useEffect } from "react";
import henceforthApi from "../utils/henceforthApi";

export default () => {
    const [state, setState] = useState({
        data: [],
        total_count: 0,
    });
    const initialiseData = async () => {
        try {
            let apiRes = await henceforthApi.Common.faq();
            setState({
                ...state,
                ...apiRes.data,
            });
        } catch (error) {
        }
    };
    useEffect(() => {
        initialiseData();
    }, []);

    return <section className="faqsection pt-0 mb-0 pb-lg-0">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-3 ">
                    <div className="trending-heading d-lg-flex justify-content-between align-items-center pb-lg-2">
                        <h2 className=" mb-sm-0">FAQ</h2>
                    </div>
                    <hr className="border-bottom" />
                </div>
                <div className="col-md-12">
                    <div className="faq">
                        <div className="accordion" id="accordionExample">
                            {state.data.map((res, index) => {
                                return <div key={res._id} className="accordion-item mb-3">
                                    <h2 className="accordion-header mt-0" id="headingOne">
                                        <button
                                            className="accordion-button btn-primary-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapseOne${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapseOne${index}`}
                                        >
                                            {res.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapseOne${index}`}
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div
                                            className="accordion-body word-break-faq"
                                        >{res.answer ? res.answer.slice(3, -4) : "answer not available"}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

