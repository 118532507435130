export default {
    data: [],
    last_date: new Date(),
    last_update: new Date(),
    nfts: {
        count_data: 0,
        data: [],
    },
    top_ten_creators: [],
    live_auction: {
        count_data: 0,
        data: [],
    },
    trending: {
        count_data: 0,
        data: [],
    },
    top_sellers: {
        count_data: 0,
        data: [],
    },
}