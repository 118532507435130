import chooseimg from "./../../assets/images/other/collection_choose_img.svg";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import henceforthApi from "../../utils/henceforthApi";
import { toast } from "react-toastify";
import { Spinner } from "../BootstrapCompo";
import { ethers } from "ethers";
import commonAbi from '../../utils/henceforthFunctions.json'

export default () => {

    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, createCollection721, fileUpload, getMyProvider } = useContext(GlobalContext)

    const [icon, setIcon] = useState(null)
    const [collectionName, setCollectionName] = useState("")
    const [collectionSymbol, setCollectionSymbol] = useState("")
    const [collectionDescription, setCollectionDescription] = useState("")
    const [collectionSortUrl, setCollectionSortUrl] = useState("")

    const [collectionAddress, setCollectionAddress] = useState("")
    const [error, setError] = useState("")

    const [loading, setLoading] = useState(false)
    henceforthApi.setToken(authState.access_token)

    const createNewCollection = async () => {
        if (!icon) {
            toast.warn("image is required")
            return
        }
        if (!collectionName) {
            toast.warn("Collection name is required")
            return
        }
        if (!collectionSymbol) {
            toast.warn("Collection symbol is required")
            return
        }
        setLoading(true)

        let contractRes = await createCollection721(collectionName, collectionSymbol, icon, collectionDescription)
        if (contractRes == null) {
            setLoading(false)
            return
        }
        const events = contractRes.events
        var proxy = null
        if (Array.isArray(events)) {
            events.forEach(element => {
                if (element.args) {
                    let args = element.args
                    proxy = args.proxy
                }
            })
        }
        debugger
        try {
            let fileName = await fileUpload(icon)
            debugger
            let items = {
                address: proxy,
                img_url: fileName,
                name: collectionName,
                symbol: collectionSymbol,
                language: "ENGLISH"
            }
            debugger
            if (collectionDescription) {
                items["description"] = collectionDescription;
            }
            // if (collectionSortUrl) {
            //     items["short_url"] = collectionSortUrl;
            // }
            let apiRes = await henceforthApi.Collection.create(items)
            try {
                let closeCollectionModal = document.getElementById("closeCollectionModal")
                if (closeCollectionModal) {
                    closeCollectionModal.click()
                }
            } catch (error) {
                setLoading(false)
            }
            query.set("collection_id", apiRes.data._id)
            query.set("collection", proxy)
            setLoading(false)
            setIcon(null)
            setCollectionName("")
            setCollectionSymbol("")
            setCollectionDescription("")
            setCollectionSortUrl("")

            navigate({ search: query.toString() })
        } catch (error) {
            setLoading(false)
            if (error.response && error.response && error.response.body && error.response.body.error_description) {
                if (typeof error.response.body.error_description === "string") {
                    toast.error(error.response.body.error_description)
                } else {
                    toast.error(JSON.stringify(error.response.body.error_description))
                }
            }
        }
    }

    const importCollection = async () => {
        try {
            let { accounts, provider } = await getMyProvider()
            if (accounts.length) {
                const signer = provider.getSigner()
                const contract = new ethers.Contract(collectionAddress, commonAbi.abi, signer);
                const ownerContract = await contract.functions.owner();
                let ownerRes = Array.isArray(ownerContract) ? ownerContract[0] : ownerContract
                if (String(accounts[0]).toLocaleLowerCase() !== String(ownerRes).toLocaleLowerCase()) {
                    return toast.warn("You are not owner of this collection")
                }
                setLoading(true)

                const contractURI = await contract.functions.contractURI();
                const symbolContract = await contract.functions.symbol();
                const nameContract = await contract.functions.name();
                debugger
                let source = Array.isArray(contractURI) ? contractURI[0] : contractURI
                let symbolRes = Array.isArray(symbolContract) ? symbolContract[0] : symbolContract
                let nameRes = Array.isArray(nameContract) ? nameContract[0] : nameContract
                if (source.includes("{address}")) {
                    // in rinkeyBy
                    source = source.replace("{address}", collectionAddress)
                }
                let collectionRes = await (await fetch(source)).json()
                let imageUrl = String(collectionRes.image).includes("http") ? collectionRes.image : collectionRes.image.split("/")
                if (Array.isArray(imageUrl)) {
                    imageUrl = imageUrl[imageUrl.length - 1];
                    imageUrl = `https://ipfs.io/ipfs/${imageUrl}`
                }

                const items = {
                    address: collectionAddress,
                    description: collectionRes.description,
                    img_url: imageUrl,
                    name: nameRes,
                    symbol: symbolRes,
                    language: "ENGLISH"
                }
                let closeImportCollectionModal = document.getElementById("closeImportCollectionModal")
                let apiRes = await henceforthApi.Collection.create(items)
                if (closeImportCollectionModal) {
                    closeImportCollectionModal.click()
                }
                setCollectionAddress("")
                setLoading(false)
                navigate(`/collection/${apiRes.data._id}`)
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                if (error.response.body) {
                    setError(error.response.body.message)
                }
            } else {
                toast.warn("Something wrong in collection.")
            }
        }
    }
    useEffect(() => {
        setError("")
    }, [collectionAddress])

    return (
        <>
            <div className="modal signin fade collection-modal" data-bs-backdrop="static" id="CreateCollectionModal" tabindex="-1" aria-labelledby="signinLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" id="closeCollectionModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa fa-times"></i></button>
                        </div>
                        <div className="modal-body pt-0 pe-4 ps-4">
                            <h5 className="modal-title">Collection ERC-721</h5>
                            <div className="collections-div mt-4 mb-3">
                                <div className="d-flex text-start align-items-center">
                                    <div className="colloective-img position-relative choose-img me-3">
                                        <img src={icon ? URL.createObjectURL(icon) : chooseimg} />
                                        <input type="file" accept="image/*" onChange={(e) => setIcon(e.target.files[0])} />
                                    </div>
                                    <div className="collection-text">
                                        <p>We recommend an image of at least 300*300. </p>
                                        <p>Max 5MB.</p>
                                    </div>
                                </div>
                                <div className="collection-name">
                                    <div className="form-group mb-3">
                                        <label className="mb-1">Collection name (required)</label>
                                        <input type="text" placeholder="Collection name" class="mb-0" value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                                        <p>Token name cannot be changed in future</p>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-1">Symbol (required)</label>
                                        <input type="text" placeholder="Token symbol" value={collectionSymbol} onChange={(e) => setCollectionSymbol(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-1">Description (optional)</label>
                                        <textarea placeholder="Spread some word about your token collection" value={collectionDescription} onChange={(e) => setCollectionDescription(e.target.value)} ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center pt-0 pe-4 ps-4">
                            <button type="button" className="btn banner-button primery-button w-100" onClick={() => createNewCollection()} disabled={loading}>{loading ? <Spinner /> : 'CREATE'}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal signin fade importcollection-modal" data-bs-backdrop="static" id="ImportCollectionModal" tabindex="-1" aria-labelledby="signinLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <form className="modal-content" onSubmit={(e) => { e.preventDefault(); importCollection() }}>
                        <div className="modal-header">
                            <button type="button" id="closeImportCollectionModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa fa-times"></i></button>
                        </div>
                        <div className="modal-body pt-0 pe-4 ps-4">
                            <h5 className="modal-title">Import your collection</h5>
                            <div className="collections-div mt-4 mb-3">
                                <div className="collection-name">
                                    <div className="form-group mb-3">
                                        <label className="mb-1">Collection address</label>
                                        <input type="text" placeholder="Enter your ERC721 collection address" className="mb-0" value={collectionAddress} onChange={(e) => setCollectionAddress(e.target.value)} />
                                        {error ? <div className="text-danger">
                                            {error}
                                        </div> : ''}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center pt-0 pe-4 ps-4">
                            <button type="submit" className="btn banner-button primery-button w-100" disabled={loading}>{loading ? <Spinner /> : 'IMPORT'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}