const baseUrl = 'https://acriamarket.infura-ipfs.io/'
export const ipfsGetFileUrl = (token) => {
    return `${baseUrl}ipfs/${token}`
}

const getWithIPFS = (token) => {
    if (String(token).startsWith("https://")) {
        return token
    }
    if (String(token).startsWith("ipfs://ipfs/")) {
        let urlArr1 = String(token).split("ipfs://ipfs/")
        if (urlArr1.length == 2) {
            return `${baseUrl}ipfs/${urlArr1[1]}`
        } else {
            return ipfsGetFileUrl(token)
        }
    }
    let urlArr = String(token).split("ipfs://")
    if (urlArr.length == 2) {
        return `${baseUrl}ipfs/${urlArr[1]}`
    } else {
        return ipfsGetFileUrl(token)
    }
}
export default {
    baseUrl,
    ipfsGetFileUrl,
    getWithIPFS,
}