import { EXPLORE_ADD, AUTH_FEV, EXPLORE_LIVE_AUCTION, EXPLORE_TOP_SELLERS } from "../../actionTypes"

const authFav = (payload) => (dispatch) => {
    dispatch({
        type: AUTH_FEV,
        payload: payload
    })
}

export default {
    authFav,
};
