import henceforthApi from "../utils/henceforthApi"
import { Spinner } from "./BootstrapCompo"
import Lightbox from 'react-image-lightbox';
import { useState } from "react";

export default ({ file_ext, media_url, video_url, file_url }) => {
    const [lightBoxOpen, setLightBoxOpen] = useState(false)
    if (String(file_ext).includes("video")) {
        return <video className="w-100" controls autoPlay muted loop> <source src={henceforthApi.FILES.video(file_url)}></source></video>
    } else if (media_url) {
        return <div  >{lightBoxOpen && (
            <Lightbox
                mainSrc={henceforthApi.FILES.imageOriginal(file_url)}
                onCloseRequest={() => setLightBoxOpen(false)}
            />
        )} <img style={{ cursor: 'pointer' }} src={henceforthApi.FILES.imageOriginal(file_url)} onClick={() => setLightBoxOpen(!lightBoxOpen)} alt={media_url} className="w-100" /></div>
    } else return <Spinner />
}