import { LOGIN_SUCCESS, LOGOUT_SUCCESS, SIGNUP_SUCCESS, INITIALISE_PROFILE, SOCIAL_TYPE_PROVIDER, CHAT_STATUS, AUTH_FEV } from "../actionTypes";

const auth = (state, { type, payload }) => {
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: true,
                isSignUp: false,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                access_token: "",
                email: null,
                email_verified: null,
                user: null,
                user_name: null,
                account_status: null,
                account_type: null,
                device_type: null,
                token_gen_at: null,
                admin_verified: null,
                social_token: null,
                social_type: null,
                fcm_token: null,
                provider: "META_MASK",
                type: null,
                _id: null,
                isLogin: false,
                isSignUp: false,
            }
        case AUTH_FEV: {
            let index = payload.index
            debugger
            let oldFavourite = state.favourite
            oldFavourite.splice(index, 1)
            return {
                ...state,
                favourite: oldFavourite
            }
        }
        case INITIALISE_PROFILE:
            return {
                ...state,
                ...payload,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: false,
                isSignUp: true,
            }
        case SOCIAL_TYPE_PROVIDER:
            return {
                ...state,
                ...payload,
            }
        case CHAT_STATUS:
            return {
                ...state,
                ...payload,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default auth;