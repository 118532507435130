import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import {
  EXPIRE_1_DAY,
  EXPIRE_3_DAY,
  EXPIRE_5_DAY,
  PICK_SPECIFIC_DATE,
  RIGHT_AFTER_LISTING,
  DEFAULT_COLLECTION_ID,
  FIXED_PRICE_AUCTION_ID,
  TIMED_AUCTION_AUCTION_ID,
  UNLIMITED_AUCTION_AUCTION_ID,
} from "./../context/actionTypes";
import henceforthApi from "../utils/henceforthApi";
import { toast } from "react-toastify";
import { NftPlayer, Spinner } from "../components/BootstrapCompo";
import moment from "moment";
import { NumberValidation } from "../utils/validations";
import CreateCollection from "../components/collection/CreateCollection";
import createwhite from "./../assets/images/other/create_white.svg";
import Modals from "../pages/Modals";

export default () => {
  let navigate = useNavigate();
  const location = useLocation();
  var query = new URLSearchParams(location.search);
  const {
    authState,
    setLoading,
    loading,
    fileUpload,
    createAuctionNow,
    createNftStep,
    uploadFileIpfs
  } = useContext(GlobalContext);
  const [auctionType, setAuctionType] = useState(FIXED_PRICE_AUCTION_ID);
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [royalties, setRoyalties] = useState("");
  const [startDate, setStartDate] = useState(RIGHT_AFTER_LISTING);
  const [endDate, setEndDate] = useState(EXPIRE_1_DAY);
  const [startSpecificDate, setStartSpecificDate] = useState("");
  const [endSpecificDate, setEndSpecificDate] = useState("");
  const [tag, setTag] = useState("");
  const [supply, setSupply] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [collectionList, setCollectionList] = useState({
    data: [],
    total_count: 0,
  });
  const [state, setState] = useState({
    tags: new Map(),
    tagArray: [],
  });
  henceforthApi.setToken(authState.access_token);

  const openMintModal = () => {
    const btnOpenMintModal = document.getElementById("btnOpenMintModal");
    if (btnOpenMintModal) {
      btnOpenMintModal.click();
    }
  };
  const closeMintModal = () => {
    const btnCloseMintModal = document.getElementById("btnCloseMintModal");
    if (btnCloseMintModal) {
      btnCloseMintModal.click();
    }
  };

  const onAddTags = () => {
    let rowData = [];
    if (tag) {
      let map = state.tags;
      map.set(tag, tag);
      map.forEach((element) => {
        rowData.push(element);
      });
      setState({
        ...state,
        tags: map,
        tagArray: rowData,
      });
      setTag("");
    }
  };

  const onRemoveTags = (tag) => {
    let rowData = [];
    if (tag) {
      let map = state.tags;
      map.delete(tag);
      map.forEach((element) => {
        rowData.push(element);
      });
      setState({
        ...state,
        tags: map,
        tagArray: rowData,
      });
    }
  };

  const onCreateNFT = async () => {
    let videoUrl = "";
    setLoading(true);
    if (!selectedFile) {
      setLoading(false);
      return toast.warn("Please select file");
    }
    if (selectedFile) {
      var inKb = selectedFile.size / 1024
      var inMb = inKb / 1024
      if (inMb > 100) {
        setLoading(false);
        return toast.warn("File to large")
      }
    }
    if (!description) {
      setLoading(false);
      return toast.warn("Please add description");
    }
    if (!name) {
      setLoading(false);
      return toast.warn("Please enter name");
    }
    if (selectedFile) {
      try {
        if (auctionType == FIXED_PRICE_AUCTION_ID) {
          try {
            if (!Number(price)) {
              setLoading(false);
              return toast.warn("Please enter valid amount");
            } else {
              await createFixedNft(selectedFile, videoUrl);
            }
          } catch (error) {
            setLoading(false);
            return toast.warn("Please enter valid amount");
          }
        } else if (auctionType == TIMED_AUCTION_AUCTION_ID) {
          try {
            if (!Number(price)) {
              setLoading(false);
              return toast.warn("Invalid Minimum Bid value");
            } else {
              await createAuctionNft(selectedFile, videoUrl);
            }
          } catch (error) {
            return toast.warn("Invalid Minimum Bid value");
          }
        } else {
          try {
            if (!Number(price)) {
              setLoading(false);
              return toast.warn("Invalid Minimum Bid value");
            } else {
              await createUnlimitedNft(selectedFile, videoUrl);
            }
          } catch (error) {
            return toast.warn("Invalid Minimum Bid value");
          }
        }
      } catch (error) {
      }
    }
  };

  const createFixedNft = async (selectedFile, videoUrl) => {
    debugger
    try {
      if (!price) {
        setLoading(false);
        return toast.warn("please enter price");
      }
      let collection_id = query.get("collection_id");
      let fileUrl = await uploadFileIpfs(selectedFile)
      let sampleContentS3Bucket = await fileUpload(selectedFile)
      let items = {
        file_url: sampleContentS3Bucket,
        file_ext: selectedFile.type,
        type: FIXED_PRICE_AUCTION_ID,
        start_date: 0,
        price: price,
        name: name,
        description: description,
        royalties: Number(royalties),
        tags: state.tagArray,
        language: "ENGLISH",
        collection_id: collection_id ? collection_id : DEFAULT_COLLECTION_ID,
      };
      if (videoUrl) {
        items["video_url"] = videoUrl;
      }
      openMintModal();
      let tempMetadata = {
        name: name,
        title: name,
        description: description,
        image: fileUrl,
        properties: {
          name: {
            type: "string",
            description: "properties name",
          },
          title: {
            type: "string",
            description: "properties title",
          },
        },
      };
      const metadata = (
        await henceforthApi.Ipfs.metadata(JSON.stringify(tempMetadata))
      ).data;

      let createAuctionRes = await createAuctionNow(
        metadata,
        price,
        royalties,
        location,
        auctionType,
        0
      );
      if (createAuctionRes) {
        const { itemsVoucher, token_id } = createAuctionRes;
        debugger
        const apiItems = {
          ...items,
          ...itemsVoucher,
          token_id
        }
        await finalCreateNFT(apiItems)
      } else {
        closeMintModal();
      }
    } catch (error) {
    }
  };
  const createUnlimitedNft = async (selectedFile, videoUrl) => {
    try {
      if (!price) {
        setLoading(false);
        return toast.warn("please enter price");
      }
      let collection_id = query.get("collection_id");
      let fileUrl = await uploadFileIpfs(selectedFile);
      let sampleContentS3Bucket = await fileUpload(selectedFile)
      let items = {
        file_url: sampleContentS3Bucket,
        file_ext: selectedFile.type,
        type: UNLIMITED_AUCTION_AUCTION_ID,
        start_date: 0,
        price: price,
        name: name,
        description: description,
        royalties: Number(royalties),
        tags: state.tagArray,
        language: "ENGLISH",
        collection_id: collection_id
          ? collection_id
          : DEFAULT_COLLECTION_ID,
      };
      if (videoUrl) {
        items["video_url"] = videoUrl;
      }
      openMintModal();
      let tempMetadata = {
        name: name,
        title: name,
        description: description,
        image: fileUrl,
        properties: {
          name: {
            type: "string",
            description: "properties name",
          },
          title: {
            type: "string",
            description: "properties title",
          },
        },
      };
      const metadata = (
        await henceforthApi.Ipfs.metadata(JSON.stringify(tempMetadata))
      ).data;
      let createAuctionRes = await createAuctionNow(
        metadata,
        price,
        royalties,
        location,
        auctionType,
        0
      );
      if (createAuctionRes) {
        const { itemsVoucher, token_id } = createAuctionRes;
        debugger
        const apiItems = {
          ...items,
          ...itemsVoucher,
          token_id
        }
        await finalCreateNFT(apiItems)
      } else {
        closeMintModal();
      }
    } catch (error) {
    }
  };
  const createAuctionNft = async (selectedFile, videoUrl) => {
    debugger;
    let startDateTemp = startDate;
    let endDateTemp = endDate;
    if (startDateTemp == RIGHT_AFTER_LISTING) {
      startDateTemp = moment.utc().valueOf();
    } else {
      startDateTemp = Number(startSpecificDate);
    }
    if (endDateTemp == EXPIRE_1_DAY) {
      endDateTemp = moment().add(1, "days").utc().valueOf();
    } else if (endDateTemp == EXPIRE_3_DAY) {
      endDateTemp = moment().add(3, "days").utc().valueOf();
    } else if (endDateTemp == EXPIRE_5_DAY) {
      endDateTemp = moment().add(5, "days").utc().valueOf();
    } else {
      endDateTemp = Number(endSpecificDate);
    }
    if (!startDateTemp) {
      setLoading(false);
      return toast.warn("Please select start date");
    }
    if (!endDateTemp) {
      setLoading(false);
      return toast.warn("Please select end date");
    }

    if (Number(price) <= 0) {
      setLoading(false);
      return toast.warn("Invalid Minimum Bid value");
    }
    try {
      let collection_id = query.get("collection_id");
      let fileUrl = await uploadFileIpfs(selectedFile)
      let sampleContentS3Bucket = await fileUpload(selectedFile)
      if (!fileUrl) {
        return
      }
      let items = {
        file_url: sampleContentS3Bucket,
        file_ext: selectedFile.type,
        type: TIMED_AUCTION_AUCTION_ID,
        start_date: startDateTemp,
        end_date: endDateTemp,
        name: name,
        description: description,
        royalties: Number(royalties),
        tags: state.tagArray,
        language: "ENGLISH",
        collection_id: collection_id
          ? collection_id
          : DEFAULT_COLLECTION_ID,
      };
      if (videoUrl) {
        items["video_url"] = videoUrl;
      }
      openMintModal();
      let tempMetadata = {
        name: name,
        title: name,
        description: description,
        image: fileUrl,
        properties: {
          name: {
            type: "string",
            description: "properties name",
          },
          title: {
            type: "string",
            description: "properties title",
          },
        },
      };
      const metadata = (
        await henceforthApi.Ipfs.metadata(JSON.stringify(tempMetadata))
      ).data;

      let createAuctionRes = await createAuctionNow(
        metadata,
        price,
        royalties,
        location,
        auctionType,
        endDateTemp
      );
      if (createAuctionRes) {
        const { itemsVoucher, token_id } = createAuctionRes;
        const apiItems = {
          ...itemsVoucher,
          ...items,
          token_id
        }
        await finalCreateNFT(apiItems)
      } else {
        closeMintModal();
      }
    } catch (error) {
      setLoading(false);
      closeMintModal();
    }
  };
  const finalCreateNFT = async (items) => {
    debugger
    setLoading(true);
    try {
      let apiRes = await henceforthApi.NFT2.create(items);
      closeMintModal();
      navigate(`/content/${apiRes.nft_data._id}/${apiRes.nft_data.auction_type == 1 ? "trading" : "bids"}/last_30_days`);
    } catch (error) {
      closeMintModal();
    }
    setLoading(false);
  };

  const initialiseCollectionData = async () => {
    try {
      let apiRes = await henceforthApi.Collection.getList();
      let data = apiRes.data;
      setCollectionList(data);
    } catch (error) { }
  };

  useEffect(() => {
    if (!authState.access_token) {
      navigate(`/?signin=true&redirect=${location.pathname}`, {
        replace: true,
      });
    } else {

    }
  }, [authState]);

  useEffect(() => {
    initialiseCollectionData();
  }, [query.get("collection")]);

  return <section className="content-auction edit-profile bg-light">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 m-auto">
          <div className="create-content p-4 bg-white  m-auto">
            <h4 className="mb-4">
              <strong>Create Content</strong>
            </h4>
            <div className="fix-wrapper">
              <h6>Upload file</h6>
              {selectedFile ? (
                <NftPlayer
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  loading={loading}
                />
              ) : (
                <div className="uplode-file text-center p-4  mb-3">
                  <p className="mb-1 fw-normal">
                    PNG, JPEG, GIF, WEBP, MP4
                  </p>
                  <label htmlFor="filNftFile" className="mt-2">
                    <button className="orange-btn btn btn-light pe-4 ps-4 pt-0 pb-0 me-lg-3 ms-lg-3 Create-btn shadow-none my-lg-0 my-2  position-relative ">
                      Upload File
                    </button>
                  </label>
                  <input
                    className=""
                    type="file"
                    id="filNftFile"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    accept="image/*, video/mp4"
                  />
                </div>
              )}
              <ul
                className="nav nav-pills mb-3 "
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item w-100" role="presentation">
                  <button
                    className="orange-btn nav-link active rounded-pill shadow-none w-100 mb-1 p-color"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="false"
                    onClick={() => setAuctionType(FIXED_PRICE_AUCTION_ID)}
                  >
                    Fixed Auction
                  </button>
                </li>
                <li className="nav-item w-100" role="presentation">
                  <button
                    className="orange-btn nav-link rounded-pill shadow-none w-100 mb-1 p-color"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="false"
                    onClick={() => setAuctionType(UNLIMITED_AUCTION_AUCTION_ID)}
                  >
                    Unlimited Auction
                  </button>
                </li>
                <li className="nav-item w-100" role="presentation">
                  <button className="nav-link w-100 shadow-none rounded-pill p-color"
                    id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                    aria-controls="pills-profile" aria-selected="false" onClick={() => setAuctionType(TIMED_AUCTION_AUCTION_ID)}>Timed Auction</button>
                </li>
              </ul>
              <div className="input-wrapper ">
                <label htmlFor="" className="mb-1">
                  {auctionType == FIXED_PRICE_AUCTION_ID
                    ? "Price"
                    : auctionType == UNLIMITED_AUCTION_AUCTION_ID
                      ? "Minimum Price"
                      : "Minimum Bid"}
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control shadow-none border-0"
                    placeholder=" e.g. 0.5 ETH"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    name="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <span
                    className="input-group-text bg-transparent color-red"
                    id="basic-addon2"
                  >
                    ETH
                  </span>
                </div>
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="tab-box create-form"></div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="tab-box create-form">
                    <div className="full-wrapper">
                      <div className="input-wrapper w-100 d-lg-flex justify-content-between align-items-center">
                        <div className="me-1">
                          <label htmlFor="">Starting Date</label>
                          <div className="input-group">
                            <select
                              className="form-select bg-transparent shadow-none border-0 w-100"
                              name="startDate"
                              aria-label="Default select example"
                              onChange={(e) => { setStartDate(e.target.value); setStartSpecificDate("") }}
                            >
                              <option className="bg-light" value={RIGHT_AFTER_LISTING}>
                                Right After Listing
                              </option>
                              <option className="bg-light" value={PICK_SPECIFIC_DATE}>
                                Pick Specific Date
                              </option>
                            </select>
                          </div>

                          {startDate == PICK_SPECIFIC_DATE ? (
                            <input
                              type="date"
                              className="form-control mt-1"
                              min={moment(new Date()).format("YYYY-MM-DD")}
                              name="startSpecificDate"
                              onChange={(e) => {
                                setStartSpecificDate(e.target.valueAsNumber);
                                setEndDate(EXPIRE_1_DAY)
                              }
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-3 mt-lg-0">
                          <label htmlFor="">Expiration Date </label>
                          <div className="input-group">
                            <select
                              name="endDate"
                              className="form-select bg-transparent shadow-none border-0 text-muted w-100"
                              aria-label="Default select example"
                              onChange={(e) => setEndDate(e.target.value)}
                            >
                              <option value={EXPIRE_1_DAY}>1 Day</option>
                              <option value={EXPIRE_3_DAY}>3 Days</option>
                              <option value={EXPIRE_5_DAY}>5 Days</option>
                              <option value={PICK_SPECIFIC_DATE}>
                                Pick Specific Date
                              </option>
                            </select>
                          </div>
                          {endDate == PICK_SPECIFIC_DATE ? (
                            <input
                              type={`date`}
                              className="form-control mt-1"
                              min={moment(startSpecificDate ? Number(startSpecificDate) : new Date())
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              name="endSpecificDate"
                              onChange={(e) =>
                                setEndSpecificDate(e.target.valueAsNumber)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <p className="text-start bid-text p-color">
                        <small>
                          Bid Placed In The Last 10 Minutes Extends The
                          Auction By 10 Minutes.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="input-wrapper ">
                  <label htmlFor="" className="mb-1">
                    Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control shadow-none border-0"
                      placeholder="Enter name"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <section className="section-collection">
                  <label className="opctiy">Choose Collection</label>
                  <div className="row mt-2">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <div
                          className="box-creat cursor-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#CreateCollectionModal">
                          <img src={createwhite} />
                          <h6 className="text-light">Create</h6>
                        </div>
                      </div>
                    </div>
                    {Array.isArray(collectionList.data) &&
                      collectionList.data.map((res) => {
                        return <div
                          key={res._id}
                          className="col-md-4 mb-3"
                          onClick={() => {
                            query.set("collection_id", res._id);
                            query.set("collection", res.address);
                            navigate({ search: query.toString() });
                          }}
                        >
                          <div className="form-group">
                            <div
                              className={`box-creat customcreate cursor-pointer ${query.get("collection_id") === res._id
                                ? "active"
                                : ""
                                }`}
                            >
                              {res.img_url?.includes("https") ? <img
                                src={res.img_url}
                              /> : <img
                                src={`${henceforthApi.API_FILE_ROOT_MEDIUM}${res.img_url}`}
                              />}
                              <h6 className="text-light my-1">
                                {res.name.length > 15 ? `${res.name?.slice(0, 8)}...` : res.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      })}
                  </div>
                </section>
                <div className="input-wrapper ">
                  <label htmlFor="" className="mb-1">
                    Description
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control shadow-none border-0"
                      placeholder="Enter description"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="input-wrapper ">
                  <label htmlFor="" className="mb-1">
                    Royalties
                  </label>
                  <div className="input-group mb-1">
                    <input
                      type="text"
                      className="form-control shadow-none border-0"
                      placeholder="0"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="royalties"
                      value={royalties}
                      onChange={(e) =>
                        NumberValidation(e.target.value)
                          ? setRoyalties(e.target.value)
                          : console.log(e.target.value)
                      }
                      autoComplete="off"
                    />
                    <span
                      className="input-group-text border-0 bg-transparent"
                      id="basic-addon2"
                    >
                      <strong>%</strong>
                    </span>
                  </div>
                  <p className="text-start">
                    <small>Suggested: 10%, 20%, 30% & max 50% allowed.</small>
                  </p>
                </div>
                {query.has("type") && query.get("type") == "1155" && (
                  <div className="input-wrapper ">
                    <label htmlFor="" className="mb-1">
                      Supply
                    </label>
                    <div className="input-group mb-1">
                      <input
                        type="text"
                        className="form-control shadow-none border-0"
                        placeholder="0"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        name="royalties"
                        value={supply}
                        onChange={(e) =>
                          NumberValidation(e.target.value)
                            ? setSupply(e.target.value)
                            : console.log(e.target.value)
                        }
                        autoComplete="off"
                      />
                      <span
                        className="input-group-text border-0 bg-transparent"
                        id="basic-addon2"
                      >
                        <strong>%</strong>
                      </span>
                    </div>
                  </div>
                )}
                <div className="input-wrapper ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onAddTags();
                    }}
                  >
                    <label htmlFor="" className="mb-1">
                      Tags (Optional)
                    </label>
                    <div className="input-group mb-1">
                      <input
                        type="text"
                        className="form-controll shadow-none border-0 p-2"
                        placeholder="Enter tag name"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        name="tag"
                        maxLength={40}
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                      />
                      <span
                        className="add-btn input-group-text-blue cursor-pointer "
                        id="basic-addon2"
                        onClick={() => onAddTags()}
                      >
                        <button className="btn-icon color-red">Add</button>
                      </span>
                    </div>
                    <div className="add-content d-flex justify-content-start  mt-2 pb-2">
                      {state.tagArray.map((res) => {
                        return <div className="anwork d-flex justify-content-start align-items-center mb-2">
                          <p className="mb-0">
                            {res}
                            <span>
                              <i
                                className="far fa-times fs-6 ms-1 me-1"
                                onClick={() => onRemoveTags(res)}
                              ></i>
                            </span>
                          </p>
                        </div>
                      })}
                    </div>
                  </form>
                </div>
                <div className="footer-buttoon d-flex justify-content-between align-items-center mt-4 pb-lg-2">
                  <button
                    type="button"
                    className="orange-btn btn btn-light shadow-none my-lg-0 w-100 me-2 "
                    disabled={loading}
                    onClick={() => onCreateNFT()}
                  >
                    {loading ? <Spinner /> : "Mint Now"}
                  </button>
                  <button
                    type="button"
                    className="trant-btn btn btn-light Create-btn w-100"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateCollection />
    <button
      type="button"
      id="openSelectNftType"
      className="d-none btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#selectNftType"
    ></button>
    <Modals.NftCreateStep createNftStep={createNftStep} />
  </section>
};
