import loder from "../assets/images/orange_loader.gif";
export const Spinner = (props) => {
    return <div className="spinner-border  spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
}

export const GlobalLoading = () => {
    return <div className="modal fade steps show" id="exampleModalLive" tabIndex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-sm modall-width">
            <div className="modal-content">
                <div className="modal-body py-lg-5">
                    <div className="nft-body-modal">
                        <div className="upload-stepss">
                            <div className="step-img">
                                <img src={loder} />
                            </div>
                            <div className="step-text text-center footerr-hed">
                                <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const NftPlayer = ({ selectedFile, setSelectedFile, loading }) => {
    return <div className="upload-times position-relative">{String(selectedFile.type).includes("image") ?
        <img src={URL.createObjectURL(selectedFile)} className="w-100" /> :
        <video controls autoPlay muted loop className="w-100" > <source src={URL.createObjectURL(selectedFile)}  ></source></video>
    }
        <i className="fa fa-times" onClick={() => !loading ? setSelectedFile(null) : alert("Not allow")}></i>
    </div>
}